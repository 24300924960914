import { makeAutoObservable } from "mobx";

class CustomBranding {
  customTitle = "";
  customUrl = "";
  customUrlTitle = "";

  constructor(customBranding) {
    makeAutoObservable(this);

    this.update(customBranding);
  }

  update = ({ customTitle, customUrl, customUrlTitle }) => {
    this.customTitle = customTitle ?? "";
    this.customUrl = customUrl ?? "";
    this.customUrlTitle = customUrlTitle ?? "";
  };
}

export default CustomBranding;
