import React from "react";

import {
	makeStyles,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from "@material-ui/core";

const useStyles = makeStyles({
	table: {
		maxWidth: 650,
		margin: "30px auto 30px",
	},
});

const PricingTable = ({ subscriptionTiers }) => {
	const classes = useStyles();
	return (
		<>
			<h3 align="left">Pricing Table</h3>
			<TableContainer className={classes.table} component={Paper}>
				<Table aria-label="simple table">
					<TableHead>
						<TableRow>
							<TableCell align="center">Number of hosts</TableCell>
							<TableCell align="center">Price per host(per month)</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{subscriptionTiers.map((row) => (
							<TableRow key={row.starting_quantity}>
								<TableCell align="center" component="th" scope="row">
									{row.starting_quantity}
									{row.ending_quantity ? -row.ending_quantity : " and beyond"}
								</TableCell>
								<TableCell align="center">
									${Math.round(row.amount.value)}
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</>
	);
};

export default PricingTable;
