import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  backButton: {
    display: "flex",
    justifyContent: "center",
  },
  circularProgress: {
    color: "#3f51b5",
    display: "flex",
    justifyContent: "center",
    height: "80%",
    alignItems: "center",
  },
  alignCenter: {
    textAlign: "center",
  },
  content: {
    minWidth: 500,
  },
});

const ApproveModal = ({ open, link }) => {
  const classes = useStyles();

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle className={classes.alignCenter} id="alert-dialog-title">
          Please, approve your subscription!
        </DialogTitle>
        <DialogContent className={classes.content}>
          <DialogContentText
            className={classes.alignCenter}
            id="alert-dialog-description"
          >
            To complete process you need to approve updates for your
            subscription via PayPal.
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.backButton}>
          <Button
            href={link}
            variant="contained"
            color="primary"
            className={classes.button}
          >
            Approve
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default ApproveModal;
