import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { CircularProgress, makeStyles, TextField } from "@material-ui/core";

const useStyles = makeStyles({
  backButton: {
    display: "flex",
    justifyContent: "center",
  },
  circularProgress: {
    color: "#3f51b5",
    display: "flex",
    justifyContent: "center",
    height: "80%",
    alignItems: "center",
  },
  title: {
    textAlign: "center",
  },
  content: {
    minWidth: 500,
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  textField: {
    marginBottom: 20,
    width: 300,
  },
});

const CancelSubscriptionModal = ({
  open,
  loading,
  reason,
  subscriptionCanceled,
  setReason,
  handleClose,
  cancel,
}) => {
  const classes = useStyles();

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle className={classes.title} id="alert-dialog-title">
          Cancel your subscription?
        </DialogTitle>
        <DialogContent className={classes.content}>
          {loading && (
            <div className={classes.circularProgress}>
              <CircularProgress color="inherit" size={40} />
            </div>
          )}
          {!loading && !subscriptionCanceled && (
            <>
              <DialogContentText id="alert-dialog-description">
                You still can use all subscription advantages till the end of
                your subscription.
              </DialogContentText>

              <TextField
                size="small"
                label="Reason"
                name="reason"
                variant="outlined"
                margin="dense"
                value={reason}
                className={classes.textField}
                onChange={(e) => setReason(e.target.value)}
              />

              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={cancel}
              >
                Cancel subscription
              </Button>
            </>
          )}

          {!loading && subscriptionCanceled && (
            <DialogContentText id="alert-dialog-description">
              Your subscription was successfully canceled!
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions className={classes.backButton}>
          <Button onClick={handleClose} color="primary">
            Go Back
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default CancelSubscriptionModal;
