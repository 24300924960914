import React, { useCallback, useEffect, useState } from "react";
import OftadehLayout from "../../../components/OftadehLayout/OftadehLayout";
import { Button, Grid, Typography, makeStyles, Paper } from "@material-ui/core";
import Icon from "@material-ui/core/Icon";
import { observer } from "mobx-react-lite";
import { useIsMounted } from "../../../helpers/useIsMounted";
import { useStore } from "../../../stores/helpers/useStore";
import HostsTable from "./HostsTable";
import ConfirmationModal from "../../../components/Modals/ConfirmationModal/ConfirmationModal";
import ErrorModal from "../../../components/Modals/ErrorModal/ErrorModal";
import Alert from "@material-ui/lab/Alert";

const useStyles = makeStyles(() => ({
	titlePaper: {
		marginBottom: "16px",
	},
	availableSpace: {
		fontVariant: "diagonal-fractions",
		fontSize: 36,
	},
}));

const HostsList = (props) => {
	const { history } = props;
	const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
	const [deletingError, setDeletingError] = useState("");
	const [submittingRequest, setSubmittingRequest] = useState(false);
	const [hostToDelete, setHostToDelete] = useState({});
	const classes = useStyles();
	const isMounted = useIsMounted();

	const {
		hostsStore: { hostsList, haveFreeSlot, getListOfHosts, deleteHost },
		subscriptionStore: {
			subscriptionStatus,
			subscriptionQuantity,
			getSubscriptionInfo,
		},
		uiStore: { loading },
	} = useStore();

	const getSubscription = useCallback(async () => {
		try {
			await getSubscriptionInfo();
		} catch {
			history.push("/pages/errors/error-500");
		}
	}, [getSubscriptionInfo, history]);

	const deletePlayer = (host) => {
		setHostToDelete(host);
		setOpenConfirmationModal(true);
	};

	const confirm = async () => {
		setSubmittingRequest(true);
		try {
			await deleteHost(hostToDelete.id);
		} catch (err) {
			setDeletingError(
				err.response.data?.message ||
					"Something went wrong! Please try again later!"
			);
		} finally {
			setSubmittingRequest(false);
			setOpenConfirmationModal(false);
		}
	};

	const handleClose = () => {
		setOpenConfirmationModal(false);
		setDeletingError("");
	};

	useEffect(() => {
		getListOfHosts();
	}, [getListOfHosts]);

	useEffect(() => {
		getSubscription();
	}, [getSubscription]);

	return (
		<>
			<OftadehLayout loading={loading || !isMounted}>
				<h1>
					{window.labs.hosts}
					{subscriptionQuantity && (
						<Typography className={classes.availableSpace}>
							{hostsList.length}/{subscriptionQuantity}
						</Typography>
					)}
				</h1>
				{subscriptionStatus !== "INACTIVE" ? (
					<>
						{!!hostsList.length ? (
							<Paper style={{ padding: "5px" }}>
								<HostsTable hostsList={hostsList} deletePlayer={deletePlayer} />
							</Paper>
						) : (
							<Grid
								container
								direction="column"
								justify="center"
								alignItems="center"
							>
								<Alert severity="warning">
									You don't have hosts, please invite existing host or create
									new account!
								</Alert>
							</Grid>
						)}

						<Grid
							container
							direction="row"
							justify="center"
							alignItems="center"
							style={{ marginTop: "15px" }}
						>
							{haveFreeSlot ? (
								<>
									<Button
										variant="contained"
										color="primary"
										style={{ marginRight: "15px" }}
										startIcon={<Icon>add</Icon>}
										onClick={() => history.push("/invite-host")}
									>
										Invite Host
									</Button>
									<Button
										variant="contained"
										color="primary"
										startIcon={<Icon>add</Icon>}
										onClick={() => history.push("/create-host")}
									>
										Create Host
									</Button>
								</>
							) : (
								<Button
									variant="contained"
									color="primary"
									style={{ marginBottom: "15px" }}
									onClick={() => history.push("/subscription")}
								>
									Update subsription
								</Button>
							)}
						</Grid>
					</>
				) : (
					<Grid
						container
						direction="column"
						justify="center"
						alignItems="center"
					>
						<Alert severity="warning" style={{ marginBottom: 40 }}>
							You will need to initiate a subscription before adding/inviting
							hosts.
						</Alert>
						<Button
							variant="contained"
							color="primary"
							style={{ marginBottom: "15px" }}
							onClick={() => history.push("/subscription")}
						>
							Start subsription
						</Button>
					</Grid>
				)}
			</OftadehLayout>

			<ConfirmationModal
				title={`You really want to delete ${hostToDelete.roomCode}?`}
				open={openConfirmationModal}
				loading={submittingRequest}
				handleClose={handleClose}
				confirm={confirm}
			>
				If this was created host, it will completely delete his account with all
				players.
				<br />
				If host was invited, it will cancel his <b>PRO</b> status.
			</ConfirmationModal>

			<ErrorModal
				open={!!deletingError}
				title="Deleting of host failed!"
				errorText={deletingError}
				handleClose={handleClose}
			/>
		</>
	);
};

export default observer(HostsList);
