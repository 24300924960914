import WagersList from "./WagersList";

export const WagersPageConfig = {
  routes: [
    {
      path: "/wager-schemes",
      exact: true,
      component: WagersList,
    },
  ],
};
