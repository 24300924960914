import VerifyEmailPage from "./VerifyEmailPage";

export const VerifyEmailPageConfig = {
  routes: [
    {
      path: "/pages/auth/verify/:verificationToken",
      exact: true,
      component: VerifyEmailPage,
      processing: true,
    },
  ],
};
