import React, { useState } from "react";
import {
  Button,
  CircularProgress,
  Icon,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { useStore } from "../../../stores/helpers/useStore";
import { observer } from "mobx-react-lite";

const useStyles = makeStyles((theme) => ({
  root: {
    background: "#0d131d",
    color: "#fff",
    width: "100vw",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
  },
  emailIcon: {
    fontSize: "5rem",
    marginTop: ".85rem",
    marginBottom: ".85rem",
  },
  button: {
    marginTop: ".85rem",
  },
  logout: {
    position: "absolute",
    top: "20px",
    right: "20px",
  },
  error: {
    color: "red",
    marginTop: ".85rem",
  },
}));

const VerificationPage = () => {
  const [resended, setResended] = useState(false);
  const [sending, setSending] = useState(false);
  const [error, setError] = useState("");
  const classes = useStyles();
  const {
    authStore: { resendVerificationEmail, logout, email },
  } = useStore();

  const resend = async () => {
    setSending(true);
    try {
      await resendVerificationEmail(email);
    } catch {
      setSending(false);
      setResended(false);
      setError("Something went wrong, please reload page and try again!");
      return;
    }
    setSending(false);
    setResended(true);
  };
  return (
    <div className={classes.root}>
      <Button
        variant="contained"
        color="primary"
        className={classes.logout}
        onClick={logout}
      >
        Logout
      </Button>
      <Typography variant="h3" gutterBottom>
        You need to verify your account!
      </Typography>
      <Typography variant="body1">
        Please check your email for a button to verify your email address.
      </Typography>
      <Typography variant="body1">
        Once verified, you'll be able to continue.
      </Typography>
      <Icon className={classes.emailIcon}>email</Icon>
      {sending && <CircularProgress color="inherit" />}
      {!sending && resended && (
        <Typography variant="body1">Email sended!</Typography>
      )}
      {!sending && !resended && email && (
        <>
          <Typography variant="body1">Didn't receive an email?</Typography>
          <Button
            variant="contained"
            color="default"
            className={classes.button}
            onClick={resend}
          >
            Resend
          </Button>
          {error && (
            <Typography className={classes.error} variant="body1">
              {error}
            </Typography>
          )}
        </>
      )}
    </div>
  );
};

export default observer(VerificationPage);
