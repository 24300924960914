import VerificationPage from "./VerificationPage";

export const VerificationPageConfig = {
  routes: [
    {
      path: "/pages/auth/verification",
      exact: true,
      component: VerificationPage,
      verification: true,
    },
  ],
};
