export const compareOrder = (a, b) => {
  const A = a.order;
  const B = b.order;

  let comparison = 0;
  if (A > B) {
    comparison = 1;
  } else if (A < B) {
    comparison = -1;
  }
  return comparison;
};
export const compareOrderNum = (a, b) => {
  const A = a.orderNum;
  const B = b.orderNum;

  let comparison = 0;
  if (A > B) {
    comparison = 1;
  } else if (A < B) {
    comparison = -1;
  }
  return comparison;
};
