import { makeAutoObservable, runInAction } from "mobx";
import { getRoot } from "mobx-easy";

import SubscriptionService from "../../services/SubscriptionService";

export default class SubscriptionStore {
	subscriptionStatus = "";
	subscriptionEnd = undefined;
	subscriptionQuantity = 0;
	nextCycleQuantity = 0;
	subscriptionTiers = [];
	subscriptionApprovalLink = "";
	rootStore = undefined;

	constructor() {
		makeAutoObservable(this);
		this.rootStore = getRoot();
	}

	clearSubscriptionStore = () => {
		this.subscriptionStatus = "";
		this.subscriptionEnd = undefined;
		this.subscriptionQuantity = 0;
		this.nextCycleQuantity = 0;
		this.subscriptionTiers = [];
		this.subscriptionApprovalLink = "";
	};

	getSubscriptionPlanInfo = async () => {
		if (!!this.subscriptionTiers.length) return;

		//this.rootStore.uiStore.startLoading();

		const response = await SubscriptionService.getSubscriptionPlanInfo();

		runInAction(() => {
			this.rootStore.uiStore.cancelLoading();
			this.subscriptionTiers = response.subscriptionPlanInfo.tiers;
		});
		//this.rootStore.uiStore.cancelLoading();

		return response;
	};

	getSubscriptionInfo = async () => {
		if (this.subscriptionStatus) return;

		//this.rootStore.uiStore.startLoading();

		const response = await SubscriptionService.getSubscriptionInfo();

		runInAction(() => {
			this.rootStore.uiStore.cancelLoading();
			this.subscriptionStatus = response.subscriptionStatus;
			this.subscriptionQuantity = response.subscriptionQuantity;
			this.nextCycleQuantity = response.nextCycleQuantity;
			this.subscriptionEnd = response.subscriptionEnd;
			this.subscriptionApprovalLink = response.subscriptionApprovalLink;
		});
	};

	createAdminSubscription = async (subscriptionId) => {
		const response = await SubscriptionService.createAdminSubscription(
			subscriptionId
		);

		runInAction(() => {
			this.subscriptionStatus = response.subscriptionStatus;
			this.subscriptionQuantity = response.subscriptionQuantity;
			this.subscriptionEnd = response.subscriptionEnd;
		});
	};

	updateAdminSubscription = async (orderID, quantity) => {
		const response = await SubscriptionService.updateAdminSubscription(
			orderID,
			quantity
		);

		return response;
	};

	cancelAdminSubscription = async (reason) => {
		const response = await SubscriptionService.cancelAdminSubscripition(reason);

		runInAction(() => {
			this.subscriptionStatus = response.subscriptionStatus;
		});
	};

	approveSubscriptionUpdate = async (approvedSubId, ba_token) => {
		this.rootStore.uiStore.startLoading();

		const response = await SubscriptionService.approveSubscriptionUpdate(
			approvedSubId,
			ba_token
		);

		runInAction(() => {
			this.rootStore.uiStore.cancelLoading();
			this.subscriptionStatus = response.subscriptionStatus;
			this.subscriptionQuantity = response.subscriptionQuantity;
			this.nextCycleQuantity = response.nextCycleQuantity;
			this.subscriptionEnd = response.subscriptionEnd;
		});

		return response;
	};
}
