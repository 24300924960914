import React from "react";
import { Redirect, Route } from "react-router";

const PrivateRoute = ({ key, auth, verified, ...route }) => {
  if (auth && verified && !route.path) {
    // console.log("%c REDIRECT TO HOSTS PAGE", "font-size:30px;color:red;");
    return <Redirect to="/hosts" />;
  } else if ((auth && verified) || route.error) {
    // console.log("%c GO TO ROUTE", "font-size:30px;color:green;");
    return <Route key={key} {...route} />;
  } else if (auth && !verified) {
    // console.log(
    //   "%c REDIRECT TO VERIFICATION ROUTE",
    //   "font-size:30px;color:red;"
    // );
    return <Redirect to="/pages/auth/verification" />;
  } else if (!auth) {
    // console.log("%c REDIRECT TO PUBLIC ROUTE", "font-size:30px;color:red;");
    return <Redirect to="/pages/auth/login" />;
  }
};

export default PrivateRoute;
