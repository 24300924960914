import React from "react";
import { makeStyles } from "@material-ui/core";

const eduStyles = makeStyles((theme) => ({
	root: {
		background: "#fefeff",
		width: "100vw",
		height: "100vh",
		display: "flex",
		justifyContent: "center",
		flexDirection: "column",
		alignItems: "center",
		textAlign: "center",
	},
	mBottom: {
		marginBottom: ".5rem",
		color: "#010102",
	},
	button: {
		marginTop: ".85rem",
	},
	loginCard: {
		borderRadius: 5,
		borderWidth: 1,
		borderColor: "#fdfdff",
		borderStyle: "solid",
		color: "black",
		padding: ".85rem",
	},
	textColor: {
		color: "#010102",
	},
	headerText: {
		color: "#010102",
		fontWeight: "bold",
		fontSize: "2rem",
	},
	circularProgress: {
		marginLeft: "10px",
	},
	customColor: {
		color: "#dfdfef",
		fontWeight: "800px",
		padding: "14px",
		lineHeight: "3px",
		marginBottom: "1rem",
	},
}));

export default eduStyles;
