import React, { useEffect, useState } from "react";
import {
  makeStyles,
  TextField,
  Button,
  Grid,
  Box,
  Paper,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import { observer } from "mobx-react-lite";

import { useFormik } from "formik";
import * as Yup from "yup";

import { useStore } from "../../../stores/helpers/useStore";
import OftadehLayout from "../../../components/OftadehLayout/OftadehLayout";
import CongratulationModal from "../../../components/Modals/CongratulationModal/CongratulationModal";

const initialValues = {
  roomCode: "",
  password: "",
  confirmPassword: "",
};

const loginationSchema = Yup.object().shape({
  roomCode: Yup.string()
    .trim()
    .min(4, "Minimum 4 symbols")
    .max(10, "Maximum 10 symbols")
    .matches(/^[a-zA-Z0-9_.-]*$/, "Invalid symbols!")
    .required("Room code is required"),
  password: Yup.string()
    .trim()
    .min(8, "Minimum 8 symbols")
    // .max(20, "Maximum 20 symbols")
    .required("Password is required"),
  confirmPassword: Yup.string()
    .trim()
    .required("Password confirmation is required")
    .when("password", {
      is: (val) => !!(val && val.length > 0),
      then: Yup.string().oneOf(
        [Yup.ref("password")],
        "Password and Confirm Password didn't match"
      ),
    }),
});

const useStyles = makeStyles(() => ({
  inviteForm: {
    maxWidth: 500,
    margin: "0 auto",
    padding: 30,
  },
  controlButtons: {
    display: "flex",
    justifyContent: "center",
    marginTop: 20,
  },
  error: {
    color: "#f44336",
    fontSize: "0.75rem",
    textAlign: "center",
  },
}));

const CreateHost = (props) => {
  const { history } = props;
  const classes = useStyles();
  const [openCongratulationModal, setOpenCongratulationModal] = useState(false);
  const [subscriptionError, setSubscriptionError] = useState("");
  const {
    hostsStore: { createNewHost },
    subscriptionStore: { subscriptionQuantity },
  } = useStore();

  const formik = useFormik({
    initialValues,
    validationSchema: loginationSchema,
    onSubmit: async (values, { setFieldError, setSubmitting }) => {
      setSubscriptionError("");
      setSubmitting(true);
      try {
        await createNewHost(values.roomCode, values.password);
        setOpenCongratulationModal(true);
      } catch (err) {
        switch (err.response?.status) {
          case 406:
            setFieldError("roomCode", err.response.data?.message);
            break;
          case 409:
            setFieldError(err.response.data?.param, err.response.data?.message);
            break;
          case 420:
            setSubscriptionError(err.response.data?.message);
            break;
          case 500:
            history.push("/pages/errors/error-500");
            break;
          default:
            break;
        }
      }
    },
  });

  const closeCongratulationModal = () => {
    setOpenCongratulationModal(false);
    history.push("/hosts");
  };

  useEffect(() => {
    if (!subscriptionQuantity) history.push("/hosts");
  }, [subscriptionQuantity, history]);

  return (
    <>
      <OftadehLayout>
        <h1>Create Host</h1>

        <Paper className={classes.inviteForm}>
          <Box m={3.5}>
            <Grid container direction={"column"} spacing={5}>
              <Typography variant="h4" component="h1" align="center" paragraph>
                Create Host
              </Typography>
              <form
                noValidate
                autoComplete="off"
                onSubmit={formik.handleSubmit}
              >
                <TextField
                  size="small"
                  label="Room Code"
                  name="roomCode"
                  variant="outlined"
                  margin="dense"
                  value={formik.values.roomCode
                    .toUpperCase()
                    .replace(/\s+/g, "")}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={!!formik.touched.roomCode && !!formik.errors.roomCode}
                  helperText={formik.touched.roomCode && formik.errors.roomCode}
                  fullWidth
                />
                <TextField
                  size="small"
                  label="Password"
                  type="password"
                  name="password"
                  variant="outlined"
                  margin="dense"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={!!formik.touched.password && !!formik.errors.password}
                  helperText={formik.touched.password && formik.errors.password}
                  fullWidth
                />
                <TextField
                  size="small"
                  label="Repeat password"
                  type="password"
                  name="confirmPassword"
                  variant="outlined"
                  margin="dense"
                  value={formik.values.confirmPassword}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    !!formik.touched.confirmPassword &&
                    !!formik.errors.confirmPassword
                  }
                  helperText={
                    formik.touched.confirmPassword &&
                    formik.errors.confirmPassword
                  }
                  fullWidth
                />

                {subscriptionError && (
                  <p className={classes.error}>{subscriptionError}</p>
                )}

                <Box className={classes.controlButtons}>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={formik.isSubmitting}
                    style={{ marginRight: 15 }}
                  >
                    {formik.isSubmitting ? (
                      <>
                        Please wait{" "}
                        <CircularProgress
                          className={classes.circularProgress}
                          color="inherit"
                          size={16}
                        />
                      </>
                    ) : (
                      <> Create</>
                    )}
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => history.push("/hosts")}
                  >
                    Cancel
                  </Button>
                </Box>
              </form>
            </Grid>
          </Box>
        </Paper>
      </OftadehLayout>

      <CongratulationModal
        open={openCongratulationModal}
        handleClose={closeCongratulationModal}
      >
        Host <b>{formik.values.roomCode.toUpperCase()}</b> successfully created!
        <br />
        Now host can login with provided <b>Room Code</b> and <b>password</b>,
        and enjoy all the <b>"PRO"</b> status features!
      </CongratulationModal>
    </>
  );
};

export default observer(CreateHost);
