import React, { useState } from "react";
import {
  Button,
  Grid,
  Box,
  Typography,
  TextField,
  makeStyles,
  CircularProgress,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import PageviewIcon from "@material-ui/icons/Pageview";

import { useHistory } from "react-router-dom";

import CSVReader from "react-csv-reader";

import { useFormik } from "formik";
import * as Yup from "yup";

import QuestionsSpreadsheet from "./QuestionsSpreadsheet";
import PreEnteredRoundQuestions from "../../components/PreEnteredRoundQuestions/PreEnteredRoundQuestions";
import CongratulationModal from "../../components/Modals/CongratulationModal/CongratulationModal";

const validationSchema = Yup.object().shape({
  roundName: Yup.string().trim().required("Round name is required"),
});

const useStyles = makeStyles({
  error: {
    color: "#f44336",
    marginTop: ".85rem",
    fontSize: "0.75rem",
    textAlign: "center",
  },
});

const triggerFile = () => {
  document.getElementById("csvreader").click();
};

const ImportQuestions = ({ upload, changeTab }) => {
  const [fileUploadError, setFileUploadError] = useState("");
  const [questionsError, setQuestionsError] = useState("");
  const [questions, setQuestions] = useState([]);
  const [openCongratulationModal, setOpenCongratulationModal] = useState(false);

  const classes = useStyles();
  const history = useHistory();

  const formik = useFormik({
    initialValues: { roundName: "" },
    validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setQuestionsError("");
      setSubmitting(true);
      try {
        await upload(values.roundName, questions);
        setOpenCongratulationModal(true);
      } catch (err) {
        if (err.response?.status === 409) {
          // TODO: add error handling
          setQuestionsError(err.response.data?.message);
        } else if (err.response?.status === 500) {
          history.push("/pages/errors/error-500");
        }
      }
    },
  });

  const handleFileUpload = (questions) => {
    const questionsToSet = [];
    let runningNumber = 1;
    questions.forEach((question, index) => {
      if (index === 0) return;
      if (question[0].length && question[1].length) {
        const questionToPush = {
          questionText: question[0],
          answerText: question[1],
          orderNum: runningNumber++,
        };
        questionsToSet.push(questionToPush);
      }
    });

    setQuestions(questionsToSet);
  };

  const clearRound = () => {
    setQuestions([]);
    setQuestionsError("");
    document.getElementById("csvreader").value = "";
  };

  const closeCongratulationModal = () => {
    setOpenCongratulationModal(false);
    changeTab();
  };
  return (
    <>
      <Box m={2.2}>
        <Alert severity="info">
          Here, you can import questions into TriviaRat via a CSV file. These
          questions will be available (in pre-entered mode) to all your hosts.
        </Alert>
      </Box>

      {!questions.length && (
        <Box m={2.2}>
          <QuestionsSpreadsheet />
        </Box>
      )}

      <Box display="flex" justifyContent="center" m={2.5}>
        <TextField
          size="small"
          label="Round name"
          name="roundName"
          variant="outlined"
          margin="dense"
          autoComplete="off"
          inputProps={{ style: { textAlign: "center" } }}
          value={formik.values.roundName}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={!!formik.touched.roundName && !!formik.errors.roundName}
          helperText={formik.touched.roundName && formik.errors.roundName}
        />
      </Box>

      {!!questions.length ? (
        <>
          <PreEnteredRoundQuestions questions={questions} />

          <Box display="flex" justifyContent="center" m={2.5}>
            <Button
              variant="contained"
              color="default"
              style={{ marginRight: "15px" }}
              onClick={clearRound}
            >
              Clear round
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={formik.isSubmitting}
              onClick={formik.handleSubmit}
            >
              {formik.isSubmitting ? (
                <>
                  Please wait{" "}
                  <CircularProgress
                    className={classes.circularProgress}
                    color="inherit"
                    size={16}
                  />
                </>
              ) : (
                <> Create round</>
              )}
            </Button>
          </Box>
          {questionsError && <p className={classes.error}>{questionsError}</p>}
        </>
      ) : (
        <Grid item xs={12}>
          <Box display="flex" justifyContent="center" m={2.5}>
            <Button
              variant="contained"
              color="primary"
              onClick={triggerFile}
              startIcon={<PageviewIcon />}
            >
              Import CSV...
            </Button>

            {fileUploadError && (
              <Typography className={classes.error} variant="body1">
                {fileUploadError}
              </Typography>
            )}
          </Box>
        </Grid>
      )}

      <CSVReader
        accept=".csv, text/csv, application/vnd.ms-excel"
        onFileLoaded={handleFileUpload}
        onError={() =>
          setFileUploadError(
            "Something went wrong. Please, check the file extension and try again!"
          )
        }
        parserOptions={{
          dynamicTyping: true,
          skipEmptyLines: true,
        }}
        inputId="csvreader"
        inputStyle={{ display: "none" }}
        strict
      />
      <CongratulationModal
        open={openCongratulationModal}
        handleClose={closeCongratulationModal}
      >
        Round <b>{formik.values.roundName}</b> successfully added!
        <br />
        Now your hosts can use this round in their games!
      </CongratulationModal>
    </>
  );
};

export default ImportQuestions;
