import {
  tierOneLimit,
  tieroOnePrice,
  tierThreePrice,
  tierTwoLimit,
  tierTwoPrice,
} from "../utils/constants";

export const getSubscriptionPrice = (numberOfHosts) => {
  let calculatedPrice = 0;
  switch (true) {
    case numberOfHosts <= tierOneLimit:
      calculatedPrice = numberOfHosts * tieroOnePrice;
      break;
    case numberOfHosts <= tierTwoLimit:
      calculatedPrice =
        tierOneLimit * tieroOnePrice +
        (numberOfHosts - tierOneLimit) * tierTwoPrice;
      break;
    case numberOfHosts > tierTwoLimit:
      calculatedPrice =
        tierOneLimit * tieroOnePrice +
        tierOneLimit * tierTwoPrice +
        (numberOfHosts - tierTwoLimit) * tierThreePrice;
      break;
    default:
      calculatedPrice = 0;
  }
  return calculatedPrice;
};
