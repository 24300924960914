import { makeAutoObservable } from "mobx";

class PlayerSettings {
	requirePlayerEmail = false;
	requirePlayerConfirmEmail = false;

	constructor(playerSettings) {
		makeAutoObservable(this);

		this.update(playerSettings);
	}

	update = (playerSettings) => {
		if (playerSettings) {
			const { requirePlayerEmail, requirePlayerConfirmEmail } = playerSettings;
			this.requirePlayerEmail = requirePlayerEmail ?? false;
			this.requirePlayerConfirmEmail = requirePlayerConfirmEmail ?? false;
		} else {
			// Handle the case where playerSettings is undefined
			this.requirePlayerEmail = false;
			this.requirePlayerConfirmEmail = false;
		}
	};
}

export default PlayerSettings;
