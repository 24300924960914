import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
  CircularProgress,
  FormControl,
  FormHelperText,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
} from "@material-ui/core";
import { useStore } from "../../../stores/helpers/useStore";

const useStyles = makeStyles({
  backButton: {
    display: "flex",
    justifyContent: "center",
    marginTop: 20,
  },
  circularProgress: {
    marginLeft: "10px",
  },
  alignCenter: {
    textAlign: "center",
  },
  content: {
    minWidth: 500,
    display: "flex",
    justifyContent: "center",
  },
  select: {
    minWidth: 200,
  },
});

const ConnectWagerSchemeModal = ({
  open,
  roundName,
  roundId,
  connectedScheme,
  handleClose,
}) => {
  const [options, setOptions] = useState([]);
  const [selectedScheme, setSelectedScheme] = useState(connectedScheme || 0);
  const [connectingError, setConnectingError] = useState("");
  const [loading, setLoading] = useState(false);

  const {
    settingsStore: { wagerSchemesList, connectWagerScheme },
  } = useStore();
  const classes = useStyles();

  useEffect(() => {
    if (!!wagerSchemesList.length) {
      const optionsToSet = [];
      wagerSchemesList.forEach((scheme) => {
        optionsToSet.push(
          <MenuItem key={scheme.id} value={scheme.id}>
            {scheme.name}
          </MenuItem>
        );
      });

      setOptions(optionsToSet);
    }
  }, [wagerSchemesList]);

  const handleChange = (e) => {
    setConnectingError("");
    setSelectedScheme(e.target.value || 0);
  };

  const confirm = async () => {
    setConnectingError("");
    if (
      (!connectedScheme && !selectedScheme) ||
      connectedScheme === selectedScheme
    ) {
      handleClose();
      return;
    }

    setLoading(true);
    try {
      await connectWagerScheme(roundId, selectedScheme || undefined);
      setLoading(false);
      handleClose();
    } catch (err) {
      setLoading(false);
      setConnectingError(
        err.response?.data?.message ||
          "Something went wrong. Please try again later!"
      );
    }
  };

  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle className={classes.alignCenter} id="alert-dialog-title">
        Select scheme to connect to <b>"{roundName}"</b> round!
      </DialogTitle>
      <DialogContent className={classes.content}>
        <FormControl className={classes.formControl} error={!!connectingError}>
          <InputLabel>Select Scheme</InputLabel>
          <Select
            className={classes.select}
            value={selectedScheme}
            onChange={handleChange}
          >
            <MenuItem key={0} value={0}>
              No Scheme
            </MenuItem>
            {options}
          </Select>
          {connectingError && (
            <FormHelperText>{connectingError}</FormHelperText>
          )}
        </FormControl>
      </DialogContent>
      <DialogActions className={classes.backButton}>
        <Button
          onClick={handleClose}
          variant="contained"
          color="default"
          className={classes.button}
          disabled={loading}
        >
          Cancel
        </Button>
        <Button
          onClick={confirm}
          variant="contained"
          color="primary"
          className={classes.button}
          disabled={loading}
        >
          {loading ? (
            <>
              Please wait
              <CircularProgress
                className={classes.circularProgress}
                color="inherit"
                size={16}
              />
            </>
          ) : (
            <> Connect</>
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default ConnectWagerSchemeModal;
