import LoginPage from "./LoginPage";

export const LoginPageConfig = {
  routes: [
    {
      path: "/pages/auth/login",
      exact: true,
      component: LoginPage,
      public: true,
    },
  ],
};
