import React from "react";
import {
	CircularProgress,
	makeStyles,
	Box,
	Grid,
	Typography,
	Container,
} from "@material-ui/core";

import { observer } from "mobx-react-lite";
import eduStyles from "./eduStyles";
import standardStyles from "./standardStyles";

import bannerImageEdu from "../../images/trivia-rat-education.png";
import bannerImage from "../../images/pub-quiz-trivia.png";

import { useStore } from "../../stores/helpers/useStore";

import "./auth.css";

const AuthWrapper = (props) => {
	const {
		authStore: { registerUser },
	} = useStore();
	const { history } = props;

	const classes = window.labs.isEducation ? eduStyles() : standardStyles();
	const imagePath = window.labs.isEducation ? bannerImageEdu : bannerImage;
	return (
		<div className={classes.root}>
			<Container maxWidth="md" style={{ paddingBottom: 200 }}>
				<Grid container spacing={3} style={{ maxWidth: 800 }}></Grid>
				<Box sx={{ m: 2 }} className={classes.customColor}>
					<Typography className={classes.textColor}> TriviaRat </Typography>
					<Typography className={classes.headerText}>
						{" "}
						{window.labs.title}
					</Typography>
				</Box>
				<Grid container spacing={1}>
					<Grid item xs={6}>
						<Box sx={{ m: 2 }}>{props.children}</Box>
					</Grid>

					<Grid item xs={6}>
						<img src={imagePath} className="banner-image" />
					</Grid>
				</Grid>
			</Container>
		</div>
	);
};

export default observer(AuthWrapper);
