import { makeAutoObservable, runInAction } from "mobx";
import { getRoot } from "mobx-easy";
import { arrayMove } from "react-sortable-hoc";

import SettingsService from "../../../services/SettingsService";
import { compareOrder } from "../../../utils/compare";
import CustomBranding from "./CustomBrandingSettings";
import GameSettings from "./GameSettings";
import PreEnteredRound from "./PreEnteredRound";
import PlayerSettings from "./PlayerSettings";
import ProGameOptions from "./ProGameOptions";
import WagerScheme from "./WagerScheme";

export default class SettingsStore {
  preEnteredRoundsList = [];
  wagerSchemesList = [];

  customBranding = {};
  gameSettings = {};
  proGameOptions = {};
  playerSettings = {};

  updatingTimer = null;
  rootStore = undefined;

  constructor() {
    makeAutoObservable(this);
    this.rootStore = getRoot();
  }

  clearSettingsStore = () => {
    this.preEnteredRoundsList = [];
    this.wagerSchemesList = [];

    this.customBranding = {};
    this.gameSettings = {};
    this.proGameOptions = {};
    this.playerSettings = {};
  };

  getListOfPreEnteredRounds = async () => {
    if (!!this.preEnteredRoundsList.length) return;

    this.rootStore.uiStore.startLoading();

    const response = await SettingsService.getListOfPreEnteredRounds();

    const roundsToSet = [];
    response.preEnteredRounds.forEach((round) => {
      roundsToSet.push(this.createRound(round));
    });

    runInAction(() => {
      this.rootStore.uiStore.cancelLoading();
      this.preEnteredRoundsList = roundsToSet;
    });
  };

  uploadPreEnteredRound = async (name, questions) => {
    const response = await SettingsService.uploadPreEnteredRound(
      name,
      questions
    );

    this.addRound(response.round);
  };

  updatePreEnteredRound = async (id, name, questions) => {
    const response = await SettingsService.updatePreEnteredRound(
      id,
      name,
      questions
    );

    this.updateRound(response.round);
  };

  uploadImage = async (image) => {
    const response = await SettingsService.uploadImage(image);

    return response;
  };

  deletePreEnteredRound = async (roundId) => {
    const response = await SettingsService.deletePreEnteredRound(roundId);

    this.removeRound(response.deletedRound);
  };

  addRound = (round) => {
    this.preEnteredRoundsList = [
      ...this.preEnteredRoundsList,
      this.createRound(round),
    ];
  };

  updateRound = (round) => {
    const foundIndex = this.preEnteredRoundsList.findIndex(
      (r) => r.id === round._id
    );
    if (foundIndex !== -1) {
      this.preEnteredRoundsList[foundIndex].questions = round.questions;
      this.preEnteredRoundsList[foundIndex].name = round.name;
    }
  };

  findPreEnteredRound = (roundId) => {
    return this.preEnteredRoundsList.find((round) => round.id === roundId);
  };

  removeRound = (roundId) => {
    this.preEnteredRoundsList = this.preEnteredRoundsList.filter(
      (round) => round.id !== roundId
    );
  };

  createRound = (round) => {
    return new PreEnteredRound(round);
  };

  getListOfWagerSchemes = async () => {
    if (!!this.wagerSchemesList.length) return;

    this.rootStore.uiStore.startLoading();

    const response = await SettingsService.getListOfWagerSchemes();

    runInAction(() => {
      this.setListOfWagerSchemes(response.wagerSchemes);
      this.rootStore.uiStore.cancelLoading();
    });
  };

  uploadWagerScheme = async (values) => {
    const response = await SettingsService.uploadWagerScheme(values);

    this.addWagerScheme(response.scheme);
  };

  editWagerScheme = async (values, schemeId) => {
    const response = await SettingsService.editWagerScheme({
      values,
      schemeId,
    });

    const schemeToUpdate = this.findWagerScheme(response.scheme._id);

    schemeToUpdate.updateScheme(response.scheme);
  };

  deleteWagerScheme = async (schemeId) => {
    const response = await SettingsService.deleteWagerScheme(schemeId);

    this.setListOfWagerSchemes(response.schemes);

    this.preEnteredRoundsList.forEach((round) => {
      if (round.wagerScheme?.id === schemeId) {
        round.updateWagerScheme(null);
      }
    });
  };

  updateSchemesOrder = async (oldIndex, newIndex) => {
    // if (oldIndex !== newIndex) {
    const arrayToSet = arrayMove(this.wagerSchemesList, oldIndex, newIndex);
    arrayToSet.map((el, i) => (el.order = i + 1));

    this.setListOfWagerSchemes(arrayToSet);

    // send only latest update in last 800ms
    if (this.updatingTimer) {
      clearTimeout(this.updatingTimer);
    }
    return new Promise((resolve, reject) => {
      this.updatingTimer = setTimeout(async () => {
        try {
          const response = await SettingsService.updateSchemesOrder(arrayToSet);
          this.setListOfWagerSchemes(response.schemes);
          resolve();
        } catch (err) {
          reject(err);
        }
      }, 1500);
    });
    // }
  };

  clearSchemeOrderUpdatingTimeout = () => {
    if (this.updatingTimer) {
      clearTimeout(this.updatingTimer);
    }
  };

  setListOfWagerSchemes = (schemes) => {
    const schemesToSet = [];
    schemes.sort(compareOrder).forEach((scheme) => {
      schemesToSet.push(this.createWagerScheme(scheme));
    });

    this.wagerSchemesList = schemesToSet;
  };

  addWagerScheme = (scheme) => {
    this.wagerSchemesList = [
      ...this.wagerSchemesList,
      this.createWagerScheme(scheme),
    ];
  };

  findWagerScheme = (schemeId) => {
    return this.wagerSchemesList.find((scheme) => scheme.id === schemeId);
  };

  // removeScheme = (schemeId) => {
  //   this.wagerSchemesList = this.wagerSchemesList.filter(
  //     (scheme) => scheme.id !== schemeId
  //   );
  // };

  createWagerScheme = (scheme) => {
    return new WagerScheme(scheme);
  };

  connectWagerScheme = async (roundId, schemeId) => {
    const response = await SettingsService.connectWagerScheme(
      roundId,
      schemeId
    );

    const roundToUpdate = this.findPreEnteredRound(roundId);

    runInAction(() => {
      roundToUpdate.updateWagerScheme(response.round.wagerScheme);
    });
  };

  getCustomBrandingSettings = async () => {
    if (Object.keys(this.customBranding).length !== 0) {
      return;
    }

    this.rootStore.uiStore.startLoading();

    const response = await SettingsService.getCustomBrandingSettings();

    runInAction(() => {
      this.rootStore.uiStore.cancelLoading();
      this.customBranding = new CustomBranding(response.customBranding);
    });
  };

  editCustomBrandingSettings = async (customBranding) => {
    const response = await SettingsService.editCustomBrandingSettings(
      customBranding
    );

    this.customBranding.update(response.customBranding);
  };

  getSettings = async () => {
    if (
      Object.keys(this.gameSettings).length !== 0 &&
      Object.keys(this.proGameOptions).length !== 0 &&
      Object.keys(this.playerSettings).length !== 0
    ) {
      return;
    }

    this.rootStore.uiStore.startLoading();

    const response = await SettingsService.getSettings();

    runInAction(() => {
      this.gameSettings = new GameSettings(response.gameSettings);
      this.proGameOptions = new ProGameOptions(response.proGameOptions);
      this.playerSettings = new PlayerSettings(response.playerSettings);
      this.rootStore.uiStore.cancelLoading();
    });
  };

  updateProGameOptions = async (proGameOptions) => {
    const response = await SettingsService.updateProGameOptions(proGameOptions);

    this.proGameOptions.update(response.proGameOptions);
  };

  updateGameSettings = async (gameSettings) => {
    const response = await SettingsService.updateGameSettings(gameSettings);

    this.gameSettings.update(response.gameSettings);
  };

  updatePlayerSettings = async (playerSettings) => {
    const response = await SettingsService.updatePlayerSettings(playerSettings);

    this.playerSettings.update(response.playerSettings);
  };
}
