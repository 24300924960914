import ApiService from "../abstracts/ApiService";

class SettingsService extends ApiService {
	async getListOfPreEnteredRounds() {
		const response = await this.protectedRequest({
			url: "/admin/rounds-list",
		});

		return response;
	}

	async uploadPreEnteredRound(roundName, questions) {
		const response = await this.protectedRequest({
			url: "/admin/create-preentered-round",
			method: "post",
			data: { roundName, questions },
		});

		return response;
	}

	async updatePreEnteredRound(roundId, roundName, questions) {
		const response = await this.protectedRequest({
			url: "/admin/update-preentered-round",
			method: "post",
			data: { roundId, roundName, questions },
		});

		return response;
	}

	async uploadImage(image) {
		const fd = new FormData();
		fd.append("image", image);
		const response = await this.protectedFormDataRequest({
			url: "/auth/upload-image",
			method: "post",
			data: fd,
		});

		return response;
	}

	async deletePreEnteredRound(roundId) {
		const response = await this.protectedRequest({
			url: "/admin/delete-round",
			method: "delete",
			data: { roundId },
		});

		return response;
	}

	async uploadWagerScheme(values) {
		const response = await this.protectedRequest({
			url: "/admin/wager-scheme",
			method: "post",
			data: {
				...values,
			},
		});

		return response;
	}

	async getListOfWagerSchemes() {
		const response = await this.protectedRequest({
			url: "/admin/wagers-list",
		});

		return response;
	}

	async editWagerScheme({ values, schemeId }) {
		const response = await this.protectedRequest({
			url: "/admin/wager-scheme",
			method: "put",
			data: {
				...values,
				schemeId,
			},
		});

		return response;
	}

	async deleteWagerScheme(schemeId) {
		const response = await this.protectedRequest({
			url: "/admin/scheme",
			method: "delete",
			data: { schemeId },
		});

		return response;
	}

	async updateSchemesOrder(schemes) {
		const response = await this.protectedRequest({
			url: "/admin/schemes-order",
			method: "put",
			data: { schemes },
		});

		return response;
	}

	async connectWagerScheme(roundId, schemeId) {
		const response = await this.protectedRequest({
			url: "/admin/connect-wager-scheme",
			method: "put",
			data: { roundId, schemeId },
		});

		return response;
	}

	async getCustomBrandingSettings() {
		const response = await this.protectedRequest({
			url: "/admin/custom-branding",
		});

		return response;
	}

	async editCustomBrandingSettings({ customTitle, customUrl, customUrlTitle }) {
		const response = await this.protectedRequest({
			url: "/admin/custom-branding",
			method: "put",
			data: { customTitle, customUrl, customUrlTitle },
		});

		return response;
	}

	async getSettings() {
		const response = await this.protectedRequest({
			url: "/admin/settings",
		});

		return response;
	}

	async updateProGameOptions({
		switchRounds,
		adjustByResponse,
		showQuestions,
		adjustByQuestion,
		maxPointValue,
		pauseModeContent,
		gamePauseMessage,
		displayCorrectAnswer,
	}) {
		const response = await this.protectedRequest({
			url: "/admin/pro-game-options",
			method: "put",
			data: {
				switchRounds,
				adjustByResponse,
				showQuestions,
				adjustByQuestion,
				maxPointValue,
				pauseModeContent,
				gamePauseMessage,
				displayCorrectAnswer,
			},
		});

		return response;
	}

	async updateGameSettings({
		playersLimit,
		questionsPerRound,
		halfPoints,
		showAnswers,
		showScores,
		helperTips,
		scoringInterval,
	}) {
		const response = await this.protectedRequest({
			url: "/admin/game-settings",
			method: "put",
			data: {
				playersLimit,
				questionsPerRound,
				halfPoints,
				showAnswers,
				showScores,
				helperTips,
				scoringInterval,
			},
		});

		return response;
	}

	async updatePlayerSettings(params) {
		const response = await this.protectedRequest({
			url: "/admin/player-settings",
			method: "put",
			data: params,
		});

		return response;
	}
}

const instance = new SettingsService();

export default instance;
