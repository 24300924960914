import React from "react";

import OftadehNavCollapse from "./OftadehNavCollapse";
import OftadehNavItem from "./OftadehNavItem";
import OftadehNavLink from "./OftadehNavLink";
import { ListSubheader } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import Chip from "@material-ui/core/Chip";

const OftadehNavGroup = (props) => {
	const { item } = props;
	return (
		<>
			<ListSubheader>{item.title}</ListSubheader>

			{item.children && (
				<React.Fragment>
					{item.children.map((item) => (
						<React.Fragment key={item.id}>
							{item.type === "group" && <NavGroup item={item} />}

							{item.type === "collapse" && <OftadehNavCollapse item={item} />}

							{item.type === "item" && <OftadehNavItem item={item} />}

							{item.type === "link" && <OftadehNavLink item={item} />}

							{item.type === "divider" && (
								<div
									style={{
										height: "2px",
										backgroundColor: "#333434",
										marginBottom: "6px",
										marginTop: "6px",
									}}
								>
									<Divider />
								</div>
							)}
						</React.Fragment>
					))}
				</React.Fragment>
			)}
		</>
	);
};

const NavGroup = React.memo(OftadehNavGroup);

export default NavGroup;
