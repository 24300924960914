import * as React from "react";
import {
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  MenuItem,
  DialogTitle,
  useMediaQuery,
  useTheme,
  CircularProgress,
  Select,
} from "@material-ui/core";
import { Publish as UploadIcon } from "@material-ui/icons";
import { useFormik } from "formik";
import * as Yup from "yup";

const moveElementInArray = (arr, from, to) => {
  arr.splice(to, 0, arr.splice(from, 1)[0]);
  return arr;
};

const questionsValidationSchema = Yup.object().shape({
  question: Yup.string().trim().required("Question name is required"),
  answer: Yup.string().trim().required("Answer name is required"),
  imageUrl: Yup.string()
    .trim()
    .matches(
      "/^((https?)://)?([w|W]{3}.)+[a-zA-Z0-9-.]{3,}.[a-zA-Z]{2,}(.[a-zA-Z]{2,})?$/",
      "This is not a valid url"
    )
    .required("Required"),
});

export default function CreateQuestionModal(props) {
  const [open, setOpen] = React.useState(props.open);
  const fileInput = React.createRef();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [questionsError, setQuestionsError] = React.useState("");
  const [uploadedImage, setUploadedImage] = React.useState(null);
  const [uploadedImageUrl, setUploadedImageUrl] = React.useState(null);
  const [imageUploadLoader, setImageUploadLoader] = React.useState(false);
  // const [timeForQuestions, setTimeForQuestions] = React.useState(false);

  const handleClose = () => {
    props.setOpen(false);
  };
  const fileHandler = (e) => {
    setUploadedImage(e.target.files[0]);
  };

  React.useEffect(() => {
    if (uploadedImage) {
      uploadImage();
    }
  }, [uploadedImage]);

  const uploadImage = async () => {
    setImageUploadLoader(true);
    try {
      let response = await props.uploadImage(uploadedImage);
      setImageUploadLoader(false);
      if (response.url) {
        setUploadedImageUrl(response.url);
      }
    } catch (err) {
      setImageUploadLoader(false);
    }
  };

  const questionsFormik = useFormik({
    initialValues:
      props.mode === "edit"
        ? props.prevQuestion
        : { question: "", answer: "", imageUrl: "", orderNum: 0 },
    questionsValidationSchema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      props.mode === "edit"
        ? editQuestion(
            {
              ...props.editableStateValues,
              questionText: values.question,
              answerText: values.answer,
              orderNum: values.orderNum,
              image: uploadedImageUrl ? uploadedImageUrl : values.imageUrl,
            },

            props.editableStateValues.index
          )
        : props.setQuestions([
            ...props.questions,
            {
              questionText: values.question,
              answerText: values.answer,
              orderNum: props.questions.length + 1,
              image: uploadedImageUrl ? uploadedImageUrl : values.imageUrl,
            },
          ]);

      props.setOpen(false);
      resetForm();
      setUploadedImageUrl(null);
    },
  });

  const editQuestion = async (updatedItem, index) => {
    const oldItem = props.questions[index];
    const updatedQuestions = moveElementInArray(
      props.questions.map((item, i) => (index === i ? updatedItem : item)),
      oldItem.orderNum - 1,
      updatedItem.orderNum - 1
    ).map((q, i) => ({
      ...q,
      orderNum: i + 1,
    }));
    props.setQuestions(updatedQuestions);
    handleClose();
  };

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        open={props.open}
        onClose={imageUploadLoader ? null : handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle
          id="responsive-dialog-title"
          style={{ textAlign: "center" }}
        >
          Round: "{props.roundName}"
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter the question and answer for the round '{props.roundName}'
            here. These questions will be available (in pre-entered mode) to all
            your hosts.
          </DialogContentText>
          {uploadedImageUrl && (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <img
                src={uploadedImageUrl}
                alt="questionImage"
                style={{ width: 100, height: 100, objectFit: "cover" }}
              />
            </div>
          )}
          <TextField
            size="small"
            label="Question"
            name="question"
            variant="outlined"
            margin="dense"
            autoComplete="off"
            fullWidth
            value={questionsFormik.values.question}
            onChange={questionsFormik.handleChange}
            onBlur={questionsFormik.handleBlur}
            error={
              !!questionsFormik.touched.question &&
              !!questionsFormik.errors.question
            }
            helperText={
              questionsFormik.touched.question &&
              questionsFormik.errors.question
            }
          />
          <TextField
            size="small"
            label="Answer"
            name="answer"
            variant="outlined"
            margin="dense"
            autoComplete="off"
            fullWidth
            value={questionsFormik.values.answer}
            onChange={questionsFormik.handleChange}
            onBlur={questionsFormik.handleBlur}
            error={
              !!questionsFormik.touched.answer &&
              !!questionsFormik.errors.answer
            }
            helperText={
              questionsFormik.touched.answer && questionsFormik.errors.answer
            }
          />
          <div style={{ display: "flex", alignItems: "center" }}>
            <TextField
              size="small"
              label="Image Url"
              name="imageUrl"
              variant="outlined"
              margin="dense"
              autoComplete="off"
              value={questionsFormik.values.imageUrl}
              onChange={questionsFormik.handleChange}
              onBlur={questionsFormik.handleBlur}
              error={
                !!questionsFormik.touched.imageUrl &&
                !!questionsFormik.errors.imageUrl
              }
              helperText={
                questionsFormik.touched.imageUrl &&
                questionsFormik.errors.imageUrl
              }
            />
            <div style={{ color: "#212121", fontWeight: "500" }}>
              &nbsp;&nbsp;{" or upload an image:"}&nbsp;&nbsp;
            </div>

            <Button
              disabled={imageUploadLoader}
              variant="contained"
              style={{ height: 38 }}
              color="default"
              onClick={() => {
                fileInput.current.click();
              }}
              startIcon={<UploadIcon />}
            >
              {imageUploadLoader ? (
                <CircularProgress style={{ width: 25, height: 25 }} />
              ) : (
                <>Upload</>
              )}
            </Button>
            <input
              onChange={fileHandler}
              hidden
              accept="image/*"
              ref={fileInput}
              multiple
              type="file"
            />
          </div>
          <div>
            {props.mode === "edit" && (
              <Select
                name="orderNum"
                value={questionsFormik.values.orderNum}
                onChange={questionsFormik.handleChange}
                onBlur={questionsFormik.handleBlur}
              >
                {props.questions.map((question) => (
                  <MenuItem value={question.orderNum}>
                    {question.orderNum}
                  </MenuItem>
                ))}
              </Select>
            )}
          </div>
        </DialogContent>
        <DialogActions
          style={{ padding: "0px 23px 10px", marginBottom: "15px" }}
        >
          <Button
            disabled={imageUploadLoader}
            fullWidth
            color="secondary"
            variant="contained"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            disabled={imageUploadLoader}
            fullWidth
            color="primary"
            variant="contained"
            onClick={questionsFormik.handleSubmit}
          >
            {props.mode === "edit" ? <>Update</> : <>Save Question</>}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
