import React from "react";
import { PayPalButton } from "react-paypal-button-v2";

const paypalKey = process.env.REACT_APP_PAYPAL_CLIENT_ID;

export const SubscriptionBtn = ({
  amount,
  currency,
  createSubscription,
  onApprove,
  catchError,
  onError,
  onCancel,
  setButtonReady,
}) => {
  return (
    <PayPalButton
      // amount={amount}
      // currency={currency}
      onButtonReady={() => setButtonReady(true)}
      createSubscription={(data, details) => createSubscription(data, details)}
      onApprove={(data, details) => onApprove(data, details)}
      onError={(err) => onError(err)}
      catchError={(err) => catchError(err)}
      onCancel={(err) => onCancel(err)}
      options={{
        clientId: paypalKey,
        vault: true,
        intent: "subscription",
      }}
      style={{
        shape: "rect",
        color: "blue",
        layout: "vertical",
        label: "subscribe",
      }}
    />
  );
};
export default SubscriptionBtn;
