import React from "react";
import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import {
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { useFormik } from "formik";
import * as Yup from "yup";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
const useStyles = makeStyles({
  table: {
    "& tbody": {
      textTransform: "uppercase",

      "& th,td": {
        fontWeight: "bold",
      },
    },
  },
});

const questionsValidationSchema = Yup.object().shape({
  questionText: Yup.string().trim().required("Question name is required"),
  answerText: Yup.string().trim().required("Answer name is required"),
});

const PreEnteredRoundQuestions = ({ questions, setQuestions, roundName }) => {
  const classes = useStyles();
  const [editableState, setEditableState] = React.useState(false);
  const [editableStateValues, setEditableStateValuea] = React.useState(null);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const deleteQuestion = async (index) => {
    let updatedQuestions = await Promise.all(
      questions.filter((item, i) => i !== index)
    );
    setQuestions(updatedQuestions);
  };

  const questionsFormik = useFormik({
    initialValues: { questionText: "", answerText: "" },
    questionsValidationSchema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      editQuestion(
        {
          ...editableStateValues,
          questionText: values.questionText,
          answerText: values.answerText,
        },
        editableStateValues.index
      );
    },
  });

  const editQuestion = async (updatedItem, index) => {
    let updatedQuestions = await Promise.all(
      questions.map((item, i) => {
        if (index === i) {
          return updatedItem;
        } else return item;
      })
    );
    setQuestions(updatedQuestions);
    setEditableState(false);
  };
  return (
    <>
      <TableContainer>
        <Table
          className={classes.table}
          style={{ tableLayout: "fixed" }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              <TableCell width={20} align="left">
                №
              </TableCell>
              <TableCell align="center">Questions</TableCell>
              <TableCell align="center">Answers</TableCell>
              {questions.some(
                (item) => item["image"] !== "" && item["image"] !== null
              ) && <TableCell align="center"></TableCell>}
              <TableCell align="center"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {questions.map((row, index) => (
              <TableRow key={index}>
                <TableCell align="left">{index + 1}</TableCell>

                <TableCell align="center">{row.questionText}</TableCell>
                <TableCell align="center">{row.answerText}</TableCell>
                {questions.some(
                  (item) => item["image"] !== "" && item["image"] !== null
                ) &&
                row.image &&
                row.image.length ? (
                  <TableCell align="center">
                    <img
                      src={row.image}
                      style={{ width: 100, height: 100, objectFit: "cover" }}
                    />
                  </TableCell>
                ) : (
                  <TableCell align="center">
                    <h5 style={{ display: "none" }}>asd</h5>
                  </TableCell>
                )}
                <TableCell align="center">
                  <EditIcon
                    onClick={() => {
                      setEditableState(true);
                      setEditableStateValuea({ ...row, index });
                      questionsFormik.setValues({
                        questionText: row.questionText,
                        answerText: row.answerText,
                      });
                    }}
                    fontSize="15px"
                    style={{ cursor: "pointer" }}
                  />
                  &nbsp;
                  <DeleteIcon
                    onClick={() => deleteQuestion(index)}
                    fontSize="10px"
                    style={{ cursor: "pointer" }}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {editableState && (
        <Dialog
          fullScreen={fullScreen}
          open={true}
          // onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle
            id="responsive-dialog-title"
            style={{ textAlign: "center" }}
          >
            Round: "{roundName}"
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Enter the question and answer for the round '{roundName}' here.
              These questions will be available (in pre-entered mode) to all
              your hosts.
            </DialogContentText>
            <TextField
              size="small"
              label="Question"
              name="questionText"
              variant="outlined"
              margin="dense"
              autoComplete="off"
              fullWidth
              value={questionsFormik.values.questionText}
              onChange={questionsFormik.handleChange}
              onBlur={questionsFormik.handleBlur}
              error={
                !!questionsFormik.touched.questionText &&
                !!questionsFormik.errors.questionText
              }
              helperText={
                questionsFormik.touched.questionText &&
                questionsFormik.errors.questionText
              }
            />
            <TextField
              size="small"
              label="Answer"
              name="answerText"
              variant="outlined"
              margin="dense"
              autoComplete="off"
              fullWidth
              value={questionsFormik.values.answerText}
              onChange={questionsFormik.handleChange}
              onBlur={questionsFormik.handleBlur}
              error={
                !!questionsFormik.touched.answerText &&
                !!questionsFormik.errors.answerText
              }
              helperText={
                questionsFormik.touched.answerText &&
                questionsFormik.errors.answerText
              }
            />
          </DialogContent>
          <DialogActions
            style={{ padding: "0px 23px 10px", marginBottom: "15px" }}
          >
            <Button
              fullWidth
              color="secondary"
              variant="contained"
              onClick={() => setEditableState(false)}
            >
              Cancel
            </Button>
            <Button
              fullWidth
              color="primary"
              variant="contained"
              onClick={questionsFormik.handleSubmit}
            >
              Update
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default PreEnteredRoundQuestions;
