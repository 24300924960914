import React, { useCallback, useEffect, useState } from "react";
import OftadehLayout from "../../../components/OftadehLayout/OftadehLayout";
import { Paper, Button, Grid } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import GameSettings from "../../../components/GameSettings/GameSettings";
import ProGameOptions from "../../../components/ProGameOptions/ProGameOptions";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import { useStore } from "../../../stores/helpers/useStore";
import { useIsMounted } from "../../../helpers/useIsMounted";
import UpdateRoomCode from "../../../components/UpdateRoomCode/UpdateRoomCode";
import ChangePassword from "../../../components/ChangePassword/ChangePassword";
import TipModal from "../../../components/Modals/TipModal/TipModal";

const EditHost = (props) => {
  const { history } = props;
  const { roomCode } = useParams();
  const isMounted = useIsMounted();
  const [host, setHost] = useState({});
  const [modalContent, setModalContent] = useState();
  const [openTipModal, setOpenTipModal] = useState(false);

  const {
    hostsStore: { getAdvancedSettings },
    uiStore: { loading },
  } = useStore();

  const handleGetAdvancedSettings = useCallback(async () => {
    const hostToEdit = await getAdvancedSettings(roomCode);

    if (!hostToEdit) {
      history.push("/hosts");
      return;
    }

    setHost(hostToEdit);
  }, [getAdvancedSettings, roomCode, history]);

  useEffect(() => {
    handleGetAdvancedSettings();
  }, [handleGetAdvancedSettings]);

  const handleClose = () => {
    setOpenTipModal(false);
  };

  return (
    <>
      <OftadehLayout
        loading={loading || !isMounted || Object.keys(host).length === 0}
      >
        <h1>Edit Host</h1>

        <Grid item>
          <Alert severity="info">
            These settings will be applied to this individual host only!
          </Alert>
        </Grid>

        <Paper style={{ padding: "0 50px" }}>
          <Grid container spacing={2}>
            <Grid item md={6} xs={12}>
              <UpdateRoomCode
                roomCode={host.roomCode}
                updateRoomCode={host.updateRoomCode}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <ChangePassword
                roomCode={host.roomCode}
                updatePassword={host.updatePassword}
              />
            </Grid>

            <Grid item md={6} xs={12}>
              <GameSettings
                gameSettings={host.gameSettings}
                setModalContent={setModalContent}
                setOpenTipModal={setOpenTipModal}
                updateGameSettings={host.updateHostGameSettings}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <ProGameOptions
                proGameOptions={host.proGameOptions}
                setModalContent={setModalContent}
                setOpenTipModal={setOpenTipModal}
                updateProGameOptions={host.updateHostProGameOptions}
              />
            </Grid>
          </Grid>

          <Grid
            item
            container
            direction="row"
            justify="center"
            alignItems="center"
            style={{ paddingBottom: "15px" }}
          >
            <Button variant="contained" onClick={() => history.push("/hosts")}>
              Back
            </Button>
          </Grid>
        </Paper>
      </OftadehLayout>

      <TipModal open={openTipModal} handleClose={handleClose}>
        {modalContent}
      </TipModal>
    </>
  );
};

export default observer(EditHost);
