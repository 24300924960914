import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { CircularProgress, makeStyles } from "@material-ui/core";

import SubscriptionBtn from "../../PaypalBtn/SubscriptionBtn";

const useStyles = makeStyles({
  backButton: {
    display: "flex",
    justifyContent: "center",
  },
  circularProgress: {
    color: "#3f51b5",
    display: "flex",
    justifyContent: "center",
    height: "80%",
    alignItems: "center",
  },
  title: {
    textAlign: "center",
  },
  content: {
    minWidth: 500,
    textAlign: "center",
  },
});

const CreateSubscriptionModal = ({
  open,
  loading,
  approved,
  handleClose,
  paypalSubscribe,
  onError,
  onApprove,
  hostsQuantity,
  price,
  onCancel,
}) => {
  const [buttonReady, setButtonReady] = useState(false);
  const classes = useStyles();

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle className={classes.title} id="alert-dialog-title">
          Start your subscription?
        </DialogTitle>
        <DialogContent className={classes.content}>
          <DialogContentText id="alert-dialog-description">
            You will have {hostsQuantity} hosts with monthly payment of {price}$
          </DialogContentText>

          {!approved && (
            <SubscriptionBtn
              createSubscription={paypalSubscribe}
              onApprove={onApprove}
              catchError={onError}
              onError={onError}
              onCancel={onCancel}
              setButtonReady={setButtonReady}
            />
          )}
          {(!buttonReady || loading) && (
            <div className={classes.circularProgress}>
              <CircularProgress color="inherit" size={40} />
            </div>
          )}
        </DialogContent>
        <DialogActions className={classes.backButton}>
          <Button onClick={handleClose} color="primary">
            Go Back
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default CreateSubscriptionModal;
