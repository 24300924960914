import React, { useCallback, useEffect, useState } from "react";

import { observer } from "mobx-react-lite";

import { useStore } from "../../stores/helpers/useStore";

import { getSubscriptionPrice } from "../../helpers/getSubscriptionPrice";

import OftadehLayout from "../../components/OftadehLayout/OftadehLayout";
import CreateSubscription from "./Stages/CreateSubscription";
import UpdateSubscription from "./Stages/UpdateSubscription/UpdateSubscription";
import ViewSubscription from "./Stages/ViewSubscription/ViewSubscription";

const SubscriptionPage = (props) => {
	const {
		authStore: { email },
		subscriptionStore: {
			subscriptionQuantity,
			nextCycleQuantity,
			subscriptionStatus,
			subscriptionTiers,
			subscriptionEnd,
			subscriptionApprovalLink,
			getSubscriptionInfo,
			getSubscriptionPlanInfo,
			createAdminSubscription,
			updateAdminSubscription,
			cancelAdminSubscription,
		},
		hostsStore: { hostsList, getListOfHosts },
		uiStore: { loading },
	} = useStore();

	const [stage, setStage] = useState("VIEW"); // VIEW | CREATE | UPDATE
	const { history } = props;
	const [error, setError] = useState(null);

	const getSubscription = useCallback(async () => {
		try {
			await getSubscriptionPlanInfo();
			await getSubscriptionInfo();
		} catch {
			// history.push("/pages/errors/error-500");
			setError("Something went wrong, please try again later");
			//set loading is false
			//setLoading(false);
		}
	}, [getSubscriptionPlanInfo, getSubscriptionInfo, history]);

	// get subsscriptionPlanInfo from paypal and subscriptionInfo from BE
	useEffect(() => {
		getSubscription();
	}, [getSubscription]);

	useEffect(() => {
		getListOfHosts();
	}, [getListOfHosts]);

	return (
		<OftadehLayout loading={loading || !subscriptionTiers.length}>
			{
				{
					VIEW: (
						<ViewSubscription
							subscriptionQuantity={subscriptionQuantity}
							nextCycleQuantity={nextCycleQuantity}
							subscriptionEnd={subscriptionEnd}
							subscriptionStatus={subscriptionStatus}
							subscriptionTiers={subscriptionTiers}
							subscriptionApprovalLink={subscriptionApprovalLink}
							cancelAdminSubscription={cancelAdminSubscription}
							createSubscription={() => setStage("CREATE")}
							updateSubscription={() => setStage("UPDATE")}
							getPrice={getSubscriptionPrice}
						/>
					),
					CREATE: (
						<CreateSubscription
							subscriptionQuantity={subscriptionQuantity}
							subscriptionEnd={subscriptionEnd}
							subscriptionStatus={subscriptionStatus}
							createAdminSubscription={createAdminSubscription}
							getPrice={getSubscriptionPrice}
							back={() => setStage("VIEW")}
						/>
					),
					UPDATE: (
						<UpdateSubscription
							subscriptionQuantity={subscriptionQuantity}
							nextCycleQuantity={nextCycleQuantity}
							updateAdminSubscription={updateAdminSubscription}
							email={email}
							getPrice={getSubscriptionPrice}
							existingHostsQuantity={hostsList.length}
							back={() => setStage("VIEW")}
							goToHostsList={() => history.push("/hosts")}
						/>
					),
				}[stage]
			}
		</OftadehLayout>
	);
};

export default observer(SubscriptionPage);
