import { wrapRoot } from "mobx-easy";
import RootStore from "../RootStore";

const createStore = () => {
  const rootStore = wrapRoot({
    RootStore: RootStore,
    env: {},
  });
  return rootStore;
};

export { createStore };
