import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { CircularProgress, makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  backButton: {
    display: "flex",
    justifyContent: "center",
  },
  alignCenter: {
    textAlign: "center",
  },
  circularProgress: {
    color: "#3f51b5",
    display: "flex",
    justifyContent: "center",
    height: "80%",
    alignItems: "center",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
});

const DecreaseSubscriptionModal = ({
  open,
  hostsQuantity,
  price,
  loading,
  update,
  handleClose,
}) => {
  const classes = useStyles();

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle className={classes.alignCenter} id="alert-dialog-title">
          Update your subscription
        </DialogTitle>
        <DialogContent className={classes.content}>
          <DialogContentText id="alert-dialog-description">
            You will have <b>{hostsQuantity}</b> hosts with monthly payment of{" "}
            <b>{price}$</b>
          </DialogContentText>
          {loading ? (
            <div className={classes.circularProgress}>
              <CircularProgress color="inherit" size={40} />
            </div>
          ) : (
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={update}
            >
              Update
            </Button>
          )}
        </DialogContent>
        <DialogActions className={classes.backButton}>
          <Button onClick={handleClose} color="primary">
            Go Back
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default DecreaseSubscriptionModal;
