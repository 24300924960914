import React from "react";
import { makeStyles } from "@material-ui/core";

const eduStyles = makeStyles((theme) => ({
	root: {
		backgroundd: "#0d131d",
		background: "linear-gradient(135deg, #311a1a, #111020,#00001c)",
		width: "100vw",
		height: "100vh",
		display: "flex",
		justifyContent: "center",
		flexDirection: "column",
		alignItems: "center",
		textAlign: "center",
	},
	mBottom: {
		marginBottom: ".5rem",
		color: "#898989",
	},
	button: {
		marginTop: ".85rem",
	},
	loginCard: {
		borderRadius: 5,
		background: "#fff",
		padding: ".85rem",
	},
	textColor: {
		color: "white",
	},
	headerText: {
		color: "#dfdfdf",
		fontWeight: 800,
		fontSize: "2rem",
	},
	customColor: {
		color: "#dfdfef",
		fontWeight: "800px",
		padding: "14px",
		lineHeight: "3px",
	},
}));

export default eduStyles;
