import React from "react";
import { Redirect } from "react-router-dom";

//import { DashboardPageConfig } from "../pages/dashboard/DashboardPageConfig";

// import { AboutPageConfig } from "../pages/about/AboutPageConfig";
// import { AddPostPageConfig } from "../pages/posts/add-post/AddPostPageConfig";
// import { AllPostsPageConfig } from "../pages/posts/all-posts/AllPostsPageConfig";
// import { CalendarPageConfig } from "../pages/calendar/CalendarPageConfig";

import { HostsPageConfig } from "../pages/hosts/HostsPageConfig";
import { SettingsPageConfig } from "../pages/settings/SettingsPageConfig";
import { PlayerSettingConfig } from "../pages/settings/player/PlayerSettingPageConfig";
import { QuestionsPageConfig } from "../pages/questions/QuestionsPageConfig";
import { ForgotPasswordPageConfig } from "../pages/auth/forgot-password/ForgotPasswordPageConfig";
import { ResetPasswordPageConfig } from "../pages/auth/reset-password/ResetPasswordPageConfig";
import { LoginPageConfig } from "../pages/auth/login/LoginPageConfig";
import { RegisterPageConfig } from "../pages/auth/register/RegisterPageConfig";
import { VerificationPageConfig } from "../pages/auth/verification/VerificationPageConfig";
import { VerifyEmailPageConfig } from "../pages/auth/verify-email/VerifyEmailPageConfig";
import { SubscriptionPageConfig } from "../pages/subscription/SubscriptionPageConfig";
import { Error404PageConfig } from "../pages/errors/404/Error404PageConfig";
import { Error500PageConfig } from "../pages/errors/500/Error500PageConfig";
import { ContactPageConfig } from "../pages/contact/ContactPageConfig";
import { GuidePageConfig } from "../pages/guide/GuidePageConfig";
import { WagersPageConfig } from "../pages/wagers/WagersPageConfig";
import { BrandPageConfig } from "../pages/brand/BrandPageConfig";
import { UserConfig } from "../pages/user/UserConfig";
import { GameHistoryConfig } from "../pages/game-history/GameHistoryConfig";

// import { DocumentationConfig } from "../pages/documentation/DocumentationConfig";

const routeConfigs = [
	//...DashboardPageConfig.routes,
	// ...AllPostsPageConfig.routes,
	// ...AddPostPageConfig.routes,
	// ...CalendarPageConfig.routes,
	...HostsPageConfig.routes,
	...SettingsPageConfig.routes,
	...PlayerSettingConfig.routes,
	...UserConfig.routes,
	...GameHistoryConfig.routes,
	...QuestionsPageConfig.routes,
	...WagersPageConfig.routes,
	...ForgotPasswordPageConfig.routes,
	...LoginPageConfig.routes,
	...RegisterPageConfig.routes,
	...Error404PageConfig.routes,
	...Error500PageConfig.routes,
	// ...AboutPageConfig.routes,
	...VerificationPageConfig.routes,
	...ResetPasswordPageConfig.routes,
	...VerifyEmailPageConfig.routes,
	...SubscriptionPageConfig.routes,
	...ContactPageConfig.routes,
	...GuidePageConfig.routes,
	...BrandPageConfig.routes,
];

const routes = [
	...routeConfigs,
	{
		component: () => <Redirect to="/pages/errors/error-404" />,
	},
];

export default routes;
