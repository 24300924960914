import React, { useCallback, useEffect, useMemo, useState } from "react";
import OftadehLayout from "../../components/OftadehLayout/OftadehLayout";
import {
	Grid,
	Typography,
	makeStyles,
	Paper,
	Box,
	TextField,
	Button,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { observer } from "mobx-react-lite";
import { useIsMounted } from "../../helpers/useIsMounted";
import { useStore } from "../../stores/helpers/useStore";
import GameResultsTable from "./GameResultsTable";

const useStyles = makeStyles(() => ({
	titlePaper: {
		marginBottom: "16px",
	},
	availableSpace: {
		fontVariant: "diagonal-fractions",
		fontSize: 36,
	},
}));

const RecordList = (props) => {
	const classes = useStyles();
	const isMounted = useIsMounted();
	const [isLoading, setIsLoading] = useState(true);
	const [email, setEmail] = useState("");

	const [startDate, setStartDate] = useState(() => {
		const date = new Date();
		date.setDate(date.getDate() - 7);
		return date.toISOString().split("T")[0];
	});
	const [endDate, setEndDate] = useState(
		new Date().toISOString().split("T")[0]
	);

	const [teamName, setTeamName] = useState("");

	const {
		hostsStore: { gameResults, getListOfGameResults },

		uiStore: { loading },
	} = useStore();

	useEffect(() => {
		// Initial fetch with default or current state values
		getListOfGameResults(startDate, endDate).then(() =>
			setIsLoading(false)
		);
	}, []); // Empty dependency array to only run once on mount

	const handleRefreshClick = () => {
		// Use the most current state values directly
		getListOfGameResults(startDate, endDate).then(() =>
			setIsLoading(false)
		);
	};

	const handleKeyDown = (event) => {
		if (event.key === "Enter") {
			handleRefreshClick();
		}
	};
	const filteredResults = useMemo(() => {
		if (gameResults.length) {
			if (!email.length && !teamName.length) {
				return gameResults;
			}
			return gameResults.filter((gameResult) => {
				if (email.length && (!gameResult.email || !gameResult.email.toLowerCase().includes(email.toLowerCase()))) {
					return false;
				}
				if (teamName.length && !gameResult.team.toLowerCase().includes(teamName.toLowerCase())) {
					return false;
				}
				return true;
			})
		} else {
			return;
		}
	}, [gameResults, email, teamName])
	return (
		<>
			<OftadehLayout loading={loading || !isMounted || isLoading}>
				<Box style={{ marginBottom: "20px" }}>
					<h2>Previous Game Data</h2>
					{/* <Link to="/round-history" sx={{ m: 2 }}>
						Go to Round History
					</Link> */}
				</Box>
				<Box style={{ marginBottom: "20px", padding: "12px" }}>
					{/* start_date and end_date dropdown boxes below */}
					<Grid container spacing={3}>
						<Grid item xs={12} sm={6}>
							<TextField
								id="start_date"
								label="Start Date"
								type="date"
								value={startDate}
								onChange={(e) => setStartDate(e.target.value)}
								InputLabelProps={{
									shrink: true,
								}}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<TextField
								id="end_date"
								label="End Date"
								type="date"
								value={endDate}
								onChange={(e) => setEndDate(e.target.value)}
								InputLabelProps={{
									shrink: true,
								}}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<TextField
								id="team_name"
								label="Team Name"
								type="text"
								value={teamName}
								onKeyDown={handleKeyDown}
								onChange={(e) => setTeamName(e.target.value)}
								InputLabelProps={{
									shrink: true,
								}}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<TextField
								id="email"
								label="Email"
								type="text"
								value={email}
								onKeyDown={handleKeyDown}
								onChange={(e) => setEmail(e.target.value)}
								InputLabelProps={{
									shrink: true,
								}}
							/>
						</Grid>
						<Grid item xs={12} sm={4}>
							<Button
								variant="contained"
								color="primary"
								disabled={false}
								onClick={() => handleRefreshClick()}
							>
								Refresh
							</Button>
						</Grid>
					</Grid>
				</Box>

				<>
					{!!filteredResults?.length ? (
						<Paper style={{ padding: "5px", margintop: "10px" }}>
							<GameResultsTable gameList={filteredResults} />
						</Paper>
					) : (
						<Grid container direction="column">
							<Alert severity="info">
								No Rounds have been historically saved yet.
							</Alert>
						</Grid>
					)}
				</>
			</OftadehLayout>
		</>
	);
};

export default observer(RecordList);
