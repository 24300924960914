import { makeAutoObservable } from "mobx";

class WagerScheme {
  id = String;
  name = String;
  type = String;
  order = Number;
  points = [];
  percentage = Number;
  singleUse = Boolean;
  mandatoryWagering = Boolean;
  subtractForIncorrect = Boolean;
  bonus = Boolean;
  bonusPoints = Number;

  constructor(scheme) {
    makeAutoObservable(this, {
      id: false,
    });

    this.updateScheme(scheme);
  }

  updateScheme = ({
    _id,
    id,
    name,
    type,
    order,
    points,
    percentage,
    singleUse,
    mandatoryWagering,
    subtractForIncorrect,
    bonusPoints,
  }) => {
    this.id = _id ?? id;
    this.name = name;
    this.type = type;
    this.order = order;
    this.points = points;
    this.percentage = percentage;
    this.singleUse = singleUse;
    this.mandatoryWagering = mandatoryWagering;
    this.subtractForIncorrect = subtractForIncorrect;
    this.bonusPoints = bonusPoints;
    this.bonus = bonusPoints ? true : false;
  };
}

export default WagerScheme;
