import React, { useCallback, useEffect, useState } from "react";
import OftadehLayout from "../../components/OftadehLayout/OftadehLayout";
import {
	Typography,
	makeStyles,
	Paper,
	Button,
	Table,
	Icon,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Box,
} from "@material-ui/core";

import Alert from "@material-ui/lab/Alert";

import { observer } from "mobx-react-lite";
import { useIsMounted } from "../../helpers/useIsMounted";
import HostsService from "../../services/HostsService";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(() => ({
	titlePaper: {
		marginBottom: "16px",
	},
	availableSpace: {
		fontVariant: "diagonal-fractions",
		fontSize: 36,
	},
}));

const GameResultsView = (props) => {
	const classes = useStyles();
	const isMounted = useIsMounted();
	const [gameResult, setGameResult] = useState(null);
	const gameResultId = props.match.params.gameId;
	const userId = props.match.params.userId;
	const loading = false;
	const history = useHistory();

	useEffect(() => {
		const fetchUserDetails = async () => {
			try {
				const response = await HostsService.getGameResult(gameResultId);
				const historyRounds = [];
				let playerId;
				let playerName;
				let gameScore;
				for (const score of response.scores) {
					for (const id of score.userIds) {
						if (id === userId) {
							playerId = score.playerId;
							playerName = score.playerName;
							gameScore = score.score;
							break;
						}
					}
				}
				if (!playerId) return;
				for (const round of response.rounds) {
					for (const question of round.questions) {
						for (const answer of question.answers) {
							if (!answer.players?.includes(playerId)) continue;
							// Calculate the initial score
							const point = question.markedPoint || 0;
							const score =
								(answer.point ? point * 1 : 0) +
								(answer.halfPoint ? point * 0.5 : 0) +
								answer.additionalPoints;


							// Initialize wagerScore
							let wagerScore = 0;

							// Check if wagering was enabled and currentWagerScheme exists
							if (round.currentWagerScheme && answer.marked) {
								// Process wagers
								const wager = answer.wagers.find(
									(w) => w.player.toString() === playerId
								);

								if (wager) {
									if (answer.point) {
										// Add wager bet if answer is correct
										wagerScore += wager.bet;
									} else if (round.currentWagerScheme.subtractForIncorrect) {
										// Subtract wager bet if answer is incorrect and subtractForIncorrect is true
										wagerScore -= wager.bet;
									}
								}


								// Calculate the final score including wagerScore
								const finalScore = score + wagerScore;

								// Check if the game result already exists
								let game = historyRounds.find(
									(game) =>
										game.roomId === round.roomId &&
										game.version === round.version
								);

								if (!game) {
									game = {
										roomId: round.roomId,
										roomCode: round.roomCode,
										version: round.version,
										scores: [],
										totalScore: 0,
									};
									historyRounds.push(game);
								}

								game.scores.push({
									questionId: question._id,
									score: finalScore,
									answerText: answer.text,
									point: answer.point,
									halfPoint: answer.halfPoint,
									additionalPoints: answer.additionalPoints,
									wage: wagerScore,
								});

								game.totalScore += finalScore;
							}
						}
					}
				}
				setGameResult({ ...response, playerName, gameScore, historyRounds });
			} catch (error) {
				console.error("Failed to fetch user details:", error);
			}
		};

		fetchUserDetails();
	}, [gameResultId, userId]); // Empty dependency array to only run once on mount

	if (!gameResult) {
		return <div>Loading...</div>;
	}

	return (
		<OftadehLayout loading={loading || !isMounted}>
			<Box m={2}>
				<div style={{ marginBottom: "12px" }}>
					<Button
						variant="contained"
						color="primary"
						onClick={() => {
							history.push("/game-results");
						}}
					>
						{"<"} Back
					</Button>
				</div>
				<Typography variant="h5" className="p-2" gutterBottom>
					{gameResult.playerName} details for {gameResult.gameName} in {gameResult.roomName}
				</Typography>
				<Typography variant="p" className="p-2" gutterBottom>
					Game score: {gameResult.gameScore}
				</Typography>
				<br />
				{gameResult.historyRounds.map((game, index) => (
					<TableContainer
						component={Paper}
						key={index}
						style={{ marginBottom: "20px", padding: "20px" }}
					>
						<Typography variant="h6" gutterBottom>
							{game.roomCode ? `${game.roomCode} - ` : ''}
							{game.version}
						</Typography>
						<Table aria-label="game details">
							<TableHead>
								<TableRow>
									<TableCell>Question</TableCell>
									<TableCell align="right">Response</TableCell>
									<TableCell align="right">Correct</TableCell>
									<TableCell align="right">Score</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{game.scores.map((score, scoreIndex) => (
									<TableRow key={scoreIndex}>
										<TableCell component="th" scope="row">
											<span title={score.questionId}># {scoreIndex + 1}</span>
										</TableCell>
										<TableCell align="right">{score.answerText}</TableCell>

										<TableCell align="right">
											{score.point ? (
												<Icon color="primary">check_circle_outline</Icon>
											) : (
												""
											)}
										</TableCell>
										<TableCell align="right">{score.score}</TableCell>
									</TableRow>
								))}

								<TableRow>
									<TableCell colSpan={2} align="right">
										<strong>TOTAL: {game.totalScore}</strong>
									</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					</TableContainer>
				))}
			</Box>
		</OftadehLayout>
	);
};

export default observer(GameResultsView);
