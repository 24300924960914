import React, { useEffect, useState } from "react";
import {
	Box,
	Button,
	FormControlLabel,
	FormGroup,
	Grid,
	Icon,
	IconButton,
	makeStyles,
	Switch,
	Typography,
	CircularProgress,
	Table,
	TableRow,
	TableCell,
	TableContainer,
	TableHead,
	TableBody,
} from "@material-ui/core";

import { useFormik } from "formik";
import * as Yup from "yup";

import { observer } from "mobx-react-lite";

const validationSchema = Yup.object().shape({
	requirePlayerEmail: Yup.boolean().required(),
	requirePlayerConfirmEmail: Yup.boolean().required(),
});

const useStyles = makeStyles((theme) => ({
	fieldName: {
		"& .MuiFormControlLabel-label": {
			width: 200,
			textAlign: "center",
		},
	},
	formControl: {
		width: 150,
		marginLeft: 75,
		marginRight: 38,
	},
	error: {
		color: "red",
		marginTop: ".85rem",
		textAlign: "center",
		maxWidth: 276,
		marginLeft: 11,
	},
}));

const PlayerSettings = ({
	playerSettings: { requirePlayerEmail, requirePlayerConfirmEmail },
	setOpenTipModal,
	setModalContent,
	updatePlayerSettings,
}) => {
	const [updatingError, setUpdatingError] = useState("");

	const classes = useStyles();

	const formik = useFormik({
		initialValues: {
			requirePlayerEmail,
			requirePlayerConfirmEmail,
		},
		validationSchema,
		enableReinitialize: true,
		onSubmit: async (values) => {
			if (JSON.stringify(values) === JSON.stringify(formik.initialValues))
				return;

			try {
				await updatePlayerSettings(values);
				window.location.reload();
			} catch (err) {
				setUpdatingError(
					err.response.data?.message || "Saving failed, please try again!"
				);
			}
		},
	});

	useEffect(() => {
		setUpdatingError("");
	}, [formik.values]);

	const handleEmailHelp = () => {
		setModalContent(
			"With this enabled, the players will need to login with user account to join the room."
		);
		setOpenTipModal(true);
	};

	const handleEmailVerifyHelp = () => {
		setModalContent(
			"With this enabled, the players will need to verify their email address before they can join the room. Meaning they will have to check their inbox for the verification email and click the link provided."
		);
		setOpenTipModal(true);
	};

	return (
		<>
			<TableContainer style={{ width: "100%" }}>
				<Table aria-label="customized table">
					<TableHead>
						<TableRow>
							<TableCell>Enabled</TableCell>
							<TableCell>Setting</TableCell>
							<TableCell>Info</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						<TableRow>
							<TableCell component="th" scope="row">
								<FormControlLabel
									className={classes.fieldName}
									control={
										<Switch
											name="requirePlayerEmail"
											onChange={formik.handleChange}
											checked={formik.values.requirePlayerEmail}
										/>
									}
								/>
							</TableCell>
							<TableCell>Require Email Authentication</TableCell>
							<TableCell>
								<IconButton onClick={handleEmailHelp}>
									<Icon>help_outline</Icon>
								</IconButton>
							</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</TableContainer>
			<Box display="flex" flexDirection="column" p={0.5}>
				<Box p={2} marginTop={2}>
					{updatingError && (
						<Typography className={classes.error} variant="body1">
							{updatingError}
						</Typography>
					)}
				</Box>

				<Box display="flex">
					<Button
						variant="contained"
						color="primary"
						className={classes.button}
						onClick={formik.handleSubmit}
						disabled={
							formik.isSubmitting ||
							JSON.stringify(formik.values) ===
								JSON.stringify(formik.initialValues)
						}
					>
						{formik.isSubmitting ? (
							<>
								Please wait <CircularProgress color="inherit" size={16} />
							</>
						) : (
							<> Save</>
						)}
					</Button>
				</Box>
			</Box>
		</>
	);
};

export default observer(PlayerSettings);

{
	/* {formik.values.requirePlayerEmail ? (
	<TableRow>
		<TableCell component="th" scope="row">
			<FormControlLabel
				className={classes.fieldName}
				control={
					<Switch
						name="requirePlayerConfirmEmail"
						onChange={formik.handleChange}
						checked={formik.values.requirePlayerConfirmEmail}
					/>
				}
			/>
		</TableCell>
		<TableCell>Require Verification of Email</TableCell>
		<TableCell>
			<IconButton onClick={handleEmailVerifyHelp}>
				<Icon>help_outline</Icon>
			</IconButton>
		</TableCell>
	</TableRow>
) : null} */
}
