import { makeAutoObservable, runInAction } from "mobx";
import { getRoot } from "mobx-easy";
import AuthService from "../../services/AuthService";
import config from "../../utils/Configuration";

export default class AuthStore {
  email = "";
  verified = false;
  loggedIn = false;
  rootStore = undefined;

  constructor() {
    makeAutoObservable(this);
    this.verified = config.isUserVerified;
    this.loggedIn = config.authToken;
    this.rootStore = getRoot();
  }

  clearAuthStore = () => {
    this.email = "";
    this.loggedIn = false;
    this.verified = false;
  };

  registerUser = async (email, password) => {
    const response = await AuthService.registerUser(email, password);

    runInAction(() => {
      this.verified = response.verified;
      this.loggedIn = true;
      this.email = response.email;
    });
  };

  login = async (email, password) => {
    const response = await AuthService.login(email, password);

    runInAction(() => {
      this.verified = response.verified;
      this.loggedIn = true;
      this.email = response.email;
    });
  };

  logout = () => {
    // console.log("%c LOGOUT", "font-size:30px;color:red;");
    this.rootStore.subscriptionStore.clearSubscriptionStore();
    this.rootStore.hostsStore.clearHostsStore();
    this.rootStore.settingsStore.clearSettingsStore();
    this.clearAuthStore();
    AuthService.logout();
  };

  getMe = async (auth) => {
    try {
      const response = await AuthService.getMe(auth);

      runInAction(() => {
        this.email = response.email;
        this.verified = response.verified;
      });

      return response;
    } catch (err) {
      if (err?.response?.status === 430 || err?.response?.status === 404) {
        this.logout();
      } else throw err;
    }
  };

  resendVerificationEmail = async (email) => {
    await AuthService.resendVerificationEmail(email);
  };

  verifyEmail = async (verificationToken) => {
    const response = await AuthService.verifyEmail(verificationToken);

    runInAction(() => {
      this.verified = response.verified;
      this.token = response.token;
      this.loggedIn = true;
    });
  };

  forgotPassword = async (email) => {
    await AuthService.forgotPassword(email);
  };

  validateResetToken = async (resetToken) => {
    const response = await AuthService.validateResetToken(resetToken);

    return response;
  };

  resetPassword = async (password, resetToken) => {
    const response = await AuthService.resetPassword(password, resetToken);

    runInAction(() => {
      this.verified = response.verified;
      this.loggedIn = true;
      this.email = response.email;
    });
  };
}
