import React from "react";
import {
	makeStyles,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from "@material-ui/core";

const useStyles = makeStyles({
	table: {
		"& tbody": {
			textTransform: "uppercase",

			"& th,td": {
				fontWeight: "bold",
			},
		},
	},
});

const PreEnteredRoundQuestions = ({ questions }) => {
	const classes = useStyles();
	return (
		<TableContainer>
			<Table
				className={classes.table}
				style={{ tableLayout: "fixed" }}
				aria-label="simple table"
			>
				<TableHead>
					<TableRow>
						<TableCell width={20} align="left">
							№
						</TableCell>
						<TableCell align="center">Questions</TableCell>
						<TableCell align="center">Answers</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{questions.map((row, index) => (
						<TableRow key={index}>
							<TableCell align="left">{row.orderNum}</TableCell>
							<TableCell align="center">{row.questionText}</TableCell>
							<TableCell align="center">{row.answerText}</TableCell>
							{row.image && row.image !== "" && (
								<TableCell align="center">
									<img
										src={row.image}
										style={{ width: 100, height: 100, objectFit: "cover" }}
										alt={"Question image"}
									/>
								</TableCell>
							)}
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
};

export default PreEnteredRoundQuestions;
