import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import ToolTip from "../../components/ToolTip";

const useStyles = makeStyles({
	table: {
		"& tbody": {
			//textTransform: "uppercase",

			"& th,td": {
				// fontWeight: "bold",
			},
		},
	},
});

const UserTable = ({ usersList }) => {
	const classes = useStyles();
	const history = useHistory();

	const isLast = (index) => index === usersList.length - 1;
	const tips = {
		team: "The team name is the name of the team that the user used during their most recent game.",
	};
	return (
		<TableContainer>
			<Table
				className={classes.table}
				style={{ tableLayout: "fixed" }}
				aria-label="simple table"
			>
				<TableHead>
					<TableRow>
						<TableCell style={{ width: 260 }}>User Email</TableCell>
						<TableCell>
							Name <ToolTip text={tips.team} />
						</TableCell>
						<TableCell>Rooms Played</TableCell>
						<TableCell>Total Score</TableCell>
						<TableCell>Last Sign In</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{usersList.map((user, userIndex) => (
						<TableRow key={userIndex}>
							<TableCell
								style={{
									borderBottom: isLast(userIndex) && 0,
								}}
							>
								<Button onClick={() => history.push(`/users/${user._id}`)}>
									{user.userEmail}
								</Button>
							</TableCell>
							<TableCell
								scope="row"
								className="text-uppercase"
								style={{
									borderBottom: isLast(userIndex) && 0,
								}}
							>
								{user.playerNames[0]}
							</TableCell>
							<TableCell
								scope="row"
								style={{
									borderBottom: isLast(userIndex) && 0,
								}}
							>
								{user.roomCodes.join(", ")}
							</TableCell>
							<TableCell
								scope="row"
								style={{
									borderBottom: isLast(userIndex) && 0,
								}}
							>
								{user.totalScore}
							</TableCell>
							<TableCell
								style={{
									borderBottom: isLast(userIndex) && 0,
								}}
							>
								{new Date(user.lastLogin).toLocaleString()}
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
};

export default UserTable;
