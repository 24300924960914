import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";

const useStyles = makeStyles({
  title: {
    fontSize: 20,
    textAlign: "center",
  },
  content: {
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  closeBtn: {
    display: "flex",
    justifyContent: "center",
  },
  errorIcon: {
    fontSize: 55,
    color: "#3f51b5",
    marginBottom: 20,
  },
});

const SubscriptionCreatingErrorModal = ({ open, handleClose }) => {
  const classes = useStyles();

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle className={classes.title} id="alert-dialog-title">
          Something went wrong!
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            className={classes.content}
            id="alert-dialog-description"
          >
            <ErrorOutlineIcon className={classes.errorIcon} />
            We're sorry, we're having some trouble completing your request right
            now. Please try again shortly.
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.closeBtn}>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default SubscriptionCreatingErrorModal;
