import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  CircularProgress,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useParams } from "react-router";

import { useStore } from "../../../stores/helpers/useStore";

const initialValues = {
  email: "",
};

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .trim()
    .required("Email is required")
    .email("Email must be valid"),
});

const useStyles = makeStyles((theme) => ({
  root: {
    background: "#0d131d",
    color: "#fff",
    width: "100vw",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
  },
  button: {
    maxWidth: "150px",
    marginTop: ".85rem",
  },
  logout: {
    position: "absolute",
    top: "20px",
    right: "20px",
  },
  progress: {
    marginTop: "1rem",
  },
  verificationFailed: {
    width: "525px",
    borderRadius: 5,
    background: "#fff",
    padding: ".85rem",
    color: "black",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  input: {
    maxWidth: "300px",
    margin: "1rem 0",
  },
  circularProgress: {
    marginLeft: "10px",
  },
}));

const VerifyEmailPage = ({ ...props }) => {
  const [sending, setSending] = useState(true);
  const { verificationToken } = useParams();
  const { history } = props;
  const classes = useStyles();
  const {
    authStore: { resendVerificationEmail, logout, verifyEmail },
  } = useStore();

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, { setFieldError, setSubmitting }) => {
      setSubmitting(true);
      try {
        await resendVerificationEmail(values.email);
        history.replace("/pages/aut/verification");
      } catch (err) {
        setSubmitting(false);
        if (err?.response?.status === 409) {
          setFieldError(
            err?.response?.data?.param,
            err?.response?.data?.message
          );
        } else if (err?.response?.status === 406) {
          setFieldError("email", "No user with such an email.");
        } else if (err?.response?.status === 500) {
          history.push("/pages/errors/error-500");
        }
      }
    },
  });

  const verify = useCallback(
    async (verificationToken) => {
      try {
        await verifyEmail(verificationToken);
      } catch {
        setSending(false);
      }
    },
    [verifyEmail]
  );

  const handleLogout = () => {
    logout();
    history.push("/login");
  };

  useEffect(() => {
    verify(verificationToken);
  }, [verificationToken, verify]);
  return (
    <div className={classes.root}>
      <Button
        variant="contained"
        color="primary"
        className={classes.logout}
        onClick={handleLogout}
      >
        Logout
      </Button>
      {sending ? (
        <>
          <Typography variant="h3" gutterBottom>
            Verifying your email!
          </Typography>
          <Typography variant="body1">Please wait.</Typography>
          <CircularProgress className={classes.progress} />
        </>
      ) : (
        <div className={classes.verificationFailed}>
          <Typography variant="h4">Verification failed.</Typography>
          <Typography variant="body1">
            You can send a new verification message to your email!
          </Typography>
          <TextField
            size="small"
            label="Email"
            name="email"
            variant="outlined"
            margin="dense"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={!!formik.touched.email && !!formik.errors.email}
            helperText={formik.touched.email && formik.errors.email}
            fullWidth
            className={classes.input}
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            className={classes.button}
            onClick={formik.handleSubmit}
            disabled={formik.isSubmitting}
          >
            {formik.isSubmitting ? (
              <>
                Please wait{" "}
                <CircularProgress
                  className={classes.circularProgress}
                  color="inherit"
                  size={16}
                />
              </>
            ) : (
              <> Send</>
            )}
          </Button>
        </div>
      )}
    </div>
  );
};

export default VerifyEmailPage;
