import React from "react";
import {
	makeStyles,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
} from "@material-ui/core";

const useStyles = makeStyles({
	table: {
		maxWidth: 650,
		textAlign: "left",
		margin: "30px auto 10px",
		"& h5": {
			fontSize: 14,
		},
	},
});

const SubscriptionDetails = ({
	subscriptionQuantity,
	subscriptionStatus,
	nextCycleQuantity,
	subscriptionEnd,
	getPrice,
}) => {
	const classes = useStyles();
	return (
		<div>
			<h3>Your subscription details:</h3>
			{subscriptionStatus && (
				<TableContainer className={classes.table} component={Paper}>
					<Table aria-label="simple table">
						<TableBody>
							<TableRow>
								<TableCell component="th" scope="row">
									<h4>Subscription Status</h4>
								</TableCell>
								<TableCell>
									<b> {subscriptionStatus}</b>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell component="th" scope="row">
									<h4>Hosts quantity</h4>
								</TableCell>
								<TableCell>
									<b>{subscriptionQuantity}</b>
								</TableCell>
							</TableRow>
							{nextCycleQuantity && (
								<TableRow>
									<TableCell component="th" scope="row">
										<h4>Next cycle quantity</h4>
									</TableCell>
									<TableCell>
										<b>{nextCycleQuantity}</b>
									</TableCell>
								</TableRow>
							)}
							<TableRow>
								<TableCell component="th" scope="row">
									<h4>Monthly price</h4>
								</TableCell>
								<TableCell>
									<b> {getPrice(subscriptionQuantity)}$</b>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell component="th" scope="row">
									<h4>Valid until</h4>
								</TableCell>
								<TableCell>
									<b> {subscriptionEnd?.slice(0, 10) || "-"}</b>
								</TableCell>
							</TableRow>
						</TableBody>
					</Table>
				</TableContainer>
			)}
		</div>
	);
};

export default SubscriptionDetails;
