import React, { useEffect, useState } from "react";
import OftadehLayout from "../../components/OftadehLayout/OftadehLayout";
import { Paper, Grid, Box } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import { Alert } from "@material-ui/lab";
// import Checkbox from "@material-ui/core/Checkbox";
// import Alert from "@material-ui/lab/Alert";

import { useStore } from "../../stores/helpers/useStore";
import { useIsMounted } from "../../helpers/useIsMounted";

import TipModal from "../../components/Modals/TipModal/TipModal";
import GameSettings from "../../components/GameSettings/GameSettings";
import ProGameOptions from "../../components/ProGameOptions/ProGameOptions";

const SettingsPage = () => {
	const [modalContent, setModalContent] = useState();
	const [openTipModal, setOpenTipModal] = useState(false);

	const {
		settingsStore: {
			gameSettings,
			proGameOptions,
			getSettings,
			updateProGameOptions,
			updateGameSettings,
		},
		uiStore: { loading },
	} = useStore();
	const isMounted = useIsMounted();

	const handleClose = () => {
		setOpenTipModal(false);
	};

	useEffect(() => {
		getSettings();
	}, [getSettings]);

	return (
		<>
			<OftadehLayout loading={loading || !isMounted}>
				<h1>Global Host Settings</h1>

				{/* <Grid item xs={12}>
          <Alert icon={false} severity="success">
            <FormControlLabel
              control={<Checkbox name="checkedA" />}
              label="Lock these settings for all hosts"
            />
          </Alert>
        </Grid> */}
				<Box>
					<Alert severity="info" style={{ marginBottom: 12 }}>
						Updating these settings will apply to all of your rooms!
					</Alert>
				</Box>

				<Paper style={{ padding: "0 50px" }}>
					<Grid container spacing={2}>
						<Grid item md={6} xs={12}>
							<GameSettings
								gameSettings={gameSettings}
								setModalContent={setModalContent}
								setOpenTipModal={setOpenTipModal}
								updateGameSettings={updateGameSettings}
							/>
						</Grid>
						<Grid item md={6} xs={12}>
							<ProGameOptions
								proGameOptions={proGameOptions}
								setModalContent={setModalContent}
								setOpenTipModal={setOpenTipModal}
								updateProGameOptions={updateProGameOptions}
							/>
						</Grid>
					</Grid>
				</Paper>
			</OftadehLayout>

			<TipModal open={openTipModal} handleClose={handleClose}>
				{modalContent}
			</TipModal>
		</>
	);
};

export default observer(SettingsPage);
