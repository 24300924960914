import PlayerSettingPage from "./PlayerSettingPage";

export const PlayerSettingConfig = {
  routes: [
    {
      path: "/pages/player-settings",
      exact: true,
      component: PlayerSettingPage,
    },
  ],
};
