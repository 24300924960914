import { get } from "mobx";
import ApiService from "../abstracts/ApiService";

class UsersService extends ApiService {
	async getUserDetails(userId) {
		const response = await this.protectedRequest({
			url: "/admin/user/" + userId,
		});

		return response;
	}
}

const instance = new UsersService();

export default instance;
