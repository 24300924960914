import QuestionsPage from "./QuestionsPage";

export const QuestionsPageConfig = {
  routes: [
    {
      path: "/questions",
      exact: true,
      component: QuestionsPage,
    },
  ],
};
