import React from "react";
import { Redirect, Route } from "react-router";

const VerificationRoute = ({ key, auth, verified, ...route }) => {
  if (auth && !verified) {
    // console.log("%c GO TO VERIFICATION ROUTE", "font-size:30px;color:green;");
    return <Route key={key} {...route} />;
  } else if (auth && verified) {
    // console.log("%c REDIRECT TO HOME", "font-size:30px;color:red;");
    return <Redirect to="/" />;
  } else if (!auth) {
    // console.log("%c DEFAULT REDIRECT", "font-size:30px;color:red;");
    return <Redirect to="/pages/auth/login" />;
  }
};

export default VerificationRoute;
