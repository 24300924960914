import React from "react";
import OftadehLayout from "../../components/OftadehLayout/OftadehLayout";
import { Paper, Button, Grid, Tabs, Tab, Box } from "@material-ui/core";

import HostsService from "../../services/HostsService";
import GameHistoryViewTable from "./GameHistoryViewTable";

const TabPanel = ({ children, value, index }) => {
	return <div>{value === index && <Box p={3}>{children}</Box>}</div>;
};

class GameHistoryView extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			// You can initialize your state here, for example:
			gameData: null,
			loading: true,
			error: null,
			headerData: [],
			tabIndex: 0,
		};
	}

	componentDidMount() {
		// This is where you call your API to get data
		this.fetchGameData();
	}

	fetchGameData = async () => {
		const response = await HostsService.getRoundHistory(
			this.props.match.params.id
		);
		//set gameData
		const headerData = this.headerData(response.data);
		this.setState({ gameData: response.data, headerData, loading: false });
	};

	changeTab = (event, newIndex) => {
		this.setState({ tabIndex: newIndex });
	};

	uniquePlayers = () => {
		const playersMap = new Map();
		const data = this.state.gameData;
		data.questions.forEach((question) => {
			question.answers.forEach((answer) => {
				answer.players.forEach((player) => {
					if (!playersMap.has(player._id)) {
						playersMap.set(player._id, player);
					}
				});
			});
		});

		return Array.from(playersMap.values()).sort((a, b) => b.score - a.score);
	};

	headerData = (gameData) => {
		return [
			{
				label: "Room",
				value: gameData.roomId.roomCode,
			},
			{
				label: "Game Started At",
				value: gameData.createdAt,
			},
			{
				label: "Wagering",
				value: gameData.wagerMode ? "Enabled" : "Disabled",
			},
			{
				label: "Game Mode",
				value: gameData.roundMode,
			},
		];
	};

	render() {
		const { gameData, loading, error, headerData, tabIndex } = this.state;

		return (
			<OftadehLayout>
				<h1>View Game Details</h1>
				<div style={{ marginBottom: "12px" }}>
					<Button
						variant="contained"
						color="primary"
						onClick={() => {
							this.props.history.push("/game-history");
						}}
					>
						{"<"} Back
					</Button>
				</div>
				{gameData && (
					<div>
						<Paper style={{ padding: "15px", marginBottom: "2x" }}>
							{loading && <p>Loading...</p>}
							{error && <p>Error: {error}</p>}

							<Grid container spacing={2}>
								{headerData.map((item, index) => (
									<Grid item xs={4} key={index}>
										<div>
											<p className="overline">{item.label}</p>
											<b className="text-uppercase">{item.value}</b>
										</div>
									</Grid>
								))}
							</Grid>
						</Paper>

						<Paper
							style={{ padding: "2px", marginBottom: "4px", marginTop: "16px" }}
						>
							<Tabs value={tabIndex} onChange={this.changeTab}>
								<Tab label="All Players" />
								<Tab label="Responses" />
							</Tabs>

							<TabPanel value={tabIndex} index={0}>
								<div>
									<table className="tr-table">
										<tr className="">
											<th>Team Name</th>
											<th>User Email</th>
											<th>Score</th>
										</tr>
										{this.uniquePlayers().map((player) => (
											<tr key={player._id} style={{}}>
												<td>
													<b className="text-uppercase">{player.name}</b>
												</td>
												<td>
													<b className="text-uppercase">
														{player.user ? player.user[0].email : "-"}
													</b>
												</td>
												<td>
													<b className="text-uppercase">{player.score}</b>
												</td>
											</tr>
										))}
									</table>
								</div>
							</TabPanel>

							<TabPanel value={tabIndex} index={1}>
								<GameHistoryViewTable gameData={gameData} />
							</TabPanel>
						</Paper>
					</div>
				)}
			</OftadehLayout>
		);
	}
}

export default GameHistoryView;
