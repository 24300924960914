import SettingsPage from "./SettingsPage";

export const SettingsPageConfig = {
  routes: [
    {
      path: "/pages/settings",
      exact: true,
      component: SettingsPage,
    },
  ],
};
