import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  confirmBtn: {
    display: "flex",
    justifyContent: "center",
  },
  title: {
    textAlign: "center",
  },
  content: {
    textAlign: "center",
    padding: "10px 50px",
  },
});

const CongratulationModal = ({ open, handleClose, children }) => {
  const classes = useStyles();

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle className={classes.title} id="alert-dialog-title">
          Congratulations!
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            className={classes.content}
            id="alert-dialog-description"
          >
            {children}
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.confirmBtn}>
          <Button onClick={handleClose} variant="contained" color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default CongratulationModal;
