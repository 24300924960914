import CreateHost from "./createHost/CreateHost";
import EditHost from "./editHost/EditHost";
import HostsList from "./hostsList/HostsList";
import InviteHost from "./inviteHost/InviteHost";

export const HostsPageConfig = {
  routes: [
    {
      path: "/hosts",
      exact: true,
      component: HostsList,
    },
    {
      path: "/edit-host/:roomCode",
      exact: true,
      component: EditHost,
    },
    {
      path: "/create-host",
      exact: true,
      component: CreateHost,
    },
    {
      path: "/invite-host",
      exact: true,
      component: InviteHost,
    },
  ],
};
