import React, { useEffect } from "react";
import { Switch } from "react-router-dom";
import { observer } from "mobx-react-lite";

import { useStore } from "../../stores/helpers/useStore";

import PublicRoute from "./CustomRoutes/PublicRoute";
import PrivateRoute from "./CustomRoutes/PrivateRoute";

import routes from "../../oftadeh-configs/routesConfig";
import VerificationRoute from "./CustomRoutes/VerificationRoute";
import ProcessingRoute from "./CustomRoutes/ProcessingRoute";

const OftadehRoutes = () => {
  const {
    authStore: { verified, getMe, loggedIn },
  } = useStore();

  useEffect(() => {
    if (loggedIn) {
      getMe();
    }
  }, [getMe, loggedIn]);

  return (
    <Switch>
      {routes.map((route, index) => {
        switch (true) {
          case route.public:
            return (
              <PublicRoute
                key={index}
                auth={loggedIn}
                verified={verified}
                {...route}
              />
            );
          case route.verification:
            return (
              <VerificationRoute
                key={index}
                auth={loggedIn}
                verified={verified}
                {...route}
              />
            );
          case route.processing:
            return (
              <ProcessingRoute
                key={index}
                auth={loggedIn}
                verified={verified}
                {...route}
              />
            );
          default:
            return (
              <PrivateRoute
                key={index}
                auth={loggedIn}
                verified={verified}
                {...route}
              />
            );
        }
      })}
    </Switch>
  );
};

export default observer(OftadehRoutes);
