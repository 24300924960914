import React, { useState } from "react";
import {
  makeStyles,
  Typography,
  Button,
  TextField,
  CircularProgress,
} from "@material-ui/core";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useStore } from "../../../stores/helpers/useStore";

const initialValues = {
  email: "",
};

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .trim()
    .required("Email is required")
    .email("Email must be valid"),
});

const useStyles = makeStyles((theme) => ({
  root: {
    background: "#0d131d",
    width: "100vw",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
  },
  mBottom: {
    marginBottom: ".5rem",
  },
  mVertical: {
    marginTop: "1.5rem",
    marginBottom: "1.5rem",
  },
  button: {
    marginTop: ".85rem",
  },
  loginCard: {
    width: "275px",
    borderRadius: 5,
    background: "#fff",
    padding: ".85rem",
  },
  circularProgress: {
    marginLeft: "10px",
  },
}));

const ForgotPasswordPage = ({ ...props }) => {
  const [emailSent, setEmailSent] = useState(false);
  const classes = useStyles();
  const { history } = props;
  const {
    authStore: { forgotPassword },
  } = useStore();

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, { setFieldError, setSubmitting }) => {
      setSubmitting(true);

      try {
        await forgotPassword(values.email);
        setEmailSent(true);
      } catch (err) {
        setSubmitting(false);
        if (err?.response?.status === 409) {
          setFieldError(
            err?.response?.data?.param,
            err?.response?.data?.message
          );
        } else if (err?.response?.status === 406) {
          setFieldError("email", "No user with such an email.");
        } else {
          setFieldError(
            "email",
            "Something went wrong.Please, try again later."
          );
        }
      }
    },
  });

  return (
    <div className={classes.root}>
      <div className={classes.loginCard}>
        {emailSent ? (
          <>
            <Typography variant="h5" component="h1">
              Email is sent!
            </Typography>
            <Typography
              className={(classes.mBottom, classes.mVertical)}
              variant="body1"
            >
              Please go to
              <br />
              <b>{formik.values.email}</b>
              <br />
              and follow instruction.
            </Typography>
          </>
        ) : (
          <>
            <Typography variant="h5" component="h1">
              Forgot Password
            </Typography>
            <Typography className={classes.mBottom} variant="body1">
              Enter your email
            </Typography>
            <form noValidate autoComplete="off" onSubmit={formik.handleSubmit}>
              <TextField
                size="small"
                label="Email"
                name="email"
                variant="outlined"
                margin="dense"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={!!formik.touched.email && !!formik.errors.email}
                helperText={formik.touched.email && formik.errors.email}
                fullWidth
              />
              <div className={classes.mBottom}>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  className={classes.button}
                  onClick={formik.handleSubmit}
                  disabled={formik.isSubmitting}
                >
                  {formik.isSubmitting ? (
                    <>
                      Please wait
                      <CircularProgress
                        className={classes.circularProgress}
                        color="inherit"
                        size={16}
                      />
                    </>
                  ) : (
                    <> Send</>
                  )}
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  fullWidth
                  className={classes.button}
                  onClick={() => history.push("/pages/auth/login")}
                >
                  Back to Login
                </Button>
              </div>
            </form>
          </>
        )}
      </div>
    </div>
  );
};

export default ForgotPasswordPage;
