import ApiService from "../abstracts/ApiService";

class AuthService extends ApiService {
  async registerUser(email, password) {
    const response = await this.request({
      url: "/admin/sign-up",
      method: "post",
      data: { email, password },
    });

    this.updateAuthFields(response.token, response.verified);

    return response;
  }

  async login(email, password) {
    const response = await this.request({
      url: "/admin/sign-in",
      method: "put",
      data: { email, password },
    });

    this.updateAuthFields(response.token, response.verified);

    return response;
  }

  logout() {
    localStorage.removeItem("auth");
    localStorage.removeItem("verified");
  }

  async getMe() {
    const response = await this.protectedRequest({
      url: "/admin/me",
      method: "get",
    });

    this.updateVerifiedField(response.verified);

    // console.log("response of getMe");
    // console.log(response);

    return response;
  }

  async resendVerificationEmail(email) {
    const response = await this.protectedRequest({
      url: "/admin/resend-email",
      method: "put",
      data: {
        email,
      },
    });

    return response;
  }

  async verifyEmail(verificationToken) {
    const response = await this.request({
      url: "/admin/verify",
      method: "put",
      data: {
        verificationToken,
      },
    });

    this.updateAuthFields(response.token, response.verified);

    return response;
  }

  async forgotPassword(email) {
    const response = await this.request({
      url: "/admin/forgot-password",
      method: "put",
      data: {
        email,
      },
    });

    return response;
  }

  async validateResetToken(resetToken) {
    const response = await this.request({
      url: `/admin/validate-token/${resetToken}`,
      method: "get",
    });

    return response;
  }

  async resetPassword(password, resetToken) {
    const response = await this.request({
      url: "/admin/reset-password",
      method: "put",
      data: {
        password,
        resetToken,
      },
    });

    this.updateAuthFields(response.token, response.verified);

    return response;
  }

  updateAuthFields = (token, verified) => {
    this.tokenStorage.set(token);
    this.updateVerifiedField(verified);
  };

  updateAuthToken = (token) => {
    localStorage.setItem("auth", JSON.stringify(token));
  };

  updateVerifiedField = (verified) => {
    localStorage.setItem("verified", JSON.stringify(verified));
  };
}

const instance = new AuthService();

export default instance;
