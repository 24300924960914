import GameComponent from "./GameHistoryList";
import GameResultComponent from "./GameResultsList";
import GameHistoryView from "./GameHistoryView";
import GameResultsView from "./GameResultsView";

import RoundComponent from "./RoundHistoryList";
import RoundHistoryView from "./RoundHistoryView";

export const GameHistoryConfig = {
	routes: [
		{
			path: "/game-history",
			exact: true,
			component: GameComponent,
		},
		{
			path: "/game-results",
			exact: true,
			component: GameResultComponent,
		},
		{
			path: "/game-results/:gameId/:userId",
			exact: true,
			component: GameResultsView,
		},
		{
			path: "/game-history/:id",
			exact: true,
			component: GameHistoryView,
		},
		{
			path: "/round-history",
			exact: true,
			component: RoundComponent,
		},
		{
			path: "/round-history/:id",
			exact: true,
			component: RoundHistoryView,
		},
	],
};
