import React, { useEffect, useState } from "react";

import { makeStyles, Button } from "@material-ui/core";

import CreateSubscriptionModal from "../../../components/Modals/CreateSubscriptionModal/CreateSubscriptionModal";
import CongratulationModal from "../../../components/Modals/CongratulationModal/CongratulationModal";
import SubscriptionCreatingErrorModal from "../../../components/Modals/SubscriptionCreatingErrorModal/SubscriptionCreatingErrorModal";
import UpdateHostsQuantityTable from "../../../components/UpdateHostsQuantityTable/UpdateHostsQuantityTable";

const useStyles = makeStyles({
  button: {
    maxWidth: 220,
    display: "flex",
    margin: "30px auto",
  },
  backButtonOuter: {
    display: "flex",
    justifyContent: "center",
  },
  backButton: {
    marginTop: 100,
  },
});

const CreateSubscription = ({
  subscriptionStatus,
  subscriptionEnd,
  subscriptionQuantity,
  createAdminSubscription,
  getPrice,
  back,
}) => {
  const [hostsQuantity, setHostsQuantity] = useState(3);
  const [quantityError, setQuantityError] = useState("");
  const [openCongratulationModal, setOpenCongratulationModal] = useState(false);
  const [subscriptionCreated, setSubscriptionCreated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [approved, setApproved] = useState(false);
  const [openCreateSubscriptionModal, setOpenCreateSubscriptionModal] =
    useState(false);
  const [openSubscriptionErrorModal, setOpenSubscriptionErrorModal] =
    useState(false);

  const classes = useStyles();

  const handleClose = () => {
    setOpenCreateSubscriptionModal(false);
    setOpenSubscriptionErrorModal(false);
  };

  const closeCongratulationModal = () => {
    setOpenCongratulationModal(false);
    back();
  };

  const decreaseHostsQuantity = () => {
    if (hostsQuantity <= 3) {
      setQuantityError("Minimum hosts quantity is: 3");
      return;
    }
    setQuantityError("");
    setHostsQuantity(hostsQuantity - 1);
  };

  const increaseHostsQuantity = () => {
    setHostsQuantity(hostsQuantity + 1);
    setQuantityError("");
  };

  // create subscription with selected hosts quantity
  const paypalSubscribe = (data, actions) => {
    return actions.subscription.create({
      plan_id: process.env.REACT_APP_PAYPAL_PLAN_ID,
      quantity: hostsQuantity,
    });
  };

  const paypalOnError = (err) => {
    setOpenSubscriptionErrorModal(true);
    setOpenCreateSubscriptionModal(false);
  };

  const paypalOnApprove = (data, detail) => {
    // call the backend api to store transaction details
    setApproved(true);
    createSubscription(data.subscriptionID);
  };

  const createSubscription = async (subscriptionID) => {
    try {
      setLoading(true);
      await createAdminSubscription(subscriptionID);
      setLoading(false);
      setSubscriptionCreated(true);
    } catch (err) {
      paypalOnError();
    }
  };

  useEffect(() => {
    if (subscriptionCreated && subscriptionStatus === "ACTIVE") {
      setOpenCreateSubscriptionModal(false);
      setOpenCongratulationModal(true);
    }
  }, [subscriptionStatus, subscriptionCreated]);

  return (
    <>
      <h1>Create Subscription</h1>

      <UpdateHostsQuantityTable
        error={quantityError}
        hostsQuantity={hostsQuantity}
        increase={increaseHostsQuantity}
        decrease={decreaseHostsQuantity}
      />

      <Button
        variant="contained"
        color="primary"
        className={classes.button}
        onClick={() => setOpenCreateSubscriptionModal(true)}
      >
        Start subscription
      </Button>
      <div className={classes.backButtonOuter}>
        <Button
          className={classes.backButton}
          variant="contained"
          color="default"
          onClick={back}
        >
          Go back
        </Button>
      </div>

      <CreateSubscriptionModal
        open={openCreateSubscriptionModal}
        hostsQuantity={hostsQuantity}
        loading={loading}
        approved={approved}
        price={getPrice(hostsQuantity)}
        handleClose={handleClose}
        paypalSubscribe={paypalSubscribe}
        onApprove={paypalOnApprove}
        catchError={paypalOnError}
        onError={paypalOnError}
        // onCancel={paypalOnCancel}
      />
      <CongratulationModal
        open={openCongratulationModal}
        handleClose={closeCongratulationModal}
        subscriptionStatus={subscriptionStatus}
        subscriptionEnd={subscriptionEnd}
        subscriptionQuantity={subscriptionQuantity}
      >
        Your subscription is now <b>{subscriptionStatus}</b> till{" "}
        <b>{subscriptionEnd && subscriptionEnd.slice(0, 10)}</b>, and you can
        add <b>{subscriptionQuantity}</b> hosts!
      </CongratulationModal>
      <SubscriptionCreatingErrorModal
        open={openSubscriptionErrorModal}
        handleClose={handleClose}
      />
    </>
  );
};

export default CreateSubscription;
