import React, { useEffect, useState } from "react";
import { makeStyles, TextField } from "@material-ui/core";

const useStyles = makeStyles({
	input: {
		display: "flex",
		margin: "0 auto",
		maxWidth: 150,
		marginInline: 30,
	},
	calculatedPrice: {
		justifyContent: "center",
		display: "flex",
	},
});

const PriceCalculator = ({
	value,
	error,
	helperText,
	expectedPrice,
	onChange,
	onBlur,
}) => {
	const classes = useStyles();
	const [price, setPrice] = useState(Math.round(value));

	useEffect(() => {
		setPrice(Math.round(value));
	}, [value]);
	return (
		<>
			<div className={classes.calculatedPrice}>
				<h3>Calculate your price:</h3>
				<TextField
					type="number"
					label="Number of hosts"
					variant="standard"
					name="calculateHosts"
					className={classes.input}
					value={price}
					error={error}
					helperText={helperText}
					onChange={onChange}
					onBlur={onBlur}
				/>
			</div>

			<div className={classes.calculatedPrice}>
				<h2>Expected price: &nbsp; </h2>
				<h2>{expectedPrice}$ </h2>
			</div>
		</>
	);
};

export default PriceCalculator;
