import React, { useEffect, useState } from "react";
import {
  TextField,
  Grid,
  Button,
  CircularProgress,
  Typography,
  makeStyles,
  Box,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

import { useFormik } from "formik";
import * as Yup from "yup";

import { useStore } from "../../stores/helpers/useStore";
import ContactService from "../../services/ContactService";

import OftadehLayout from "../../components/OftadehLayout/OftadehLayout";

const initialValues = {
  subject: "",
  message: "",
};

const validationSchema = Yup.object().shape({
  subject: Yup.string().trim().min(1, "Please add subject."),
  message: Yup.string().trim().min(5, "Minimum 5 symbols."),
});

const useStyles = makeStyles((theme) => ({
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "80vh",
    justifyContent: "space-evenly",
    "& h2": {
      fontSize: 30,
    },
  },
  error: {
    color: "red",
    marginTop: ".85rem",
    marginBottom: ".85rem",
    textAlign: "center",
  },
  sendBtn: {
    width: 200,
  },
  circle: {
    marginLeft: 10,
  },
}));

const ContactPage = (props) => {
  const [sendingError, setSendingError] = useState("");
  const [emailSended, setEmailSended] = useState(false);

  const {
    authStore: { email },
  } = useStore();
  const classes = useStyles();

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, { setFieldError }) => {
      setSendingError("");
      try {
        await ContactService.contactUs(email, values.subject, values.message);
        setEmailSended(true);
      } catch (err) {
        switch (err.response?.status) {
          case 409:
            setFieldError(err.response.data.param, err.response.data.message);
            break;
          case 500:
            setSendingError(err.response.data.message);
            break;
          default:
            setSendingError("Something went wrong, please try again later!");
        }
      }
    },
  });

  useEffect(() => {
    setSendingError("");
  }, [formik.values]);

  return (
    <OftadehLayout>
      <h2>Contact Us</h2>

      {emailSended ? (
        <div className={classes.content}>
          <h2>Email was sent! Thanks for contacting us!</h2>
        </div>
      ) : (
        <>
          <Grid container direction={"column"} spacing={5}>
            <Grid item>
              <TextField
                id="outlined-basic"
                label="Subject"
                variant="outlined"
                name="subject"
                value={formik.values.subject}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={!!formik.touched.subject && !!formik.errors.subject}
                helperText={formik.touched.subject && formik.errors.subject}
                fullWidth
              />
            </Grid>

            <Grid item>
              <TextField
                id="outlined-basic"
                label="Comments"
                multiline
                variant="outlined"
                fullWidth
                name="message"
                value={formik.values.message}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={!!formik.touched.message && !!formik.errors.message}
                helperText={formik.touched.message && formik.errors.message}
                rows={4}
              />
            </Grid>

            {sendingError && (
              <Typography className={classes.error} variant="body1">
                {sendingError}
              </Typography>
            )}

            <Box display="flex" justifyContent="center">
              <Button
                variant="contained"
                color="primary"
                className={classes.sendBtn}
                onClick={formik.handleSubmit}
                disabled={formik.isSubmitting}
              >
                {formik.isSubmitting ? (
                  <>
                    Please wait{" "}
                    <CircularProgress
                      className={classes.circle}
                      color="inherit"
                      size={16}
                    />
                  </>
                ) : (
                  <> Send</>
                )}
              </Button>
            </Box>

            <Grid item>
              <Alert severity="info">
                Response will be sent to the email address used for your login
              </Alert>
            </Grid>
          </Grid>
        </>
      )}
    </OftadehLayout>
  );
};

export default ContactPage;
