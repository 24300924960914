import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormGroup,
  Grid,
  makeStyles,
  Typography,
  CircularProgress,
  FormControl,
  TextField,
} from "@material-ui/core";

import { useFormik } from "formik";
import * as Yup from "yup";

import { observer } from "mobx-react-lite";

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .trim()
    .min(6, "Minimum 6 symbols")
    .max(20, "Maximum 20 symbols")
    .matches(
      /^(?=.*[a-zA-Z])(?=.*[0-9]).+$/,
      "Should have at least one number and letter"
    )
    .required("Password is required"),
});

const useStyles = makeStyles((theme) => ({
  error: {
    color: "red",
    marginTop: ".85rem",
    textAlign: "center",
    maxWidth: 158,
  },
}));

const ChangePasswordCode = ({ updatePassword }) => {
  const [updatingError, setUpdatingError] = useState("");
  const [changed, setChanged] = useState(false);

  const classes = useStyles();

  const formik = useFormik({
    initialValues: { password: ''},
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        await updatePassword(values.password);
        setChanged(true);
      } catch (err) {
        setUpdatingError(
          err.response?.data?.message || "Saving failed, please try again!"
        );
      }
    },
  });

  useEffect(() => {
    setUpdatingError("");
  }, [formik.values]);

  return (
    <Box display="flex" alignItems="center" flexDirection="column" p={2.5}>
      <h3>
        Change Password
      </h3>
      <Box p={2} marginTop={2}>
        <FormGroup row>
          <Grid container direction={"column"} spacing={3}>
            <Box display="flex" alignItems="center">
              <FormControl className={classes.formControl}>
                <TextField
                  size="small"
                  label="New Password"
                  name="password"
                  margin="dense"
                  type="password"
                  inputProps={{ min: 1 }}
                  value={formik.values.roomCode}
                  onChange={(e) => {
                    if(changed) setChanged(false);
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  error={!!formik.touched.roomCode && !!formik.errors.roomCode}
                  helperText={formik.touched.roomCode && formik.errors.roomCode}
                />
              </FormControl>
            </Box>
          </Grid>
        </FormGroup>

        {updatingError && (
          <Typography className={classes.error} variant="body1">
            {updatingError}
          </Typography>
        )}
      </Box>

      <Box display="flex" justifyContent="center" m={2.5}>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={formik.handleSubmit}
          disabled={
            !!formik.errors.roomCode ||
            formik.isSubmitting ||
            changed
          }
        >
          {formik.isSubmitting ? (
            <>
              Please wait <CircularProgress color="inherit" size={16} />
            </>
          ) : (
            <> Update</>
          )}
        </Button>
      </Box>
    </Box>
  );
};

export default observer(ChangePasswordCode);
