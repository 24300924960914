import React, { useState } from "react";
import {
  Button,
  Box,
  Typography,
  makeStyles,
  CircularProgress,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

import { useHistory } from "react-router-dom";

import CSVReader from "react-csv-reader";

import PreEnteredRoundQuestions from "../../components/PreEnteredRoundQuestionsEditable/PreEnteredRoundQuestions";
import CongratulationModal from "../../components/Modals/CongratulationModal/CongratulationModal";
import CreateRoundModal from "../../components/CreateRound/CreateRound";

const useStyles = makeStyles({
  error: {
    color: "#f44336",
    marginTop: ".85rem",
    fontSize: "0.75rem",
    textAlign: "center",
  },
});

const ManualQuestions = ({ upload, changeTab, uploadImage }) => {
  const [fileUploadError, setFileUploadError] = useState("");
  const [questionsError, setQuestionsError] = useState("");
  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [roundName, setRoundName] = useState("");
  const [openCongratulationModal, setOpenCongratulationModal] = useState(false);
  const [openNewRoundModal, setOpenNewRoundModal] = useState(false);

  const classes = useStyles();
  const history = useHistory();

  const clearRound = () => {
    setQuestions([]);
    setRoundName("");
    setQuestionsError("");
    document.getElementById("csvreader").value = "";
  };

  const closeCongratulationModal = () => {
    setOpenCongratulationModal(false);
    setRoundName("");
    changeTab();
  };

  const handleFileUpload = async () => {
    setLoading(true);

    let questionsToUpload = await Promise.all(
      questions.map((question, index) => {
        if (question.index !== null) {
          delete question.index;
          return question;
        } else return { ...question, orderNum: index + 1 };
      })
    );

    try {
      await upload(roundName, questionsToUpload);
      setLoading(false);
      setOpenCongratulationModal(true);
    } catch (err) {
      setLoading(false);
      if (err.response?.status === 409) {
        // TODO: add error handling
        setQuestionsError(err.response.data?.message);
      } else if (err.response?.status === 500) {
        history.push("/pages/errors/error-500");
      }
    }
  };

  return (
    <>
      <Box m={2.2}>
        <Alert severity="info">
          Here, you can create Rounds and add Questions manually. These
          questions will be available (in pre-entered mode) to all your hosts.
        </Alert>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          style={{ marginRight: "15px", marginTop: 20 }}
          onClick={() => setOpenNewRoundModal(true)}
        >
          {roundName !== "" ? "Add new question" : "Create Round"}
        </Button>
      </Box>
      <CreateRoundModal
        open={openNewRoundModal}
        questions={questions}
        setQuestions={setQuestions}
        setOpen={setOpenNewRoundModal}
        setRoundName={setRoundName}
        roundName={roundName}
        uploadImage={uploadImage}
      />
      {roundName !== "" && (
        <Typography variant="h4" style={{ textAlign: "center" }}>
          Round: "{roundName}"
        </Typography>
      )}
      {!!questions.length ? (
        <>
          <PreEnteredRoundQuestions
            questions={questions}
            setQuestions={setQuestions}
            roundName={roundName}
          />
          <Button
            variant="contained"
            color="danger"
            fullWidth
            style={{ marginTop: "15px" }}
            onClick={clearRound}
          >
            Clear round
          </Button>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            style={{ marginTop: "15px", height: 38 }}
            disabled={loading}
            onClick={handleFileUpload}
          >
            {loading ? (
              <CircularProgress
                className={classes.circularProgress}
                color="inherit"
                size={16}
              />
            ) : (
              "save round"
            )}
          </Button>
          {questionsError && <p className={classes.error}>{questionsError}</p>}
        </>
      ) : (
        <></>
      )}

      <CSVReader
        accept=".csv, text/csv, application/vnd.ms-excel"
        onFileLoaded={handleFileUpload}
        onError={() =>
          setFileUploadError(
            "Something went wrong. Please, check the file extension and try again!"
          )
        }
        parserOptions={{
          dynamicTyping: true,
          skipEmptyLines: true,
        }}
        inputId="csvreader"
        inputStyle={{ display: "none" }}
        strict
      />
      <CongratulationModal
        open={openCongratulationModal}
        handleClose={closeCongratulationModal}
      >
        Round <b>{roundName}</b> successfully added!
        <br />
        Now your hosts can use this round in their games!
      </CongratulationModal>
    </>
  );
};

export default ManualQuestions;
