import React from "react";
import { PayPalButton } from "react-paypal-button-v2";

const paypalKey = process.env.REACT_APP_PAYPAL_CLIENT_ID;

export const OrderBtn = ({
  createOrder,
  onApprove,
  catchError,
  onError,
  onCancel,
  setButtonReady,
}) => {
  return (
    <PayPalButton
      amount={10}
      onButtonReady={() => setButtonReady(true)}
      createOrder={(data, details) => createOrder(data, details)}
      onApprove={(data, details) => onApprove(data, details)}
      onError={(err) => onError(err)}
      catchError={(err) => catchError(err)}
      onCancel={(err) => onCancel(err)}
      options={{
        clientId: paypalKey,
      }}
      style={{
        shape: "rect",
        color: "blue",
        layout: "vertical",
      }}
    />
  );
};
export default OrderBtn;
