import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { CircularProgress, makeStyles } from "@material-ui/core";

import OrderBtn from "../../PaypalBtn/OrderBtn";

const useStyles = makeStyles({
  backButton: {
    display: "flex",
    justifyContent: "center",
  },
  circularProgress: {
    color: "#3f51b5",
    display: "flex",
    justifyContent: "center",
    height: "80%",
    alignItems: "center",
  },
  alignCenter: {
    textAlign: "center",
  },
});

const IncreaseSubscriptionModal = ({
  open,
  hostsQuantity,
  price,
  approved,
  loading,
  fee,
  handleClose,
  onError,
  onApprove,
  createOrder,
  onCancel,
}) => {
  const [buttonReady, setButtonReady] = useState(false);
  const classes = useStyles();

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle className={classes.alignCenter} id="alert-dialog-title">
          Update your subscription
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            className={classes.alignCenter}
            id="alert-dialog-description"
          >
            You will pay one-time fee in amount of <b>{fee}$</b>, and you will
            have <b>{hostsQuantity}</b> hosts with monthly payment of{" "}
            <b>{price}$</b>
          </DialogContentText>

          {!approved && (
            <OrderBtn
              createOrder={createOrder}
              onApprove={onApprove}
              catchError={onError}
              onError={onError}
              onCancel={onCancel}
              setButtonReady={setButtonReady}
            />
          )}
          {(!buttonReady || loading) && (
            <div className={classes.circularProgress}>
              <CircularProgress color="inherit" size={40} />
            </div>
          )}
        </DialogContent>
        <DialogActions className={classes.backButton}>
          {!loading && (
            <Button onClick={handleClose} color="primary">
              Go Back
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default IncreaseSubscriptionModal;
