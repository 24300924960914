import * as React from "react";
import {
	TextField,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	useMediaQuery,
	useTheme,
} from "@material-ui/core";
import { useFormik } from "formik";
import * as Yup from "yup";
import CreateQuestionModal from "../Modals/CreateQuestionModal/CreateQuestionModal";

const validationSchema = Yup.object().shape({
	roundName: Yup.string().trim().required("Round name is required"),
});
const questionsValidationSchema = Yup.object().shape({
	question: Yup.string().trim().required("Question name is required"),
	answer: Yup.string().trim().required("Answer name is required"),
	imageUrl: Yup.string()
		.trim()
		.matches(
			"/^((https?)://)?([w|W]{3}.)+[a-zA-Z0-9-.]{3,}.[a-zA-Z]{2,}(.[a-zA-Z]{2,})?$/",
			"This is not a valid url"
		)
		.required("Required"),
});

export default function ResponsiveDialog(props) {
	const [open, setOpen] = React.useState(props.open);
	const fileInput = React.createRef();
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
	const [questionsError, setQuestionsError] = React.useState("");
	const [uploadedImage, setUploadedImage] = React.useState(null);
	const [uploadedImageUrl, setUploadedImageUrl] = React.useState(null);
	const [imageUploadLoader, setImageUploadLoader] = React.useState(false);
	// const [timeForQuestions, setTimeForQuestions] = React.useState(false);

	const handleClose = () => {
		props.setOpen(false);
	};

	React.useEffect(() => {
		if (uploadedImage) {
			uploadImage();
		}
	}, [uploadedImage]);

	const uploadImage = async () => {
		setImageUploadLoader(true);
		try {
			let response = await props.uploadImage(uploadedImage);
			setImageUploadLoader(false);
			if (response.url) {
				setUploadedImageUrl(response.url);
			}
		} catch (err) {
			setImageUploadLoader(false);
		}
	};

	const formik = useFormik({
		initialValues: { roundName: "" },
		validationSchema,
		onSubmit: async (values, { setSubmitting }) => {
			setQuestionsError("");
			// setSubmitting(true);
			props.setRoundName(values.roundName);
		},
	});

	return (
		<div>
			{props.roundName !== "" ? (
				<>
					<CreateQuestionModal
						roundName={props.roundName}
						open={props.open}
						setOpen={props.setOpen}
						uploadImage={props.uploadImage}
						setQuestions={props.setQuestions}
						questions={props.questions}
					/>
				</>
			) : (
				<>
					<Dialog
						fullScreen={fullScreen}
						open={props.open}
						onClose={imageUploadLoader ? null : handleClose}
						aria-labelledby="responsive-dialog-title"
					>
						<DialogTitle
							id="responsive-dialog-title"
							style={{ textAlign: "center" }}
						>
							{"Create New Round"}
						</DialogTitle>
						<DialogContent>
							<DialogContentText>
								Enter the name of the round here. Once you create a round you
								can start adding questions in them.
							</DialogContentText>
							<TextField
								size="small"
								label="Round name"
								name="roundName"
								variant="outlined"
								margin="dense"
								autoComplete="off"
								fullWidth
								// inputProps={{ style: { textAlign: "center" } }}
								value={formik.values.roundName}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								error={!!formik.touched.roundName && !!formik.errors.roundName}
								helperText={formik.touched.roundName && formik.errors.roundName}
							/>
						</DialogContent>
						<DialogActions
							style={{ padding: "0px 23px 10px", marginBottom: "15px" }}
						>
							<Button
								fullWidth
								color="secondary"
								variant="contained"
								onClick={handleClose}
							>
								Cancel
							</Button>
							<Button
								fullWidth
								color="primary"
								variant="contained"
								onClick={formik.handleSubmit}
							>
								Create
							</Button>
						</DialogActions>
					</Dialog>
				</>
			)}
		</div>
	);
}
