import React, { useCallback, useEffect, useState } from "react";
import OftadehLayout from "../../components/OftadehLayout/OftadehLayout";
import {
	Grid,
	Typography,
	makeStyles,
	Paper,
	Button,
	FormControlLabel,
	Checkbox,
	Table,
	Icon,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Box,
} from "@material-ui/core";

import Alert from "@material-ui/lab/Alert";

import { observer } from "mobx-react-lite";
import { useIsMounted } from "../../helpers/useIsMounted";
import { useStore } from "../../stores/helpers/useStore";
import UserTable from "./UserTable";
import UsersService from "../../services/UsersService";

const useStyles = makeStyles(() => ({
	titlePaper: {
		marginBottom: "16px",
	},
	availableSpace: {
		fontVariant: "diagonal-fractions",
		fontSize: 36,
	},
}));

const getCodeFromEmail = (email) => {
	if (!email) {
		return "N/A";
	}
	const normalizedEmail = email.toLowerCase();
	let hash = 0;
	for (let i = 0; i < normalizedEmail.length; i++) {
		const char = normalizedEmail.charCodeAt(i);
		hash = (hash * 31 + char) % 10000; // Use a prime number and mod 10000 for 4-digit code
	}
	const code = ("0000" + Math.abs(hash)).slice(-4);

	return code;
};

const UserDetails = (props) => {
	const classes = useStyles();
	const isMounted = useIsMounted();
	const [userDetails, setUserDetails] = useState(null);
	//perform axios command on useEffect for getting user details and also a function that can be ran via a button
	const userId = props.match.params.id;
	const loading = false;

	useEffect(() => {
		const fetchUserDetails = async () => {
			try {
				const response = await UsersService.getUserDetails(userId);
				//console.log("Returned", response.data);
				setUserDetails(response.data);
			} catch (error) {
				console.error("Failed to fetch user details:", error);
			}
		};

		fetchUserDetails();
	}, [userId]); // Empty dependency array to only run once on mount

	if (!userDetails) {
		return <div>Loading...</div>;
	}

	return (
		<OftadehLayout loading={loading || !isMounted}>
			<Box m={2}>
				<Typography variant="p" className="p-2" gutterBottom>
					User/Team Details for {userDetails.userEmail}
				</Typography>
				<div>
					<h1>Game History</h1>
					{userDetails.map((game, index) => (
						<div key={game._id} style={{ marginBottom: "20px" }}>
							<h2>Room: {game.room.roomCode}</h2>
							{game.gameRoundDetails?.rounds.map((round, roundIndex) => (
								<div key={roundIndex} style={{ paddingLeft: "20px" }}>
									<h3>Round {roundIndex + 1}</h3>
									<p>
										<strong>Version:</strong> {round.version}
									</p>
									<p>
										<strong>Mode:</strong> {round.roundMode}
									</p>
									<p>
										<strong>Wager Mode:</strong>{" "}
										{round.wagerMode ? "Enabled" : "Disabled"}
									</p>

									<div>
										<h4>Questions:</h4>
										{round.questions.map((question, questionIndex) => (
											<div key={questionIndex} style={{ paddingLeft: "20px" }}>
												<p>
													<strong>Q:</strong> {JSON.stringify(question)}
												</p>
												<p>
													<strong>A:</strong> {question.answer}
												</p>
											</div>
										))}
									</div>
								</div>
							))}
						</div>
					))}
				</div>
			</Box>
		</OftadehLayout>
	);
};

export default observer(UserDetails);
