import { makeAutoObservable, runInAction } from "mobx";
import GameSettings from "./SettingsStore/GameSettings";
import ProGameOptions from "./SettingsStore/ProGameOptions";
import HostsService from "../../services/HostsService";

class Host {
  id = "";
  roomCode = "";
  gameStatus = "";
  playersCount = 0;
  users = [];

  gameSettings = {};
  proGameOptions = {};

  constructor(id, roomCode, gameStatus, playersCount, users) {
    makeAutoObservable(this, {
      id: false,
    });

    this.id = id;
    this.roomCode = roomCode;
    this.gameStatus = gameStatus;
    this.playersCount = playersCount;
    this.users = users;
  }

  initializeHostGameSettings = (gameSettings) => {
    this.gameSettings = new GameSettings(gameSettings);
  };

  initializeHostProGameOptions = (proGameOptions) => {
    this.proGameOptions = new ProGameOptions(proGameOptions);
  };

  updateRoomCode = async (roomCode) => {
    const response = await HostsService.updateRoomCode(roomCode, this.id);

    runInAction(() => {
      this.roomCode = response.roomCode;
    });
  };

  updatePassword = async (password) => {
    await HostsService.updatePassword(password, this.id);
  };

  updateHostGameSettings = async (gameSettings) => {
    const response = await HostsService.updateHostGameSettings({
      gameSettings,
      hostId: this.id,
    });

    this.gameSettings.update(response.gameSettings);
  };

  updateHostProGameOptions = async (proGameOptions) => {
    const response = await HostsService.updateHostProGameOptions({
      proGameOptions,
      hostId: this.id,
    });

    this.proGameOptions.update(response.proGameOptions);
  };
}

export default Host;
