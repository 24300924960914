import React, { useState } from "react";
import {
	makeStyles,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import CreateQuestionModal from "../Modals/CreateQuestionModal/CreateQuestionModal";
const useStyles = makeStyles({
	table: {
		"& tbody": {
			textTransform: "uppercase",

			"& th,td": {
				fontWeight: "bold",
			},
		},
	},
});

const RoundQuestions = (props) => {
	const classes = useStyles();
	const [editableState, setEditableState] = React.useState(false);
	const [editableStateValues, setEditableStateValuea] = React.useState(null);
	const [prevQuestion, setPrevQuestion] = useState();

	const deleteQuestion = async (index) => {
		let updatedQuestions = await Promise.all(
			props.questions.filter((item, i) => i !== index)
		);
		props.setQuestions(updatedQuestions);
	};

	return (
		<>
			<TableContainer>
				<Table
					className={classes.table}
					style={{ tableLayout: "fixed" }}
					aria-label="simple table"
				>
					<TableHead>
						<TableRow>
							<TableCell width={20} align="left">
								№
							</TableCell>
							<TableCell align="center">Questions</TableCell>
							<TableCell align="center">Answers</TableCell>
							{props.questions.some(
								(item) => item["image"] !== "" && item["image"] !== null
							) && <TableCell align="center"></TableCell>}
							<TableCell align="center"></TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{props.questions.map((row, index) => (
							<TableRow key={index}>
								<TableCell align="left">{index + 1}</TableCell>

								<TableCell align="center">{row.questionText}</TableCell>
								<TableCell align="center">{row.answerText}</TableCell>
								{props.questions.some(
									(item) => item["image"] !== "" && item["image"] !== null
								) &&
								row.image &&
								row.image.length ? (
									<TableCell align="center">
										<img
											src={row.image}
											style={{ width: 100, height: 100, objectFit: "cover" }}
										/>
									</TableCell>
								) : (
									<TableCell align="center">
										<h5 style={{ display: "none" }}>asd</h5>
									</TableCell>
								)}
								<TableCell align="center">
									<EditIcon
										onClick={() => {
											setEditableState(true);
											setEditableStateValuea({ ...row, index });
											setPrevQuestion({
												question: row.questionText,
												answer: row.answerText,
												imageUrl: row.image,
												orderNum: row.orderNum,
											});
										}}
										fontSize="15px"
										style={{ cursor: "pointer" }}
									/>
									&nbsp;
									<DeleteIcon
										onClick={() => deleteQuestion(index)}
										fontSize="10px"
										style={{ cursor: "pointer" }}
									/>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
			{editableState && (
				<CreateQuestionModal
					roundName={props.roundName}
					open={true}
					mode="edit"
					setOpen={() => setEditableState(false)}
					prevQuestion={prevQuestion}
					uploadImage={props.uploadImage}
					setQuestions={props.setQuestions}
					questions={props.questions}
					editableStateValues={editableStateValues}
				/>
			)}
		</>
	);
};

export default RoundQuestions;
