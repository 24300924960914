import React, { useCallback, useEffect, useState } from "react";

import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import { Button, makeStyles } from "@material-ui/core";

import { observer } from "mobx-react-lite";

import { useStore } from "../../stores/helpers/useStore";

import OftadehLayout from "../../components/OftadehLayout/OftadehLayout";

import { getSubscriptionPrice } from "../../helpers/getSubscriptionPrice";

const useStyles = makeStyles({
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "80vh",
    justifyContent: "space-evenly",
    "& p": {
      fontSize: 20,
    },
    "& h2": {
      fontSize: 30,
    },
  },
  thumbUp: {
    fontSize: 55,
    color: "#3f51b5",
  },
});

const SubscriptionApprovedPage = (props) => {
  const {
    subscriptionStore: {
      approveSubscriptionUpdate,
      subscriptionQuantity,
      nextCycleQuantity,
    },
    uiStore: { loading, cancelLoading, startLoading },
  } = useStore();

  const [baToken, setBaToken] = useState("");
  const [subscriptionId, setSubscriptionId] = useState("");
  const [approved, setApproved] = useState(false);
  const [updateMethod, setUpdateMethod] = useState(""); // INCREASE | DECREASE

  const { history } = props;

  const classes = useStyles();

  useEffect(() => {
    if (!history.location.search) history.push("/subscription");

    startLoading();

    const splitedUrl = history.location.search.substring(1).split("&");

    for (const query of splitedUrl) {
      let splitedQuery = query.split("=");
      if (splitedQuery[0] === "ba_token") {
        setBaToken(splitedQuery[1]);
      } else if (splitedQuery[0] === "subscription_id") {
        setSubscriptionId(splitedQuery[1]);
      }
    }
  }, [history, startLoading]);

  const approve = useCallback(
    async (id, token) => {
      try {
        const response = await approveSubscriptionUpdate(id, token);

        setUpdateMethod(response.updateMethod);
        setApproved(true);
      } catch (err) {
        cancelLoading();
      }
    },
    [approveSubscriptionUpdate, cancelLoading]
  );

  useEffect(() => {
    if (baToken && subscriptionId) {
      history.replace({
        search: "",
      });
      approve(subscriptionId, baToken);
    } else {
      cancelLoading();
    }
  }, [baToken, subscriptionId, history, cancelLoading, approve]);

  return (
    <OftadehLayout loading={loading}>
      {approved ? (
        <div className={classes.content}>
          <h2>Your subscription was successfully updated!</h2>
          <ThumbUpIcon className={classes.thumbUp} />
          {
            {
              INCREASE: (
                <p>
                  Now you can have <b>{subscriptionQuantity}</b> hosts with
                  monthly payment of{" "}
                  <b>{getSubscriptionPrice(subscriptionQuantity)}$</b>{" "}
                </p>
              ),
              DECREASE: (
                <p>
                  Now you can have <b>{subscriptionQuantity}</b> hosts. From
                  next subscription cycle you will have{" "}
                  <b>{nextCycleQuantity}</b> hosts with monthly payment of{" "}
                  <b>{getSubscriptionPrice(nextCycleQuantity)}$</b>{" "}
                </p>
              ),
            }[updateMethod]
          }
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={() => history.push("/hosts")}
          >
            Confirm
          </Button>
        </div>
      ) : (
        <div className={classes.content}>
          <h2>Your subscription is not approved</h2>

          <p>If you have some questions, please contact us</p>

          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={() => history.push("/contact")}
          >
            Contact Us
          </Button>
        </div>
      )}
    </OftadehLayout>
  );
};

export default observer(SubscriptionApprovedPage);
