import BrandPage from "./BrandPage";

export const BrandPageConfig = {
  routes: [
    {
      path: "/brand",
      exact: true,
      component: BrandPage,
    },
  ],
};
