import React from "react";
import { IconButton, Tooltip, Icon } from "@material-ui/core";

const ToolTip = ({ text }) => {
	return (
		<Tooltip title={text} style={{ paddingTop: "2px", opacity: 0.6 }}>
			<Icon fontSize="small" color="disabled">
				help-outlined-outline
			</Icon>
		</Tooltip>
	);
};

export default ToolTip;
