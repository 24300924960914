import React from "react";
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from "@material-ui/core";

import "./game.css";

import CheckBox from "@material-ui/icons/CheckBox";

class GameHistoryViewTable extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			records: [],
		};
		let gameData = this.props.gameData;
		console.log("prop", gameData);
		this.state.records = gameData.records;
	}

	componentDidMount() {
		//set columns from gameData
	}

	render() {
		const { records } = this.state;

		return (
			<div>
				{records?.length && (
					<div style={{ padding: "12px" }}>
						{/* <DataTable columns={columns} data={records} /> */}
						<TableContainer>
							<Table>
								<TableHead>
									<TableRow>
										<TableCell>User</TableCell>
										<TableCell>Total Score</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{/* {records.map((row, index) => (
										<TableRow key={row.id}>
											<TableCell>
												<span className="response-text">{index + 1}</span>
											</TableCell>

											<TableCell>{row.userEmail}</TableCell>
											<TableCell>{row.totalscore}</TableCell>
										</TableRow>
									))} */}
								</TableBody>
							</Table>
						</TableContainer>
					</div>
				)}
			</div>
		);
	}
}

export default GameHistoryViewTable;
