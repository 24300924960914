import React, { useCallback, useEffect, useState } from "react";
import OftadehLayout from "../../components/OftadehLayout/OftadehLayout";
import {
	Grid,
	Typography,
	makeStyles,
	Paper,
	Button,
	FormControlLabel,
	Checkbox,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

import { observer } from "mobx-react-lite";
import { useIsMounted } from "../../helpers/useIsMounted";
import { useStore } from "../../stores/helpers/useStore";
import UserTable from "./UserTable";

const useStyles = makeStyles(() => ({
	titlePaper: {
		marginBottom: "16px",
	},
	availableSpace: {
		fontVariant: "diagonal-fractions",
		fontSize: 36,
	},
}));

const UserList = (props) => {
	const classes = useStyles();
	const isMounted = useIsMounted();
	const [getAll, setGetAll] = useState(false);

	const {
		hostsStore: { usersList, getListOfUsers },

		uiStore: { loading },
	} = useStore();

	// const refresh = useCallback(() => {
	// 	getListOfUsers(getAll);
	// }, [getListOfUsers, getAll]); // Add getAll as a dependency

	const updateValue = (e) => {
		setGetAll(e.target.checked);
	};

	// useEffect(() => {
	// 	refresh();
	// }, [refresh, getAll]); // Add getAll as a dependency

	return (
		<>
			<OftadehLayout loading={loading || !isMounted}>
				<h1>Users</h1>

				<>
					{!!usersList.length ? (
						<>
							<Paper style={{ padding: "12px", marginBottom: "8px" }}>
								<div className="d-flex flex-row justify-content-between">
									<div>
										<FormControlLabel
											required
											value={getAll}
											checked={getAll}
											onChange={updateValue}
											control={<Checkbox />}
											label="Show All (including inactive)"
										/>
									</div>
								</div>
							</Paper>
							<Paper style={{ padding: "5px" }}>
								<UserTable usersList={usersList} />
							</Paper>
						</>
					) : (
						<Grid container direction="column">
							<Typography
								className={classes.titlePaper}
								style={{ marginBottom: 40 }}
								variant="h6"
							>
								Page is under construction.
							</Typography>
							<Alert severity="info">
								Users will show up here once they have signed up for a game, and
								the game has ended. "Users" will only be created if
								user-authentication is enabled, requiring that players sign-up
								with an email before joining a given room.
							</Alert>
						</Grid>
					)}
				</>
			</OftadehLayout>
		</>
	);
};

export default observer(UserList);
