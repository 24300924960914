import { computed, makeAutoObservable } from "mobx";

export default class UIStore {
  pendingRequests = 0;

  constructor() {
    makeAutoObservable(this, {
      loading: computed,
    });
  }

  get loading() {
    return this.pendingRequests > 0;
  }

  startLoading = () => {
    this.pendingRequests++;
  };

  cancelLoading = () => {
    this.pendingRequests--;
  };
}
