import RegisterPage from "./RegisterPage";

export const RegisterPageConfig = {
  routes: [
    {
      path: "/pages/auth/register",
      exact: true,
      component: RegisterPage,
      public: true,
    },
  ],
};
