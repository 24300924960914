import { makeAutoObservable } from "mobx";
import { compareOrderNum } from "../../../utils/compare";

class PreEnteredRound {
  id = "";
  name = "";
  uploaded = "";
  wagerScheme = {};
  preEnteredQuestions = [];

  constructor({ _id, id, name, uploaded, preEnteredQuestions, wagerScheme }) {
    makeAutoObservable(this, {
      id: false,
    });
    this.id = _id ?? id;
    this.name = name;
    this.uploaded = uploaded.slice(0, 10);
    this.preEnteredQuestions = preEnteredQuestions.sort(compareOrderNum);
    this.wagerScheme = { name: wagerScheme?.name, id: wagerScheme?._id };
  }

  updateWagerScheme = (scheme) => {
    this.wagerScheme = { name: scheme?.name, id: scheme?._id };
  };
}

export default PreEnteredRound;
