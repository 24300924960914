import ApiService from "../abstracts/ApiService";

class ContactService extends ApiService {
  async contactUs(email, subject, message) {
    const response = await this.protectedRequest({
      url: "/contact/admin",
      method: "post",
      data: {
        email,
        subject,
        message,
      },
    });

    return response;
  }
}

const instance = new ContactService();

export default instance;
