import React, { useState } from "react";
import "./App.css";
import OftadehRoutes from "./components/OftadehRoutes/OftadehRoutes";
import { ThemeProvider } from "@material-ui/core/styles";

import getTheme from "./oftadeh-configs/themeConfig";
import ThemeContext from "./context/ThemeContext";
import { StoreProvider } from "./stores/helpers/StoreContext";
import { createStore } from "./stores/helpers/createStore";
import { toJS } from "mobx";
import labels from "./utils/labels";

// import "@fontsource/montserrat/300.css";
// import "@fontsource/montserrat/400.css";
// import "@fontsource/montserrat/500.css";
// import "@fontsource/montserrat/700.css";

window.labs = labels; //set window.labs to labels for easy access

require("./index.css");

const userStore = createStore();

const App = () => {
	const curThemeName = localStorage.getItem("appTheme") || "light";

	const [themeType, setThemeType] = useState(curThemeName);

	const setThemeName = (themeName) => {
		localStorage.setItem("appTheme", themeName);
		setThemeType(themeName);
	};

	const theme = getTheme({
		paletteType: themeType,
		typography: {
			fontFamily: [
				"montserrat",
				'"Helvetica Neue"',
				"Arial",
				"sans-serif",
			].join(","),
		},
	});

	return (
		<ThemeContext.Provider value={{ setThemeName, curThemeName }}>
			<ThemeProvider theme={theme}>
				<StoreProvider value={userStore}>
					<div className="App">
						<OftadehRoutes />
					</div>
				</StoreProvider>
			</ThemeProvider>
		</ThemeContext.Provider>
	);
};

export default App;

window.rootStore = toJS(userStore);
