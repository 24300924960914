import ApiService from "../abstracts/ApiService";

class SubscriptionService extends ApiService {
  async getSubscriptionPlanInfo() {
    const response = await this.protectedRequest({
      url: "/admin/subscription-plan",
    });

    return response;
  }

  async createAdminSubscription(subscriptionId) {
    const response = await this.protectedRequest({
      url: "/admin/create-subscription",
      method: "post",
      data: { subscriptionId },
    });

    return response;
  }

  async getSubscriptionInfo() {
    const response = await this.protectedRequest({
      url: "/admin/subscription-info",
    });

    return response;
  }

  async updateAdminSubscription(orderID, quantity) {
    const response = await this.protectedRequest({
      url: "/admin/update-subscription",
      method: "put",
      data: {
        orderID,
        quantity,
      },
    });

    return response;
  }

  async cancelAdminSubscripition(reason) {
    const response = await this.protectedRequest({
      url: "/admin/cancel-subscription",
      method: "put",
      data: {
        reason,
      },
    });

    return response;
  }

  async approveSubscriptionUpdate(approvedSubId, ba_token) {
    const response = await this.protectedRequest({
      url: "/admin/approve-subscription",
      method: "put",
      data: {
        approvedSubId,
        ba_token,
      },
    });

    return response;
  }
}

const instance = new SubscriptionService();

export default instance;
