import React, { useState } from "react";
import {
	Button,
	Collapse,
	makeStyles,
	TableCell,
	TableRow,
	Icon,
} from "@material-ui/core";

import PreEnteredRoundQuestions from "../../../components/PreEnteredRoundQuestions/PreEnteredRoundQuestions";
import ConnectWagerSchemeModal from "../../../components/Modals/ConnectWagerSchemeModal/ConnectWagerSchemeModal";

const useStyles = makeStyles((theme) => ({
	questionsTable: {
		backgroundColor: theme.palette.background.default,
	},
}));

const RoundRow = ({
	round,
	collapsedRound,
	collapse,
	deletePreEnteredRound,
	editRound,
}) => {
	const [openConnectingSchemeModal, setOpenConnectingSchemeModal] =
		useState(false);
	const classes = useStyles();

	const handleClose = () => {
		setOpenConnectingSchemeModal(false);
	};

	return (
		<>
			<TableRow>
				<TableCell component="th" scope="row">
					<Button variant="contained" onClick={() => collapse(round.id)}>
						{round.name}
					</Button>
				</TableCell>
				<TableCell align="left">{round.preEnteredQuestions?.length}</TableCell>
				<TableCell align="left">{round.uploaded}</TableCell>
				<TableCell align="left">
					<Button onClick={() => setOpenConnectingSchemeModal(true)}>
						{round.wagerScheme?.name ? (
							round.wagerScheme?.name
						) : (
							<Icon
								style={{ color: "gray" }}
								size="small"
								title="Add a wagering scheme to this round"
							>
								add
							</Icon>
						)}
					</Button>
				</TableCell>
				<TableCell align="right">
					<Button
						color="secondary"
						onClick={() => deletePreEnteredRound(round.id, round.name)}
					>
						delete
					</Button>
					<Button color="primary" onClick={editRound}>
						edit
					</Button>
				</TableCell>
			</TableRow>
			<TableRow className={classes.questionsTable}>
				<TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
					<Collapse
						in={collapsedRound === round.id}
						timeout="auto"
						unmountOnExit
					>
						<PreEnteredRoundQuestions questions={round.preEnteredQuestions} />
					</Collapse>
				</TableCell>
			</TableRow>

			{openConnectingSchemeModal && (
				<ConnectWagerSchemeModal
					open={openConnectingSchemeModal}
					handleClose={handleClose}
					roundName={round.name}
					roundId={round.id}
					connectedScheme={round.wagerScheme.id}
				/>
			)}
		</>
	);
};

export default RoundRow;
