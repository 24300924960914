import React, { useEffect, useState } from "react";

import OftadehLayout from "../../components/OftadehLayout/OftadehLayout";

import { AppBar, Tabs, Tab, Box, makeStyles } from "@material-ui/core";
import ListIcon from "@material-ui/icons/List";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import TrackChangesIcon from "@material-ui/icons/TrackChanges";
import ExistingQuestions from "./ExistingQuestions/ExistingQuestions";
import ImportQuestions from "./ImportQuestions";
import AddQuestionsManually from "./AddQuestionsManually";

import { observer } from "mobx-react-lite";

import { useStore } from "../../stores/helpers/useStore";
import { useIsMounted } from "../../helpers/useIsMounted";
import EditRound from "./EditRound";

const TabPanel = ({ children, value, index }) => {
  return <div>{value === index && <Box p={3}>{children}</Box>}</div>;
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

const QuestionsPage = () => {
  const classes = useStyles();
  const isMounted = useIsMounted();
  const [tabIndex, setTabIndex] = useState(0);
  const [roundToEditIndex, setRoundToEditIndex] = useState(0);
  const {
    settingsStore: {
      preEnteredRoundsList,
      uploadPreEnteredRound,
      updatePreEnteredRound,
      uploadImage,
      getListOfPreEnteredRounds,
      getListOfWagerSchemes,
      deletePreEnteredRound,
    },
    uiStore: { loading },
  } = useStore();

  const changeTab = (event, newIndex) => {
    setTabIndex(newIndex);
  };

  useEffect(() => {
    getListOfPreEnteredRounds();
  }, [getListOfPreEnteredRounds]);

  useEffect(() => {
    getListOfWagerSchemes();
  }, [getListOfWagerSchemes]);
  return (
    <OftadehLayout loading={loading || !isMounted}>
      <div className={classes.root}>
        <AppBar position="static">
          <Tabs value={tabIndex} onChange={changeTab}>
            <Tab label="Existing Questions" icon={<ListIcon />} />
            <Tab label="Import" icon={<CloudUploadIcon />} />
            <Tab label="Add Questions Manually" icon={<TrackChangesIcon />} />
          </Tabs>
        </AppBar>
        <TabPanel value={tabIndex} index={0}>
          {roundToEditIndex === 0 ? (
            <ExistingQuestions
              rounds={preEnteredRoundsList}
              deletePreEnteredRound={deletePreEnteredRound}
              importQuestions={() => setTabIndex(1)}
              editRound={setRoundToEditIndex}
            />
          ) : (
            <EditRound
              round={preEnteredRoundsList[roundToEditIndex - 1]}
              upload={updatePreEnteredRound}
              uploadImage={uploadImage}
            />
          )}
        </TabPanel>
        <TabPanel value={tabIndex} index={1}>
          <ImportQuestions
            upload={uploadPreEnteredRound}
            changeTab={() => setTabIndex(0)}
          />
        </TabPanel>
        <TabPanel value={tabIndex} index={2}>
          <AddQuestionsManually
            upload={uploadPreEnteredRound}
            uploadImage={uploadImage}
            changeTab={() => setTabIndex(0)}
          />
        </TabPanel>
      </div>
    </OftadehLayout>
  );
};

export default observer(QuestionsPage);
