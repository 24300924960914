import { makeAutoObservable } from "mobx";

class ProGameOptions {
	switchRounds = false;
	adjustByResponse = false;
	showQuestions = false;
	adjustByQuestion = false;
	maxPointValue = 1;
	pauseModeContent = "";
	gamePauseMessage = "";
	displayCorrectAnswer = false;

	constructor(proGameOptions) {
		makeAutoObservable(this);

		this.update(proGameOptions);
	}

	update = ({
		switchRounds,
		adjustByResponse,
		showQuestions,
		adjustByQuestion,
		maxPointValue,
		pauseModeContent,
		gamePauseMessage,
		displayCorrectAnswer,
	}) => {
		this.switchRounds = switchRounds ?? false;
		this.adjustByResponse = adjustByResponse ?? false;
		this.showQuestions = showQuestions ?? false;
		this.adjustByQuestion = adjustByQuestion ?? false;
		this.maxPointValue = maxPointValue ?? 1;
		this.pauseModeContent = pauseModeContent ?? "";
		this.gamePauseMessage = gamePauseMessage ?? "";
		this.displayCorrectAnswer = displayCorrectAnswer ?? false;
	};
}

export default ProGameOptions;
