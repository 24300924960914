import React, { useState } from "react";

import {
	Paper,
	Box,
	TableContainer,
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
	Typography,
	Button,
	Grid,
	makeStyles,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

import RoundRow from "./RoundRow";
import ConfirmationModal from "../../../components/Modals/ConfirmationModal/ConfirmationModal";

const useStyles = makeStyles({
	table: {
		minWidth: 650,
	},
	error: {
		display: "flex",
		justifyContent: "center",
		marginTop: 30,
		fontSize: 16,
		color: "red",
	},
});

const ExistingQuestions = ({
	rounds,
	importQuestions,
	deletePreEnteredRound,
	editRound,
}) => {
	const [openedRound, setOpenedRound] = useState("");
	const [deletingError, setDeletingError] = useState("");
	const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
	const [roundToDelete, setRoundToDelete] = useState({});
	const [loading, setLoading] = useState(false);

	const classes = useStyles();

	const collapseRound = (id) => {
		setDeletingError("");
		if (id === openedRound) {
			setOpenedRound("");
			return;
		}
		setOpenedRound(id);
	};

	const handleDeletePreEnteredRound = async (id, name) => {
		setDeletingError("");
		setRoundToDelete({ id, name });
		setOpenConfirmationModal(true);
	};

	const confirmDeleting = async () => {
		setLoading(true);
		try {
			await deletePreEnteredRound(roundToDelete.id);
			collapseRound("");
		} catch (err) {
			setDeletingError(
				err.response.data?.message ||
					"Something went wrong. Please try again later!"
			);
		} finally {
			setOpenConfirmationModal(false);
			setLoading(false);
		}
	};

	const handleClose = () => {
		setOpenConfirmationModal(false);
	};

	return (
		<>
			<Box m={2.2}>
				<Alert severity="info" style={{ fontSize: "0.8em" }}>
					These questions will be available to all your hosts.
				</Alert>
			</Box>

			<Box m={2.2}>
				{!!rounds.length ? (
					<>
						<TableContainer component={Paper}>
							<Table className={classes.table} aria-label="simple table">
								<TableHead>
									<TableRow>
										<TableCell style={{ paddingLeft: 25 }}>ROUND</TableCell>
										<TableCell align="left">Questions</TableCell>
										<TableCell align="left">Uploaded</TableCell>
										<TableCell align="left">Wager Scheme</TableCell>
										<TableCell align="right" style={{ paddingRight: 25 }}>
											Actions
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{rounds.map((round, i) => (
										<RoundRow
											key={round.id}
											round={round}
											collapsedRound={openedRound}
											collapse={(id) => collapseRound(id)}
											deletePreEnteredRound={handleDeletePreEnteredRound}
											editRound={() => editRound(i + 1)}
										/>
									))}
								</TableBody>
							</Table>
						</TableContainer>
						{deletingError && (
							<div className={classes.error}>{deletingError} </div>
						)}
					</>
				) : (
					<Grid
						container
						direction="column"
						justify="center"
						alignItems="center"
						style={{ marginTop: "15px" }}
					>
						<Alert severity="warning" style={{ marginBottom: 40 }}>
							You don't have questions in our system. If you would like to use
							'pre-entered' mode for your hosts. You will need to import the
							questions (csv) or create them manually. If you plan to use
							"classic" mode (hosts read questions from external source), then
							you can ignore this section.
						</Alert>
						<Button
							variant="contained"
							color="primary"
							onClick={importQuestions}
						>
							Import questions
						</Button>
					</Grid>
				)}
			</Box>

			<ConfirmationModal
				title={`You really want to delete round "${roundToDelete.name}"?`}
				open={openConfirmationModal}
				loading={loading}
				handleClose={handleClose}
				confirm={confirmDeleting}
			>
				It will remove round from list of rounds of all your hosts.
			</ConfirmationModal>
		</>
	);
};

export default ExistingQuestions;
