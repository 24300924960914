import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  makeStyles,
  Switch,
  TextField,
  Typography,
} from "@material-ui/core";
import clsx from "clsx";

const validationSchema = Yup.object().shape({
  name: Yup.string().trim().required("Round name is required!"),
  type: Yup.string().oneOf(
    ["points", "percentage", "standard"],
    "Wrong type of scheme!"
  ),
  points: Yup.array().when("type", {
    is: (val) => val === "points",
    then: Yup.array().min(1, "Please, add points!"),
  }),
  percentage: Yup.number().when("type", {
    is: (val) => val === "percentage",
    then: Yup.number().min(1, "Minimum 1!").max(100, "Maximum 100!"),
  }),
  singleUse: Yup.boolean().when("type", {
    is: (val) => val === "percentage",
    then: Yup.boolean().oneOf([true], "Field must be checked"),
  }),
  mandatoryWagering: Yup.boolean().required(),
  subtractForIncorrect: Yup.boolean().required(),
  bonus: Yup.boolean().required(),
  bonusPoints: Yup.number().when("bonus", {
    is: true,
    then: Yup.number()
      .required("Please, add bonus points!")
      .min(1, "Minimum 1!"),
  }),
  pointToAdd: Yup.number().min(1, "Minimum 1!"),
});

const useStyles = makeStyles((theme) => ({
  form: {
    padding: "0 100px",
  },
  selected: {
    backgroundColor: "#303f9f",
  },
  error: {
    color: "#f44336",
    fontSize: 12,
  },
  addButton: {
    maxHeight: 40,
    maxWidth: 80,
    marginLeft: 10,
    marginTop: 8,
  },
  pointBtn: {
    marginRight: 5,
    maxHeight: 36,
    maxWidth: 36,
  },
  input: {
    width: 210,
  },
  switchBlock: { marginTop: 30 },
  description: { marginLeft: 48 },
  hr: { margin: "50px 0" },
}));

const WagerSchemeSetup = ({
  name = "",
  type = "points",
  points = [],
  pointToAdd = 1,
  percentage = 50,
  singleUse = true,
  mandatoryWagering = false,
  subtractForIncorrect = true,
  bonus = false,
  bonusPoints = 1,
  setupMode,
  submit,
  cancel,
}) => {
  const classes = useStyles();

  const formik = useFormik({
    initialValues: {
      name,
      type,
      points,
      pointToAdd,
      percentage,
      singleUse,
      mandatoryWagering,
      subtractForIncorrect,
      bonus,
      bonusPoints,
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values, { setFieldError, setSubmitting }) => {
      setSubmitting(true);

      await submit(values, setFieldError);
    },
  });

  const setPercentageType = () => {
    formik.setFieldValue("type", "percentage");
    formik.setFieldValue("singleUse", true);
    formik.setFieldTouched("points", false);
  };

  const addPoint = (point) => {
    formik.setFieldError("pointToAdd", "");
    formik.setFieldTouched("pointToAdd", false);
    if (point < 1) {
      formik.setFieldError("pointToAdd", "Minimum 1!");
      formik.setFieldTouched("pointToAdd", true);
      return;
    }
    formik.setFieldValue("points", [...formik.values.points, point]);
    formik.setFieldValue("pointToAdd", ++point);
  };

  const removePoint = (p) => {
    const arrayToSet = formik.values.points.slice();
    arrayToSet.splice(p, 1);
    if (!arrayToSet.length) {
      formik.setFieldValue("pointToAdd", 1);
    }
    formik.setFieldValue("points", arrayToSet);
  };

  return (
    <Box p={2}>
      <form
        noValidate
        autoComplete="off"
        className={classes.form}
        onSubmit={formik.handleSubmit}
      >
        <Box display="flex" alignItems="center" flexDirection="column" m={2.5}>
          <ButtonGroup disableElevation variant="contained" color="primary">
            <Button
              onClick={() => formik.setFieldValue("type", "points")}
              className={formik.values.type !== "standard" && classes.selected}
            >
              Wagering Round
            </Button>
            <Button
              onClick={() => formik.setFieldValue("type", "standard")}
              className={formik.values.type === "standard" && classes.selected}
            >
              Standard Round
            </Button>
          </ButtonGroup>
          {formik.errors.type && (
            <p className={classes.error}>{formik.errors.type}</p>
          )}
          <TextField
            size="small"
            label="Round Name"
            name="name"
            margin="dense"
            value={formik.values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={!!formik.touched.name && !!formik.errors.name}
            helperText={formik.touched.name && formik.errors.name}
            style={{ marginTop: 30 }}
          />
        </Box>

        {formik.values.type !== "standard" && (
          <Box marginBottom={2} display="flex" flexDirection="column">
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    onClick={() => formik.setFieldValue("type", "points")}
                    checked={formik.values.type === "points"}
                  />
                }
                label="Points"
              />
              <Box display="flex">
                <TextField
                  size="small"
                  label="Add point"
                  name="pointToAdd"
                  variant="outlined"
                  margin="dense"
                  type="number"
                  inputProps={{ min: 1 }}
                  className={classes.input}
                  disabled={formik.values.type !== "points"}
                  value={formik.values.pointToAdd}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    (!!formik.touched.pointToAdd &&
                      !!formik.errors.pointToAdd) ||
                    (!!formik.touched.points && !!formik.errors.points)
                  }
                  helperText={
                    (formik.touched.pointToAdd && formik.errors.pointToAdd) ||
                    (formik.touched.points && formik.errors.points)
                  }
                />
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  className={classes.addButton}
                  onClick={() => addPoint(formik.values.pointToAdd)}
                  disabled={
                    formik.isSubmitting || formik.values.type !== "points"
                  }
                >
                  Add
                </Button>
              </Box>
              {!!formik.values.points.length && (
                <Box marginTop={1}>
                  <Typography component="h5" variant="h5">
                    Points:
                  </Typography>
                  {formik.values.points.map((point, i) => (
                    <Button
                      key={i}
                      variant="contained"
                      color="secondary"
                      className={classes.pointBtn}
                      disabled={
                        formik.isSubmitting || formik.values.type !== "points"
                      }
                      onClick={() => removePoint(i)}
                    >
                      {point}
                    </Button>
                  ))}
                </Box>
              )}
            </FormGroup>

            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    onClick={setPercentageType}
                    checked={formik.values.type === "percentage"}
                  />
                }
                label="Up to percentage"
              />
              <Box display="flex" flexDirection="column">
                <TextField
                  size="small"
                  label="Percentage"
                  name="percentage"
                  variant="outlined"
                  margin="dense"
                  type="number"
                  inputProps={{ min: 1, max: 100 }}
                  className={classes.input}
                  disabled={formik.values.type !== "percentage"}
                  value={formik.values.percentage}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    !!formik.touched.percentage && !!formik.errors.percentage
                  }
                  helperText={
                    formik.touched.percentage && formik.errors.percentage
                  }
                />
                <Typography variant="body2">
                  When specifying a percentage, players will be able to wager
                  any point value up to the specified percentage of their total
                  score.
                </Typography>
              </Box>
            </FormGroup>

            <FormGroup className={classes.switchBlock}>
              <FormControlLabel
                value="end"
                disabled={formik.values.type === "percentage"}
                control={
                  <Switch
                    color="primary"
                    name="singleUse"
                    onChange={formik.handleChange}
                    checked={formik.values.singleUse}
                  />
                }
                label="Single Use"
                labelPlacement="end"
              />
              <Typography className={classes.description} variant="body2">
                With Single Use enabled, the wagering values will only be
                available to the players once. After it has been used, it will
                be removed from the select box.
              </Typography>

              <FormControlLabel
                value="end"
                control={
                  <Switch
                    color="primary"
                    name="mandatoryWagering"
                    onChange={formik.handleChange}
                    checked={formik.values.mandatoryWagering}
                  />
                }
                label="Mandatory Wagering"
                labelPlacement="end"
              />
              <Typography className={classes.description} variant="body2">
                When enabled, players will be required to wager their points on
                each question. If this is disabled, they may opt out of wagering
                and earn a single point if they are correct.
              </Typography>

              <FormControlLabel
                value="end"
                control={
                  <Switch
                    color="primary"
                    name="subtractForIncorrect"
                    onChange={formik.handleChange}
                    checked={formik.values.subtractForIncorrect}
                  />
                }
                label="Subtract"
                labelPlacement="end"
              />
              <Typography className={classes.description} variant="body2">
                When enabled, the wagering amount selected by the player will be
                subtracted from their score if their response was incorrect.
              </Typography>

              <FormControlLabel
                value="end"
                control={
                  <Switch
                    color="primary"
                    name="bonus"
                    onChange={formik.handleChange}
                    checked={formik.values.bonus}
                  />
                }
                label="Bonus"
                labelPlacement="end"
              />
              <Typography className={classes.description} variant="body2">
                When a player gets every question in this round correct, award
                them this many points:
              </Typography>
              <TextField
                size="small"
                label="Bonus Points"
                name="bonusPoints"
                variant="outlined"
                margin="dense"
                type="number"
                inputProps={{ min: 1, max: 100 }}
                className={clsx(classes.input, classes.description)}
                disabled={!formik.values.bonus}
                value={formik.values.bonusPoints}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  !!formik.touched.bonusPoints && !!formik.errors.bonusPoints
                }
                helperText={
                  formik.touched.bonusPoints && formik.errors.bonusPoints
                }
              />
            </FormGroup>
          </Box>
        )}

        <Box display="flex" justifyContent="center" m={2.5}>
          <Button
            variant="contained"
            color="default"
            style={{ marginRight: "15px" }}
            className={classes.button}
            onClick={cancel}
            disabled={formik.isSubmitting}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={formik.handleSubmit}
            disabled={formik.isSubmitting}
          >
            {formik.isSubmitting ? (
              <>
                Please wait <CircularProgress color="inherit" size={16} />
              </>
            ) : (
              <> {setupMode}</>
            )}
          </Button>
        </Box>
      </form>
      <hr className={classes.hr} />
    </Box>
  );
};

export default WagerSchemeSetup;
