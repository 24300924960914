import React, { useState } from "react";
import {
  Button,
  Box,
  Typography,
  makeStyles,
  CircularProgress,
  Input,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

import { useHistory } from "react-router-dom";

import RoundQuestions from "../../components/RoundQuestionsEditable/RoundQuestions";
import CongratulationModal from "../../components/Modals/CongratulationModal/CongratulationModal";
import CreateRoundModal from "../../components/CreateRound/CreateRound";

const useStyles = makeStyles({
  error: {
    color: "#f44336",
    marginTop: ".85rem",
    fontSize: "0.75rem",
    textAlign: "center",
  },
});

const EditRound = ({ upload, uploadImage, round }) => {
  const [questionsError, setQuestionsError] = useState("");
  const [questions, setQuestions] = useState(round.preEnteredQuestions);
  const [loading, setLoading] = useState(false);
  const [roundName, setRoundName] = useState(round.name);
  const [openCongratulationModal, setOpenCongratulationModal] = useState(false);
  const [openNewRoundModal, setOpenNewRoundModal] = useState(false);

  const classes = useStyles();
  const history = useHistory();

  const closeCongratulationModal = () => {
    setOpenCongratulationModal(false);
    history.go(0);
  };

  const handleUpdate = async () => {
    setLoading(true);
    const questionsToUpload = questions.map(
      ({ questionText, answerText, orderNum, image }) => ({
        questionText,
        answerText,
        orderNum,
        image,
      })
    );
    try {
      await upload(round.id, roundName, questionsToUpload);
      setLoading(false);
      setOpenCongratulationModal(true);
    } catch (err) {
      console.log(err);
      setLoading(false);
      if (err.response?.status === 409) {
        // TODO: add error handling
        setQuestionsError(err.response.data?.message);
      } else if (err.response?.status === 500) {
        history.push("/pages/errors/error-500");
      }
    }
  };

  return (
    <>
      <Box m={2.2}>
        <Alert severity="info">
          Here, you can create Rounds and add Questions manually. These
          questions will be available (in pre-entered mode) to all your hosts.
        </Alert>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          style={{ marginRight: "15px", marginTop: 20 }}
          onClick={() => setOpenNewRoundModal(true)}
        >
          {roundName !== "" ? "Add new question" : "Create Round"}
        </Button>
      </Box>
      <CreateRoundModal
        open={openNewRoundModal}
        questions={questions}
        setQuestions={setQuestions}
        setOpen={setOpenNewRoundModal}
        setRoundName={setRoundName}
        roundName={roundName}
        uploadImage={uploadImage}
      />
      {roundName !== "" && (
        <Typography variant="h4" style={{ textAlign: "center" }}>
          Round:{" "}
          <Input
            style={{ fontSize: "2.125rem" }}
            value={roundName}
            onChange={({ target: { value } }) => setRoundName(value)}
          />
        </Typography>
      )}
      {!!questions?.length ? (
        <>
          <RoundQuestions
            questions={questions}
            setQuestions={setQuestions}
            roundName={roundName}
            uploadImage={uploadImage}
          />
          <Button
            variant="contained"
            color="primary"
            fullWidth
            style={{ marginTop: "15px", height: 38 }}
            disabled={loading}
            onClick={handleUpdate}
          >
            {loading ? (
              <CircularProgress
                className={classes.circularProgress}
                color="inherit"
                size={16}
              />
            ) : (
              "save round"
            )}
          </Button>
          {questionsError && <p className={classes.error}>{questionsError}</p>}
        </>
      ) : (
        <></>
      )}

      <CongratulationModal
        open={openCongratulationModal}
        handleClose={closeCongratulationModal}
      >
        Round <b>{roundName}</b> successfully updated!
        <br />
        Now your hosts can use this round in their games!
      </CongratulationModal>
    </>
  );
};

export default EditRound;
