import React from "react";

import {
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { getSubscriptionPrice } from "../../../../helpers/getSubscriptionPrice";

const useStyles = makeStyles({
  table: {
    maxWidth: 650,
    margin: "30px auto 10px",
    "& h4": {
      fontSize: 16,
    },
  },
  editButtons: {
    width: 130,
  },
  quantity: {
    position: "relative",
    right: 10,
  },
  error: {
    display: "flex",
    justifyContent: "center",
    marginBottom: 30,
    fontSize: 16,
    color: "red",
  },
});

const UpdateSubscriptionTable = ({
  error,
  subscriptionQuantity,
  newSubscriptionQuantity,
  subscriptionFee,
  increase,
  decrease,
}) => {
  const classes = useStyles();
  return (
    <>
      <TableContainer className={classes.table} component={Paper}>
        <Table aria-label="simple table">
          <TableBody>
            <TableRow>
              <TableCell align="center" component="th" scope="row">
                <h4>Current hosts quantity</h4>
              </TableCell>
              <TableCell className={classes.quantity} align="right">
                <b>{subscriptionQuantity}</b>
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center" component="th" scope="row">
                <h4>New hosts quantity</h4>
              </TableCell>
              <TableCell className={classes.quantity} align="right">
                <b>{newSubscriptionQuantity}</b>
              </TableCell>
              <TableCell align="right" className={classes.editButtons}>
                <IconButton onClick={increase} color="primary">
                  <AddIcon />
                </IconButton>
                <IconButton onClick={decrease} color="secondary">
                  <RemoveIcon />
                </IconButton>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center" component="th" scope="row">
                <h4>Monthly price</h4>
              </TableCell>
              <TableCell align="right">
                <b> {getSubscriptionPrice(subscriptionQuantity)}$</b>
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center" component="th" scope="row">
                <h4>Additional fee</h4>
              </TableCell>
              <TableCell align="right">
                <b>{subscriptionFee}$</b>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      {error && <div className={classes.error}>{error} </div>}
    </>
  );
};

export default UpdateSubscriptionTable;
