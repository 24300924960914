import React from "react";

import { CircularProgress, makeStyles } from "@material-ui/core";

import OftadehLayout from "../OftadehLayout/OftadehLayout";

const useStyles = makeStyles({
  circularProgress: {
    color: "#3f51b5",
    display: "flex",
    justifyContent: "center",
    height: "80vh",
    alignItems: "center",
  },
});

const PagePreloader = () => {
  const classes = useStyles();
  return (
    <OftadehLayout>
      <div className={classes.circularProgress}>
        <CircularProgress color="inherit" size={140} />
      </div>
    </OftadehLayout>
  );
};

export default PagePreloader;
