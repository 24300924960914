import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import dateFormat, { masks } from "dateformat";

const useStyles = makeStyles({
	table: {
		".upper": {
			textTransform: "uppercase",
			fontWeight: "bold",
		},
	},
});

const GameTable = ({ roundList }) => {
	const classes = useStyles();
	const history = useHistory();

	return (
		<TableContainer>
			<Table
				className={classes.table}
				style={{ tableLayout: "fixed" }}
				aria-label="simple table"
			>
				<TableHead>
					<TableRow>
						<TableCell>User</TableCell>
						<TableCell>Team</TableCell>

						<TableCell>Total Score</TableCell>
						<TableCell>Venue(s)</TableCell>
						{/* <TableCell>Game Date</TableCell> */}
					</TableRow>
				</TableHead>
				<TableBody>
					{roundList.map((game, gameIndex) => (
						<TableRow key={gameIndex}>
							<TableCell>
								<Button onClick={() => history.push(`/users/${game.user._id}`)}>
									{game.user.email}
								</Button>
							</TableCell>
							<TableCell className="upper">{game.team}</TableCell>

							<TableCell>{game.scores}</TableCell>
							<TableCell>{game.roomCodes}</TableCell>
							{/* <TableCell>{game.gameDates[0]}</TableCell> */}
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
};

export default GameTable;
