import React from "react";
import {
	makeStyles,
	Typography,
	Button,
	TextField,
	CircularProgress,
	Box,
} from "@material-ui/core";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useStore } from "../../../stores/helpers/useStore";

import eduStyles from "../eduStyles";
import standardStyles from "../standardStyles";

import AuthWrapper from "../AuthWrapper";

const initialValues = {
	email: "",
	password: "",
};

const loginationSchema = Yup.object().shape({
	email: Yup.string()
		.trim()
		.required("Email is required")
		.email("Email must be valid"),
	password: Yup.string()
		.trim()
		.min(6, "Minimum 6 symbols")
		.max(20, "Maximum 20 symbols")
		.matches(
			/^(?=.*[a-zA-Z])(?=.*[0-9]).+$/,
			"Should have at least one number and letter"
		)
		.required("Password is required"),
});

const LoginPage = (props) => {
	return (
		<AuthWrapper>
			<LoginComponent {...props} />
		</AuthWrapper>
	);
};

const LoginComponent = (props) => {
	const { authStore } = useStore();
	//const classes = useStyles();
	const { history } = props;

	const subdomain = window.location.host.split(".")[0];
	const isEducation = subdomain === "edu";

	const classes = isEducation ? eduStyles() : standardStyles();

	const formik = useFormik({
		initialValues,
		validationSchema: loginationSchema,
		onSubmit: async (values, { setFieldError, setSubmitting }) => {
			setSubmitting(true);
			try {
				await authStore.login(values.email, values.password);
			} catch (err) {
				setSubmitting(false);
				if (err?.response?.status === 409) {
					setFieldError(
						err?.response?.data?.param,
						err?.response?.data?.message
					);
				} else if (err?.response?.status === 406) {
					setFieldError("email", "No user with such an email.");
				} else if (err?.response?.status === 403) {
					setFieldError("password", "Wrong credentials.");
				}
			}
		},
	});

	return (
		<div className={classes.loginCard}>
			<Typography variant="h5" component="h1" className={classes.textColor}>
				Login
			</Typography>
			<Typography className={classes.mBottom} variant="body1">
				Sign In to your account
			</Typography>
			<form noValidate autoComplete="off" onSubmit={formik.handleSubmit}>
				<TextField
					size="small"
					label="Email"
					name="email"
					variant="outlined"
					margin="dense"
					value={formik.values.email}
					onChange={formik.handleChange}
					onBlur={formik.handleBlur}
					error={!!formik.touched.email && !!formik.errors.email}
					helperText={formik.touched.email && formik.errors.email}
					fullWidth
				/>
				<TextField
					size="small"
					label="Password"
					type="password"
					name="password"
					variant="outlined"
					margin="dense"
					value={formik.values.password}
					onChange={formik.handleChange}
					onBlur={formik.handleBlur}
					error={!!formik.touched.password && !!formik.errors.password}
					helperText={formik.touched.password && formik.errors.password}
					fullWidth
				/>
				<Button
					onClick={() => history.push("/pages/auth/forgot-password")}
					color="primary"
				>
					Forgot password?
				</Button>
				<div className={classes.mBottom}>
					<Button
						type="submit"
						variant="contained"
						color="primary"
						fullWidth
						className={classes.button}
						onClick={formik.handleSubmit}
						disabled={formik.isSubmitting}
					>
						{formik.isSubmitting ? (
							<>
								Please wait{" "}
								<CircularProgress
									className={classes.circularProgress}
									color="inherit"
									size={16}
								/>
							</>
						) : (
							<> Login</>
						)}
					</Button>
					<Button
						variant="outlined"
						color="primary"
						fullWidth
						className={classes.button}
						onClick={() => history.push("/pages/auth/register")}
					>
						Register Now!
					</Button>
				</div>
			</form>
		</div>
	);
};

export default LoginPage;
