const constants = {
	enterprise: {
		title: "Enterprise",
		hosts: "Hosts",
		rooms: "Rooms",
		brand_title: "Trivia Brand Setup",
		brand_description:
			"This is where you can define your brand.  Your title and link will appaer on the users device when they enter your room.",
		welcomeMessage: "Welcome to TriviaRat Enterprise!",

		nav_title: "TR Admin",
		hosts: "Hosts",
		host: "Host",
		rooms: "Rooms",
		my_brand: "My Brand",
		users_and_teams: "Users & Teams",
		users: "Users",
		players: "Players",

		input_brand_title: "Trivia Brand Name",
	},
	edu: {
		title: "Education",
		subtitle: "Rapid Assessment Tool for Educators",
		hosts: "Teachers",
		rooms: "Classes",
		brand_title: "School Setup",
		brand_description:
			"This step is optional. If you would like to add a title and/or URL for your school, you can do it here.  Students will see this on their device when they join your room.",
		welcomeMessage: "Welcome to the TR Education Portal!",

		nav_title: "TR Education",
		hosts: "Teachers",
		host: "Teacher",
		rooms: "Rooms",
		my_brand: "School",
		users_and_teams: "Students / Teams",
		users: "Students",
		players: "Players",

		input_brand_title: "Display Name (School name  etc.)",
	},

	// ... add more subdomains if necessary
};

// Function to get the subdomain
const getSubdomain = () => {
	if (typeof window !== "undefined") {
		const hostname = window.location.hostname;
		const hostnameParts = hostname.split(".");
		if (hostnameParts.length > 2) {
			return hostnameParts[0]; // Assuming subdomain is always the first part
		}
	}
	return null;
};

const domain = getSubdomain();
// Get constants for the current subdomain
const currentConstants = constants[domain] || constants.enterprise;

currentConstants.domain = domain;
currentConstants.isEducation = domain === "edu";
export default currentConstants;
