import React from "react";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import { makeStyles } from "@material-ui/core/styles";

import { getNavigationConfig } from "../../oftadeh-configs/navigationConfig";

import OftadehNavGroup from "./sections/OftadehNavGroup";
import OftadehNavCollapse from "./sections/OftadehNavCollapse";
import OftadehNavItem from "./sections/OftadehNavItem";
import OftadehNavLink from "./sections/OftadehNavLink";
import { Typography } from "@material-ui/core";
import clsx from "clsx";
import { useStore } from "../../stores/helpers/useStore";

const useStyles = makeStyles((theme) => ({
	toolbar: theme.mixins.toolbar,
	logoBg: {
		backgroundColor: theme.palette.type !== "dark" && "#18202c",
		// backgroundColor: "#18202c"
	},
	logo: {
		padding: "1rem",
		"& span": {
			display: "block",
			color: "rgba(41, 113, 245, 0.87)",
		},
	},
	viga: {
		fontFamily: "Viga",
		padding: "1rem",
		"& span": {
			display: "block",
		},
	},
	navCustom: {
		"& .MuiTypography-root": {
			fontSize: ".85rem",
		},
		"& .MuiListItemIcon-root": {
			minWidth: "35px",
		},
		"& .MuiCollapse-wrapperInner a": {
			paddingLeft: "50px",
		},
	},
}));

const OftadehNavigation = (props) => {
	const classes = useStyles(props);

	const {
		settingsStore: { playerSettings },
	} = useStore();

	const navigationList = getNavigationConfig(playerSettings.requirePlayerEmail);

	return (
		<div>
			<div className={clsx(classes.toolbar, classes.logoBg)}>
				<Typography
					className={classes.viga}
					variant="h6"
					component="h1"
					align="center"
				>
					<span>{window.labs.nav_title}</span>
				</Typography>
			</div>
			<Divider />
			<List className={classes.navCustom}>
				{navigationList.map((item, navIndex) => (
					<React.Fragment key={navIndex + "-nav"}>
						{item.type === "group" && <OftadehNavGroup item={item} />}

						{item.type === "collapse" && <OftadehNavCollapse item={item} />}

						{item.type === "item" && <OftadehNavItem item={item} />}

						{item.type === "link" && <OftadehNavLink item={item} />}

						{item.type === "divider" && <Divider className="my-16" />}
					</React.Fragment>
				))}
			</List>
		</div>
	);
};

export default OftadehNavigation;
