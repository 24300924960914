import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles({
	table: {
		".upper": {
			textTransform: "uppercase",
			fontWeight: "bold",
		},
	},
});

const GameResultsTable = ({ gameList }) => {
	const classes = useStyles();
	const history = useHistory();
	return (
		<TableContainer>
			<Table
				className={classes.table}
				style={{ tableLayout: "fixed" }}
				aria-label="simple table"
			>
				<TableHead>
					<TableRow>
						<TableCell>Game</TableCell>
						<TableCell>Venue</TableCell>
						<TableCell>Email</TableCell>
						<TableCell>Player Name</TableCell>
						<TableCell>Score</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{gameList.map((game, gameIndex) => (
						<TableRow key={gameIndex}>
							<TableCell>{new Date(game.gameDate).toLocaleDateString("en-US")}</TableCell>
							<TableCell className="upper">{game.roomName}</TableCell>
							<TableCell>
								<Button onClick={() => history.push(`/game-results/${game.id}/${game.userId}`)}>
									{game.email}
								</Button>
							</TableCell>
							<TableCell className="upper">{game.team}</TableCell>
							<TableCell>{game.score}</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
};

export default GameResultsTable;
