import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { CircularProgress, makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  backButton: {
    display: "flex",
    justifyContent: "center",
  },
  circularProgress: {
    marginLeft: "10px",
  },
  alignCenter: {
    textAlign: "center",
  },
  content: {
    minWidth: 500,
  },
});

const ConfirmationModal = ({
  open,
  title,
  children,
  loading,
  handleClose,
  confirm,
}) => {
  const classes = useStyles();

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle className={classes.alignCenter} id="alert-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent className={classes.content}>
          <DialogContentText
            className={classes.alignCenter}
            id="alert-dialog-description"
          >
            {children}
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.backButton}>
          <Button
            onClick={handleClose}
            variant="contained"
            color="default"
            className={classes.button}
            disabled={loading}
          >
            Cancel
          </Button>
          <Button
            onClick={confirm}
            variant="contained"
            color="primary"
            className={classes.button}
            disabled={loading}
          >
            {loading ? (
              <>
                Please wait
                <CircularProgress
                  className={classes.circularProgress}
                  color="inherit"
                  size={16}
                />
              </>
            ) : (
              <> Confirm</>
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default ConfirmationModal;
