import React from "react";
import { Redirect, Route } from "react-router";

const PublicRoute = ({ key, auth, verified, ...route }) => {
  if (!auth) {
    // console.log("%c GO TO PUBLIC ROUTE", "font-size:30px;color:yellow;");
    return <Route key={key} {...route} />;
  } else if (auth && verified) {
    // console.log("%c REDIRECT TO HOME", "font-size:30px;color:red;");
    return <Redirect to="/hosts" />;
  } else if (auth && !verified) {
    // console.log(
    //   "%c REDIRECT TO VERIFICATION PAGE",
    //   "font-size:30px;color:red;"
    // );
    return <Redirect to="/pages/auth/verification" />;
  }
};

export default PublicRoute;
