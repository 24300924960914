import React, { useEffect, useState } from "react";

import { Button, makeStyles } from "@material-ui/core";

import { useHistory } from "react-router-dom";

import UpdateSubscriptionTable from "./UpdateSubscriptionTable";

import IncreaseSubscriptionModal from "../../../../components/Modals/IncreaseSubscriptionModal/IncreaseSubscriptionModal";
import DecreaseSubscriptionModal from "../../../../components/Modals/DecreaseSubscriptionModal/DecreaseSubscriptionModal";
import ApproveModal from "../../../../components/Modals/ApproveModal/ApproveModal";
import SubscriptionCreatingErrorModal from "../../../../components/Modals/SubscriptionCreatingErrorModal/SubscriptionCreatingErrorModal";
import ErrorModal from "../../../../components/Modals/ErrorModal/ErrorModal";

const useStyles = makeStyles({
  button: {
    maxWidth: 220,
    display: "flex",
    margin: "30px auto",
  },
  backButtonOuter: {
    display: "flex",
    justifyContent: "center",
  },
  backButton: {
    marginTop: 100,
  },
});

const UpdateSubscription = ({
  subscriptionQuantity,
  nextCycleQuantity,
  email,
  existingHostsQuantity,
  updateAdminSubscription,
  getPrice,
  back,
  goToHostsList,
}) => {
  const [newSubscriptionQuantity, setNewSubscriptionQuantity] = useState(
    +subscriptionQuantity
  );
  const [subscriptionFee, setSubscriptionFee] = useState(0);
  const [openIncSubModal, setOpenIncSubModal] = useState(false);
  const [openDecSubModal, setOpenDecSubModal] = useState(false);
  const [openApproveModal, setOpenApproveModal] = useState(false);
  const [openSubscriptionErrorModal, setOpenSubscriptionErrorModal] =
    useState(false);
  const [loading, setLoading] = useState(false);
  const [approved, setApproved] = useState(false);
  const [approveLink, setApproveLink] = useState("");
  const [quantityError, setQuantityError] = useState("");
  const [subscriptionUpdatingError, setSubscriptionUpdatingError] =
    useState("");

  const history = useHistory();

  const classes = useStyles();

  const decreaseHostsQuantity = () => {
    if (newSubscriptionQuantity <= 3) {
      setQuantityError("Minimum hosts quantity is: 3");
      return;
    }
    setQuantityError("");
    setNewSubscriptionQuantity(newSubscriptionQuantity - 1);
  };

  const increaseHostsQuantity = () => {
    setNewSubscriptionQuantity(newSubscriptionQuantity + 1);
    setQuantityError("");
  };

  const handleClose = () => {
    if (loading) return;
    setOpenIncSubModal(false);
    setOpenDecSubModal(false);
    setOpenApproveModal(false);
    setSubscriptionUpdatingError("");
    setOpenSubscriptionErrorModal(false);
  };

  const createOrder = (data, actions) => {
    return actions.order.create({
      purchase_units: [
        {
          amount: {
            currency_code: "USD",
            value: subscriptionFee,
          },
          custom_id: email,
        },
      ],
    });
  };

  const paypalOnError = (err) => {
    setOpenSubscriptionErrorModal(true);
  };

  const paypalOnApprove = (data, detail) => {
    // call the backend api to store transaction details
    setApproved(true);
    updateSubscription(data.orderID, newSubscriptionQuantity);
  };

  const updateSubscription = async (orderID, quantity) => {
    try {
      setLoading(true);
      const response = await updateAdminSubscription(orderID, quantity);

      setOpenIncSubModal(false);
      setOpenDecSubModal(false);
      setOpenApproveModal(true);
      setApproveLink(response.link);
    } catch (err) {
      setSubscriptionUpdatingError(
        err.response?.data?.message || "Updating failed, try again later!"
      );
    } finally {
      setLoading(false);
    }
  };

  const openUpdateSubscriptionModal = () => {
    if (newSubscriptionQuantity > subscriptionQuantity) {
      setOpenIncSubModal(true);
    } else if (newSubscriptionQuantity < subscriptionQuantity) {
      setOpenDecSubModal(true);
    }
  };

  useEffect(() => {
    if (newSubscriptionQuantity > subscriptionQuantity) {
      let calculatedFee =
        getPrice(newSubscriptionQuantity) - getPrice(subscriptionQuantity);
      setSubscriptionFee(calculatedFee);
      return;
    }
    setSubscriptionFee(0);
  }, [subscriptionQuantity, newSubscriptionQuantity, getPrice]);

  useEffect(() => {
    if (newSubscriptionQuantity < existingHostsQuantity) {
      setQuantityError(
        `You need to delete ${
          existingHostsQuantity - newSubscriptionQuantity
        } host(s).`
      );
    }
  }, [existingHostsQuantity, newSubscriptionQuantity]);
  return (
    <>
      <h1>Update Subscription</h1>

      <UpdateSubscriptionTable
        error={quantityError}
        subscriptionQuantity={subscriptionQuantity}
        newSubscriptionQuantity={newSubscriptionQuantity}
        subscriptionFee={subscriptionFee}
        decrease={decreaseHostsQuantity}
        increase={increaseHostsQuantity}
      />

      {+subscriptionQuantity !== newSubscriptionQuantity &&
        +nextCycleQuantity !== newSubscriptionQuantity &&
        newSubscriptionQuantity >= existingHostsQuantity && (
          <Button
            variant="contained"
            onClick={openUpdateSubscriptionModal}
            color="primary"
            className={classes.button}
          >
            Update subscription
          </Button>
        )}

      {newSubscriptionQuantity < existingHostsQuantity && (
        <Button
          variant="contained"
          onClick={goToHostsList}
          color="primary"
          className={classes.button}
        >
          Go to hosts list
        </Button>
      )}

      <div className={classes.backButtonOuter}>
        <Button
          className={classes.backButton}
          variant="contained"
          color="default"
          onClick={back}
        >
          Go back
        </Button>
      </div>

      <IncreaseSubscriptionModal
        open={openIncSubModal}
        hostsQuantity={newSubscriptionQuantity}
        fee={subscriptionFee}
        loading={loading}
        approved={approved}
        price={getPrice(newSubscriptionQuantity)}
        createOrder={createOrder}
        handleClose={handleClose}
        // onCancel={paypalOnCancel}
        onError={paypalOnError}
        catchError={paypalOnError}
        onApprove={paypalOnApprove}
      />

      <DecreaseSubscriptionModal
        open={openDecSubModal}
        hostsQuantity={newSubscriptionQuantity}
        loading={loading}
        update={() => updateSubscription(null, newSubscriptionQuantity)}
        price={getPrice(newSubscriptionQuantity)}
        handleClose={handleClose}
      />

      <ApproveModal
        open={openApproveModal}
        approve={() => history.push(approveLink)}
        link={approveLink}
      />

      <SubscriptionCreatingErrorModal
        open={openSubscriptionErrorModal}
        handleClose={handleClose}
      />

      <ErrorModal
        open={!!subscriptionUpdatingError}
        title="Updating of subscription failed!"
        errorText={subscriptionUpdatingError}
        handleClose={handleClose}
      />
    </>
  );
};

export default UpdateSubscription;
