import SubscriptionApprovedPage from "./SubscriptionApprovedPage";
import SubscriptionPage from "./SubscriptionPage";

export const SubscriptionPageConfig = {
  routes: [
    {
      path: "/subscription",
      exact: true,
      component: SubscriptionPage,
    },
    {
      path: "/subscription/approved",
      exact: true,
      component: SubscriptionApprovedPage,
    },
  ],
};
