import ContactPage from "./ContactPage";

export const ContactPageConfig = {
  routes: [
    {
      path: "/contact",
      exact: true,
      component: ContactPage,
    },
  ],
};
