import React, { useEffect, useState } from "react";

import { Button, makeStyles } from "@material-ui/core";

import { useFormik } from "formik";
import * as Yup from "yup";

import SubscriptionDetails from "./SubscriptionDetails";
import PricingTable from "./PricingTable";
import PriceCalculator from "./PriceCalculator";
import SubscriptionDescription from "./SubscriptionDescription";
import CancelSubscriptionModal from "../../../../components/Modals/CancelSubscriptionModal/CancelSubscriptionModal";

const useStyles = makeStyles({
  button: {
    maxWidth: 220,
    display: "flex",
    margin: "30px auto",
  },
});

const initialValues = {
  calculateHosts: 0,
};

const validationSchema = Yup.object().shape({
  hostsQuantity: Yup.number("Must be a number").test(
    "Min",
    "Minimum number is: 3",
    (num) => num >= 3 && num !== 0
  ),
  calculateHosts: Yup.number("Must be a number").test(
    "Min",
    "Minimum number is: 3",
    (num) => num >= 3
  ),
});

const ViewSubscription = ({
  subscriptionQuantity,
  nextCycleQuantity,
  subscriptionEnd,
  subscriptionStatus,
  subscriptionTiers,
  createSubscription,
  updateSubscription,
  cancelAdminSubscription,
  subscriptionApprovalLink,
  getPrice,
}) => {
  const [expectedPrice, setExpectedPrice] = useState(0);
  const [openCancelSubModal, setOpenCancelSubModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [subscriptionCanceled, setSubscriptionCanceled] = useState(false);
  const [reason, setReason] = useState("");

  const classes = useStyles();

  const formik = useFormik({
    initialValues,
    validationSchema,
  });

  const cancelSubscription = async () => {
    try {
      setLoading(true);
      await cancelAdminSubscription(reason);
      setLoading(false);
      setSubscriptionCanceled(true);
    } catch {
      setLoading(false);
      setOpenCancelSubModal(false);
    }
  };

  // calculating expected price of subscription based on user input
  useEffect(() => {
    // if (formik.errors.calculateHosts) {
    //   return;
    // }
    setExpectedPrice(getPrice(formik.values.calculateHosts));
  }, [
    // formik.errors.calculateHosts,
    formik.values.calculateHosts,
    getPrice,
  ]);

  return (
    <>
      <h1>Subscription Info</h1>
      {subscriptionStatus && subscriptionStatus === "INACTIVE" && (
        <Button
          variant="contained"
          color="primary"
          fullWidth
          className={classes.button}
          onClick={createSubscription}
        >
          Create subscription
        </Button>
      )}
      {subscriptionStatus && subscriptionStatus !== "INACTIVE" && (
        <>
          <SubscriptionDetails
            subscriptionQuantity={subscriptionQuantity}
            subscriptionEnd={subscriptionEnd}
            nextCycleQuantity={nextCycleQuantity}
            subscriptionStatus={subscriptionStatus}
            getPrice={getPrice}
          />
          {subscriptionApprovalLink && (
            <Button
              href={subscriptionApprovalLink}
              variant="contained"
              color="primary"
              className={classes.button}
            >
              Approve subscription
            </Button>
          )}
          {subscriptionStatus !== "CANCELED" && !subscriptionApprovalLink && (
            <Button
              variant="contained"
              color="primary"
              fullWidth
              className={classes.button}
              onClick={updateSubscription}
            >
              Update subscription
            </Button>
          )}
        </>
      )}

      <PricingTable subscriptionTiers={subscriptionTiers} />

      <PriceCalculator
        expectedPrice={expectedPrice}
        value={formik.values.calculateHosts}
        error={!!formik.errors.calculateHosts}
        helperText={formik.errors.calculateHosts}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
      />

      <SubscriptionDescription />

      {subscriptionStatus === "ACTIVE" && (
        <Button
          variant="contained"
          color="default"
          fullWidth
          className={classes.button}
          onClick={() => setOpenCancelSubModal(true)}
        >
          Cancel subscription
        </Button>
      )}

      <CancelSubscriptionModal
        open={openCancelSubModal}
        reason={reason}
        loading={loading}
        subscriptionCanceled={subscriptionCanceled}
        setReason={setReason}
        cancel={cancelSubscription}
        handleClose={() => setOpenCancelSubModal(false)}
      />
    </>
  );
};

export default ViewSubscription;
