import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  backButton: {
    display: "flex",
    justifyContent: "center",
  },
  alignCenter: {
    textAlign: "center",
  },
  content: {
    minWidth: 500,
  },
});

const TipModal = ({ open, children, loading, handleClose }) => {
  const classes = useStyles();

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent className={classes.content}>
          <DialogContentText
            className={classes.alignCenter}
            id="alert-dialog-description"
          >
            {children}
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.backButton}>
          <Button
            onClick={handleClose}
            variant="contained"
            color="default"
            className={classes.button}
            disabled={loading}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default TipModal;
