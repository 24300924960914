import React, { useCallback, useEffect, useState } from "react";
import {
  makeStyles,
  Typography,
  Button,
  TextField,
  CircularProgress,
} from "@material-ui/core";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useStore } from "../../../stores/helpers/useStore";
import { useParams } from "react-router";

const initialValues = {
  password: "",
  confirmPassword: "",
};

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .trim()
    .min(6, "Minimum 6 symbols")
    .max(20, "Maximum 20 symbols")
    .matches(
      /^(?=.*[a-zA-Z])(?=.*[0-9]).+$/,
      "Should have at least one number and letter"
    )
    .required("Password is required"),
  confirmPassword: Yup.string()
    .trim()
    .required("Password confirmation is required")
    .when("password", {
      is: (val) => !!(val && val.length > 0),
      then: Yup.string().oneOf(
        [Yup.ref("password")],
        "Password and Confirm Password didn't match"
      ),
    }),
});

const useStyles = makeStyles((theme) => ({
  root: {
    background: "#0d131d",
    width: "100vw",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
  },
  validating: {
    color: "#fff",
  },
  mBottom: {
    marginBottom: ".5rem",
  },
  button: {
    marginTop: ".85rem",
  },
  progress: {
    marginTop: "1rem",
  },
  loginCard: {
    width: "275px",
    borderRadius: 5,
    background: "#fff",
    padding: ".85rem",
  },
  errorBlock: {
    maxWidth: "400px",
    color: "#fff",
  },
  resetBtn: {
    marginTop: ".85rem",
  },
}));

const ResetPassword = (props) => {
  const [verifyingToken, setVerifyingToken] = useState(true);
  const [validToken, setValidToken] = useState(false);
  const [email, setEmail] = useState("");
  const {
    authStore: { validateResetToken, resetPassword },
  } = useStore();
  const { resetToken } = useParams();
  const classes = useStyles();
  const { history } = props;

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, { setFieldError, setSubmitting }) => {
      setSubmitting(true);
      try {
        await resetPassword(values.password, resetToken);
      } catch (err) {
        if (err?.response?.status === 409) {
          setFieldError(
            err?.response?.data?.param,
            err?.response?.data?.message
          );
        }
      }
    },
  });

  const validate = useCallback(
    async (resetToken) => {
      try {
        const result = await validateResetToken(resetToken);
        setEmail(result.email);
        setValidToken(true);
        setVerifyingToken(false);
      } catch {
        setVerifyingToken(false);
      }
    },
    [validateResetToken]
  );

  useEffect(() => {
    validate(resetToken);
  }, [resetToken, validate]);

  return (
    <div className={classes.root}>
      {verifyingToken && (
        <div className={classes.validating}>
          <Typography variant="h3" gutterBottom>
            Checking for permission!
          </Typography>
          <Typography variant="body1">Please wait.</Typography>
          <CircularProgress className={classes.progress} />
        </div>
      )}
      {validToken && !verifyingToken && (
        <div className={classes.loginCard}>
          <Typography variant="h5" component="h1">
            Reset Password
          </Typography>

          <Typography variant="body1">
            {" "}
            for: <b>{email} </b>
          </Typography>
          <form noValidate autoComplete="off" onSubmit={formik.handleSubmit}>
            <TextField
              size="small"
              label="Password"
              type="password"
              name="password"
              variant="outlined"
              margin="dense"
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={!!formik.touched.password && !!formik.errors.password}
              helperText={formik.touched.password && formik.errors.password}
              fullWidth
            />
            <TextField
              size="small"
              label="Repeat password"
              type="password"
              name="confirmPassword"
              variant="outlined"
              margin="dense"
              value={formik.values.confirmPassword}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                !!formik.touched.confirmPassword &&
                !!formik.errors.confirmPassword
              }
              helperText={
                formik.touched.confirmPassword && formik.errors.confirmPassword
              }
              fullWidth
            />
            <div className={classes.mBottom}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                className={classes.button}
                onClick={formik.handleSubmit}
                disabled={formik.isSubmitting}
              >
                {formik.isSubmitting ? (
                  <>
                    Please wait{" "}
                    <CircularProgress
                      className={classes.circularProgress}
                      color="inherit"
                      size={16}
                    />
                  </>
                ) : (
                  <> Reset</>
                )}
              </Button>
              <Button
                variant="outlined"
                color="primary"
                fullWidth
                className={classes.button}
                onClick={() => history.push("/pages/auth/login")}
              >
                Login Now!
              </Button>
            </div>
          </form>
        </div>
      )}

      {!verifyingToken && !validToken && (
        <div className={classes.errorBlock}>
          <Typography variant="h3" gutterBottom>
            Something went wrong!
          </Typography>
          <Button
            onClick={() => history.push("/pages/auth/forgot-password")}
            variant="contained"
            color="primary"
            className={classes.resetBtn}
            fullWidth
          >
            Reset password
          </Button>
          <Button
            variant="text"
            color="primary"
            fullWidth
            className={classes.button}
            onClick={() => history.push("/pages/auth/login")}
          >
            Login Now
          </Button>
        </div>
      )}
    </div>
  );
};

export default ResetPassword;
