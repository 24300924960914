import React, { useEffect, useState } from "react";
import {
	Paper,
	Button,
	Grid,
	Card,
	Box,
	TextField,
	makeStyles,
	CircularProgress,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

import { useFormik } from "formik";
import * as Yup from "yup";

import OftadehLayout from "../../components/OftadehLayout/OftadehLayout";

import { useStore } from "../../stores/helpers/useStore";
import { useIsMounted } from "../../helpers/useIsMounted";
import { observer } from "mobx-react-lite";
import { Typography } from "@material-ui/core";

import labels from "../../utils/labels";

const validationSchema = Yup.object().shape({
	customTitle: Yup.string().trim(),
	customUrl: Yup.string()
		.trim()
		.when("customUrlTitle", {
			is: (val) => !!val?.length,
			then: Yup.string().required("Please, add Custom URL!"),
		})
		.url("Invalid url!"),
	customUrlTitle: Yup.string().trim(),
});

const useStyles = makeStyles((theme) => ({
	formInputs: {
		width: 600,
	},
	circularProgress: {
		marginLeft: 10,
	},
	submitBtn: {
		width: 150,
	},
	error: {
		color: "red",
		marginTop: ".85rem",
		textAlign: "center",
	},
}));

const BrandPage = () => {
	const [savingError, setSavingError] = useState("");

	const classes = useStyles();
	const isMounted = useIsMounted();
	const {
		settingsStore: {
			customBranding: { customTitle, customUrl, customUrlTitle },
			getCustomBrandingSettings,
			editCustomBrandingSettings,
		},
		uiStore: { loading },
	} = useStore();

	const formik = useFormik({
		initialValues: {
			customTitle,
			customUrl,
			customUrlTitle,
		},
		validationSchema,
		enableReinitialize: true,
		onSubmit: async (values) => {
			if (JSON.stringify(values) === JSON.stringify(formik.initialValues))
				return;

			try {
				await editCustomBrandingSettings(values);
			} catch {
				setSavingError("Saving failed, please try again!");
			}
		},
	});

	useEffect(() => {
		setSavingError("");
	}, [formik.values]);

	useEffect(() => {
		getCustomBrandingSettings();
	}, [getCustomBrandingSettings]);
	return (
		<OftadehLayout loading={loading || !isMounted}>
			<h1>{labels.brand_title}</h1>
			<Card style={{ marginBottom: "12px" }}>
				<Grid item>
					<Alert severity="info">{labels.brand_description}</Alert>
				</Grid>
			</Card>

			<Paper style={{ padding: "15px" }}>
				<Box m={3.5} display="flex" justifyContent="center">
					<form className={classes.formInputs} onSubmit={formik.handleSubmit}>
						<Grid container direction={"column"} spacing={5}>
							<Grid item>
								<TextField
									label={window.labs.input_brand_title}
									name="customTitle"
									type="text"
									fullWidth
									disabled={formik.isSubmitting}
									value={formik.values.customTitle || ""}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									error={
										!!formik.touched.customTitle && !!formik.errors.customTitle
									}
									helperText={
										formik.touched.customTitle && formik.errors.customTitle
									}
								/>
							</Grid>

							<Grid item>
								<TextField
									label="Custom URL"
									name="customUrl"
									type="text"
									fullWidth
									disabled={formik.isSubmitting}
									value={formik.values.customUrl || ""}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									error={
										!!formik.touched.customUrl && !!formik.errors.customUrl
									}
									helperText={
										formik.touched.customUrl && formik.errors.customUrl
									}
								/>
							</Grid>

							<Grid item>
								<TextField
									label="Custom URL Label"
									name="customUrlTitle"
									type="text"
									fullWidth
									disabled={formik.isSubmitting}
									value={formik.values.customUrlTitle || ""}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									error={
										!!formik.touched.customUrlTitle &&
										!!formik.errors.customUrlTitle
									}
									helperText={
										formik.touched.customUrlTitle &&
										formik.errors.customUrlTitle
									}
								/>
							</Grid>
						</Grid>

						{savingError && (
							<Typography className={classes.error} variant="body1">
								{savingError}
							</Typography>
						)}
					</form>
				</Box>

				<Box display="flex" justifyContent="center" m={3.5}>
					<Button
						variant="contained"
						color="primary"
						onClick={formik.handleSubmit}
						disabled={
							formik.isSubmitting ||
							JSON.stringify(formik.values) ===
								JSON.stringify(formik.initialValues)
						}
						className={classes.submitBtn}
					>
						{formik.isSubmitting ? (
							<>
								Please wait{" "}
								<CircularProgress
									className={classes.circularProgress}
									color="inherit"
									size={16}
								/>
							</>
						) : (
							<> Save</>
						)}
					</Button>
				</Box>
			</Paper>
		</OftadehLayout>
	);
};

export default observer(BrandPage);
