import React from "react";
import {
  Button,
  Icon,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { SortableContainer, SortableElement } from "react-sortable-hoc";

const useStyles = makeStyles({
  table: {
    "& th,td": {
      textAlign: "center",
    },
    "& tbody": {
      textTransform: "uppercase",
    },
    "& tr": {
      "& th:first-child,td:first-child": {
        textAlign: "left",
        width: 50,
      },
    },
  },
  error: {
    display: "flex",
    justifyContent: "center",
    marginTop: 30,
    fontSize: 16,
    color: "red",
    paddingBottom: 30,
  },
  test: {
    backgroundColor: "white",
    opacity: 0.8,
    width: "auto",
    border: "1px solid #efefef",
    boxShadow: "0 5px 5px -5px rgba(0, 0, 0, 0.2)",
    borderRadius: 3,
    textTransform: "uppercase",
  },
  row: {
    "&:hover": {
      cursor: "grab",
    },
  },
});

const WagersTable = ({
  schemes,
  deletingError,
  updateSchemesOrder,
  handleDeleteWagerScheme,
  clearTimeout,
  editScheme,
}) => {
  const classes = useStyles();

  const onSortEnd = ({ oldIndex, newIndex }) => {
    document.body.style.cursor = "default";
    updateSchemesOrder(oldIndex, newIndex);
  };

  const onSortStart = ({ node, helper }) => {
    clearTimeout();
    document.body.style.cursor = "grabbing";
    node.childNodes.forEach((td, index) => {
      helper.childNodes[index].style.width = `${td.offsetWidth}px`;
      helper.childNodes[index].style.height = `${td.offsetHeight}px`;
      helper.childNodes[index].style.padding = "16px";
      helper.childNodes[index].style.textAlign = "center";
    });
  };

  const cancelDragging = (e) => {
    if (["button", "span"].indexOf(e.target.tagName.toLowerCase()) !== -1) {
      return true;
    }
  };

  const SortableList = SortableContainer(({ children }) => (
    <TableBody>{children}</TableBody>
  ));

  const SortableItem = SortableElement(({ scheme }) => (
    <TableRow key={scheme.id} className={classes.row}>
      <TableCell>{scheme.order}</TableCell>
      <TableCell>{scheme.name}</TableCell>
      <TableCell>
        {scheme.type === "points" ? scheme.points.join(", ") : " - "}
      </TableCell>
      <TableCell>
        {scheme.type === "percentage" ? scheme.percentage + "%" : " - "}
      </TableCell>
      <TableCell>
        {scheme.singleUse ? (
          <Icon color="primary">check_circle_outline</Icon>
        ) : (
          " - "
        )}
      </TableCell>
      <TableCell>
        {scheme.mandatoryWagering ? (
          <Icon color="primary">check_circle_outline</Icon>
        ) : (
          " - "
        )}
      </TableCell>
      <TableCell>
        {scheme.subtractForIncorrect ? (
          <Icon color="primary">check_circle_outline</Icon>
        ) : (
          " - "
        )}
      </TableCell>
      <TableCell>{scheme.bonusPoints || " - "}</TableCell>
      <TableCell align="right">
        <Button color="primary" onClick={() => editScheme(scheme)}>
          Edit
        </Button>
        <Button
          color="secondary"
          style={{ marginLeft: 10 }}
          onClick={() => handleDeleteWagerScheme(scheme.id, scheme.name)}
        >
          Delete
        </Button>
      </TableCell>
    </TableRow>
  ));

  return (
    <>
      <TableContainer>
        <Table
          className={classes.table}
          style={{ tableLayout: "fixed" }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              <TableCell>№</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Points</TableCell>
              <TableCell>Percentage</TableCell>
              <TableCell>Single Use</TableCell>
              <TableCell>Mandatory Wagering</TableCell>
              <TableCell>Subtract</TableCell>
              <TableCell>Bonus</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <SortableList
            onSortEnd={onSortEnd}
            onSortStart={onSortStart}
            shouldCancelStart={cancelDragging}
            axis="y"
            lockAxis="y"
            lockToContainerEdges={true}
            lockOffset={["-30%", "50%"]}
            helperClass={classes.test}
          >
            {schemes.map((scheme, index) => (
              <SortableItem
                key={`item-${index}`}
                index={index}
                scheme={scheme}
              />
            ))}
          </SortableList>
        </Table>
      </TableContainer>
      {deletingError && <div className={classes.error}>{deletingError} </div>}
    </>
  );
};

export default WagersTable;
