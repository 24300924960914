import { computed, makeAutoObservable, runInAction } from "mobx";
import { getRoot } from "mobx-easy";

import HostsService from "../../services/HostsService";
import Host from "./Host";

export default class HostsStore {
	hostsList = [];
	usersList = [];
	rootStore = undefined;
	roundList = [];
	userScores = [];
	gameResults = [];

	constructor() {
		makeAutoObservable(this, {
			haveFreeSlot: computed,
		});
		this.rootStore = getRoot();
	}

	get haveFreeSlot() {
		return (
			this.hostsList.length <
			this.rootStore.subscriptionStore.subscriptionQuantity
		);
	}

	clearHostsStore = () => {
		this.hostsList = [];
	};

	getListOfHosts = async () => {
		if (!!this.hostsList.length) return;

		this.rootStore.uiStore.startLoading();

		const response = await HostsService.getListOfHosts();

		const hoststToSet = [];
		response.hosts.forEach((host) => {
			hoststToSet.push(this.createHost(host));
		});

		runInAction(() => {
			this.rootStore.uiStore.cancelLoading();
			this.hostsList = hoststToSet;
		});
	};

	getListOfUsers = async (all) => {
		//if (!!this.usersList.length) return;

		this.rootStore.uiStore.startLoading();

		const response = await HostsService.getListOfUsers(all);

		runInAction(() => {
			this.rootStore.uiStore.cancelLoading();
			this.usersList = response.users;
		});
	};

	getListOfUserScores = async (
		start_date = null,
		end_date = null
	) => {
		//if (!!this.usersList.length) return;
		this.rootStore.uiStore.startLoading();

		const response = await HostsService.getListOfUserScores(
			start_date,
			end_date
		);
		// let response = {
		// 	users: [
		// 		{
		// 			userEmail: "johNsmith.com",
		// 			totalScore: 10,
		// 			gameDates: ["2021-10-10", "2021-10-11"],
		// 			teamNames: ["team1", "team2"],
		// 		},
		// 	],
		// };

		runInAction(() => {
			this.rootStore.uiStore.cancelLoading();
			this.userScores = response.users;
		});
	};

	getListOfGameResults = async (
		start_date = null,
		end_date = null
	) => {
		this.rootStore.uiStore.startLoading();

		const response = await HostsService.getListOfGameResults(
			start_date,
			end_date
		);

		runInAction(() => {
			this.rootStore.uiStore.cancelLoading();
			this.gameResults = response;
		});
	};

	getListOfRounds = async () => {
		const response = await HostsService.getListOfRounds();

		runInAction(() => {
			this.rootStore.uiStore.cancelLoading();
			this.roundList = response.rounds;
		});
	};

	inviteExistingHost = async (email, password) => {
		const response = await HostsService.inviteExistingHost(email, password);

		this.addHost(response.host);
	};

	createNewHost = async (roomCode, password) => {
		const response = await HostsService.createNewHost(roomCode, password);

		this.addHost(response.host);
	};

	deleteHost = async (hostId) => {
		const response = await HostsService.deletHost(hostId);

		this.removeHost(response.id);
	};

	createHost = (host) => {
		const { roomCode, gameMode, players } = host.room;
		const { _id: id } = host;

		const users = [];
		players.map((player, index) => {
			if (player.user) {
				users.push(player.user);
			}
		});
		const uniqueUsers = users.filter(
			(obj, index) =>
				users.findIndex((item) => item.email === obj.email) === index
		);

		return new Host(id, roomCode, gameMode, players.length, uniqueUsers);
	};

	addHost = (host) => {
		this.hostsList = [...this.hostsList, this.createHost(host)];
	};

	removeHost = (id) => {
		this.hostsList = this.hostsList.filter((host) => host.id !== id);
	};

	getAdvancedSettings = async (roomCode) => {
		const hostToUpdate = this.getHost(roomCode);

		if (!hostToUpdate) return;

		// if (
		//   Object.keys(hostToUpdate.gameSettings).length !== 0 &&
		//   Object.keys(hostToUpdate.proGameOptions).length !== 0
		// ) {
		//   return hostToUpdate;
		// }

		this.rootStore.uiStore.startLoading();

		const response = await HostsService.getAdvancedHostSettings(
			hostToUpdate.id
		);

		hostToUpdate.initializeHostGameSettings(response.gameSettings);
		hostToUpdate.initializeHostProGameOptions(response.proGameOptions);

		this.rootStore.uiStore.cancelLoading();

		return hostToUpdate;
	};

	getHost = (roomCode) => {
		return this.hostsList.find((host) => host.roomCode === roomCode);
	};

	getHostIndex = (id) => {
		return this.hostsList.findIndex((host) => host.id === id);
	};
}
