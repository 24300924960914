import React, { useEffect, useState } from "react";
import { Box, Button, Icon, makeStyles, Typography } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import Alert from "@material-ui/lab/Alert";
import OftadehLayout from "../../components/OftadehLayout/OftadehLayout";
import WagersTable from "./WagersTable";
import WagerSchemeSetup from "./WagerSchemeSetup";

import { useStore } from "../../stores/helpers/useStore";
import { useIsMounted } from "../../helpers/useIsMounted";
import ConfirmationModal from "../../components/Modals/ConfirmationModal/ConfirmationModal";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

const WagersList = () => {
  const [setupMode, setSetupMode] = useState("VIEW"); // VIEW | CREATE | UPDATE
  const [requestProcessing, setRequestProcessing] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [schemeToDelete, setSchemeToDelete] = useState({});
  const [schemeToEdit, setSchemeToEdit] = useState(null);
  const [deletingError, setDeletingError] = useState("");

  const isMounted = useIsMounted();
  const classes = useStyles();

  const {
    settingsStore: {
      wagerSchemesList,
      uploadWagerScheme,
      updateSchemesOrder,
      getListOfWagerSchemes,
      clearSchemeOrderUpdatingTimeout,
      editWagerScheme,
      deleteWagerScheme,
    },
    uiStore: { loading },
  } = useStore();

  useEffect(() => {
    getListOfWagerSchemes();
  }, [getListOfWagerSchemes]);

  const handleDeleteWagerScheme = (schemeId, name) => {
    setDeletingError("");
    setSchemeToDelete({ id: schemeId, name });
    setOpenConfirmationModal(true);
  };

  const handleUpdateSchemeOrder = async (oldIndex, newIndex) => {
    setDeletingError("");
    try {
      await updateSchemesOrder(oldIndex, newIndex);
    } catch {
      setDeletingError(
        "Something went wrong! Please try to update orders again!"
      );
    }
  };

  const confirmDeleting = async () => {
    setRequestProcessing(true);
    try {
      await deleteWagerScheme(schemeToDelete.id);
    } catch (err) {
      setDeletingError(
        err.response?.data?.message ||
          "Something went wrong. Please try again later!"
      );
    } finally {
      setRequestProcessing(false);
      setOpenConfirmationModal(false);
    }
  };

  const submitSchemeSetup = async (values, setFieldError) => {
    try {
      setupMode === "CREATE"
        ? await uploadWagerScheme(values)
        : await editWagerScheme(values, schemeToEdit.id);
      setSetupMode("VIEW");
    } catch (err) {
      switch (err.response.status) {
        case 406:
          setFieldError("name", err.response.data?.message);
          break;
        case 409:
          setFieldError(
            err?.response?.data?.param,
            err?.response?.data?.message
          );
          break;
        case 404:
        case 500:
        default:
          setFieldError(
            "name",
            "Something went wrong! Please try again later!"
          );
          break;
      }
    }
  };

  const editScheme = (scheme) => {
    setSchemeToEdit(scheme);
    setSetupMode("UPDATE");
  };

  const cancelSetupMode = () => {
    setSchemeToEdit(null);
    setSetupMode("VIEW");
  };

  const handleClose = () => {
    setOpenConfirmationModal(false);
  };

  return (
    <>
      <OftadehLayout loading={loading || !isMounted}>
        <h1>Wager Schemes</h1>

        {setupMode === "VIEW" && (
          <Box display="flex" justifyContent="center" m={2.5}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<Icon>add</Icon>}
              onClick={() => setSetupMode("CREATE")}
            >
              Add New Scheme
            </Button>
          </Box>
        )}

        <div className={classes.root}>
          {setupMode !== "VIEW" && (
            <WagerSchemeSetup
              {...schemeToEdit}
              setupMode={setupMode}
              submit={submitSchemeSetup}
              cancel={cancelSetupMode}
            />
          )}
          {!!wagerSchemesList.length && (
            <>
              <WagersTable
                schemes={wagerSchemesList}
                deletingError={deletingError}
                clearTimeout={clearSchemeOrderUpdatingTimeout}
                updateSchemesOrder={handleUpdateSchemeOrder}
                handleDeleteWagerScheme={handleDeleteWagerScheme}
                confirmDeleting={confirmDeleting}
                editScheme={editScheme}
              />
            </>
          )}
        </div>
        {!wagerSchemesList.length && setupMode === "VIEW" && (
          <Box display="flex" justifyContent="center" m={2.5} p={5}>
            <Alert severity="warning">
              You don't have schemes yet! Please, create your first scheme!
            </Alert>
          </Box>
        )}
      </OftadehLayout>

      <ConfirmationModal
        title={`You really want to delete scheme "${schemeToDelete.name}"?`}
        open={openConfirmationModal}
        loading={requestProcessing}
        handleClose={handleClose}
        confirm={confirmDeleting}
      >
        It will remove scheme from list of schemes of all your hosts.
      </ConfirmationModal>
    </>
  );
};

export default observer(WagersList);
