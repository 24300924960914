import React, { useEffect, useState } from "react";
import {
	TextField,
	Grid,
	Button,
	CircularProgress,
	Typography,
	makeStyles,
	Card,
	Paper,
	Box,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { useHistory } from "react-router-dom";
import { useStore } from "../../stores/helpers/useStore";
import flowchart from "../../images/flow-chart.png";
import labels from "../../utils/labels";
import OftadehLayout from "../../components/OftadehLayout/OftadehLayout";

const useStyles = makeStyles((theme) => ({
	content: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		height: "80vh",
		justifyContent: "space-evenly",
		"& h2": {
			fontSize: 30,
		},
	},
	paragraph: {
		padding: "2em",
	},
	sendBtn: {
		width: 200,
	},
	circle: {
		marginLeft: 10,
	},
}));

const definitions = [
	{
		term: labels.host,
		definition:
			"Someone who is running the trivia game using the hosting portal. As far as the data goes, a host can be used interchangeably with the term 'room'.",
	},
	{
		term: "Room",
		definition:
			"A 'Room' is a collection of 'rounds' that are played together with a group of the same players. Each room has a unique 'Room Code', and can be navigated to via the room link (or QR Code)",
	},
	{
		term: "Round",
		definition:
			"A round is a collection of questions that are played together within a given room. A room will often have several rounds associated with it ",
	},
	{
		term: "User",
		definition:
			"A 'user' is a registered person that has signed up for (at least) one of your rooms. They are required to create a 'player' or 'team' name in order to play. They can use this same name across multiple rooms, and their scores will be tallied together. Note that they can choose a different team name from one game to the next, but the 'user' (their email) will stay consistent.",
	},
	{
		term: "Player",
		definition:
			"A 'player' is a user's name that they have chosen for a specific game.  They can change this name from one game to the next, and it will not affect their 'user' score.  A 'Player' could also be referred to as a 'Team', as teams would only use one device to login and submit answers anyway.",
	},
	{
		term: "Team",
		definition:
			"From a data standpoint, team and player are the same thing.  But since the person playing isnt always part of a team, they may be referred to as a player, and vice versa",
	},
];

const GuidePage = (props) => {
	const classes = useStyles();
	const history = useHistory();
	return (
		<OftadehLayout>
			<Grid container direction={"column"} spacing={5}>
				<Grid item>
					<h2>Welcome to Enterprise Mode</h2>
					<Typography
						sx={{ px: 24, mx: 4, my: 3 }}
						style={{ marginBottom: "12px", fontSize: "0.8rem" }}
					>
						Welcome to the <b>TR ADMIN</b> portal. This is where you can take
						your trivia hosting to the next level. And by "next level" -- we
						don't just mean a few extra features to enhance your experience ...
						we literally mean next LEVEL -- as in you are now up one level in
						the operation of hosting trivia.
					</Typography>
					<Paper elevation={3} className={classes.paragraph} align="center">
						<h2> TR ADMIN to TriviaRat.com Flow </h2>
						<img src={flowchart} />
					</Paper>
				</Grid>

				<Grid item sx={{ px: 2 }}>
					<Paper elevation={3} className={classes.paragraph}>
						<h2>Main features of Enterprise</h2>
						<ul>
							<li>Manage Multiple Hosts</li>
							<li>Configure global settings to apply to all hosts</li>
							<li>
								Invite existing pro TriviaRat hosts, and/or create new ones
							</li>
							<li>Provide the same set of questions to all your hosts</li>
							<li>View previous game data</li>
							<li>
								Utilize "user" sign-in's to allow teams to play across multiple
								nights/rooms and tally their total scores (used for tournament
								situations)
							</li>
							<li>
								Show your branding across on the devices of all your hosts'
								players.
							</li>
						</ul>
					</Paper>
				</Grid>

				<Grid item>
					<Paper elevation={3} className={classes.paragraph}>
						<h2>Getting Started</h2>
						1) The first thing you'll want to do is{" "}
						<Button
							color="primary"
							size="small"
							variant="contained"
							onClick={() => history.push(`/create-host/`)}
						>
							Create a Host
						</Button>
						. You can assign the room code, password and individual settings for
						the host.
						<b>The host's username will be the same as their room code</b>, and
						they can log in at{" "}
						<a href="https://triviarat.com/sign-in">TriviaRat.com</a>
						<br />
						<br />
						2) After you've created one or more hosts - you can stop there if
						you want! The hosts do not require anything further to start logging
						in and hosting their trivia games, but you can continue on if you'd
						like.
						<br />
						<br />
						3) If you want all your hosts to have access to the same set of
						questions, you can import them via CSV in the{" "}
						<Button
							color="primary"
							size="small"
							variant="contained"
							onClick={() => history.push(`/questions/`)}
						>
							Questions
						</Button>{" "}
						section. Once the import has completed, they will be immediately
						accessible by the hosts when they are in "Pre-entered" mode. 4) At
						this point, everything is optional. You may adjust global settings
						(settings that affect all hosts), individual host settings, change
						room codes, import more questions, and so on. Stay tuned as their
						will soon be a dashboard with statistics on how your games/hosts are
						going. Have any questions or feedback?{" "}
						<Button
							color="primary"
							size="small"
							variant="contained"
							onClick={() => history.push(`/contact/`)}
						>
							Contact Us
						</Button>{" "}
						anytime.
					</Paper>
				</Grid>

				<Grid item>
					<Paper elevation={3} className={classes.paragraph}>
						<h2>Definitions</h2>
						{definitions.map((item, index) => (
							<div key={index}>
								<h3 sx={{ mb: 0 }}>{item.term}</h3>
								<p sx={{ ml: 4 }}>{item.definition}</p>
							</div>
						))}
					</Paper>
				</Grid>
			</Grid>
		</OftadehLayout>
	);
};

export default GuidePage;
