import React, { useCallback, useEffect, useState } from "react";
import OftadehLayout from "../../components/OftadehLayout/OftadehLayout";
import { Grid, Typography, makeStyles, Paper, Box } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
//import router linke
import { Link } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useIsMounted } from "../../helpers/useIsMounted";
import { useStore } from "../../stores/helpers/useStore";
import GameTable from "./GameHistoryTable";

const useStyles = makeStyles(() => ({
	titlePaper: {
		marginBottom: "16px",
	},
	availableSpace: {
		fontVariant: "diagonal-fractions",
		fontSize: 36,
	},
}));
const RecordList = (props) => {
	const classes = useStyles();
	const isMounted = useIsMounted();
	const [isLoading, setIsLoading] = useState(true);

	const {
		hostsStore: { roundList, getListOfRounds },

		uiStore: { loading },
	} = useStore();

	useEffect(() => {
		const fetchData = async () => {
			await getListOfRounds();
			setIsLoading(false);
		};

		fetchData();
	}, [getListOfRounds]);
	// useEffect(async () => {
	// 	await getListOfRounds();
	// 	setIsLoading(false);
	// }, [getListOfRounds]);

	return (
		<>
			<OftadehLayout loading={loading || !isMounted || isLoading}>
				<Box style={{ marginBottom: "20px" }}>
					<h2>Previous Round Data</h2>
					<Link to="/game-history" sx={{ m: 2 }}>
						Go to Game History
					</Link>
				</Box>

				<>
					{!!roundList?.length ? (
						<Paper style={{ padding: "5px" }}>
							<GameTable roundList={roundList} />
						</Paper>
					) : (
						<Grid container direction="column">
							<Alert severity="info">
								No Rounds have been historically saved yet.
							</Alert>
						</Grid>
					)}
				</>
			</OftadehLayout>
		</>
	);
};

export default observer(RecordList);
