import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  backButton: {
    display: "flex",
    justifyContent: "center",
  },
  alignCenter: {
    textAlign: "center",
  },
  content: {
    minWidth: 500,
  },
});

const ErrorModal = ({ open, title, errorText, handleClose }) => {
  const classes = useStyles();

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle className={classes.alignCenter} id="alert-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent className={classes.content}>
          <DialogContentText
            className={classes.alignCenter}
            id="alert-dialog-description"
          >
            {errorText}
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.backButton}>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={handleClose}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default ErrorModal;
