import React from "react";
import {
	Button,
	CircularProgress,
	makeStyles,
	TextField,
	Box,
	Grid,
	Typography,
	Paper,
	Container,
} from "@material-ui/core";
import { observer } from "mobx-react-lite";
import { useFormik } from "formik";
import * as Yup from "yup";

import eduStyles from "../eduStyles";
import standardStyles from "../standardStyles";

import AuthWrapper from "../AuthWrapper";

import { useStore } from "../../../stores/helpers/useStore";

import "../auth.css";

const initialValues = {
	email: "",
	password: "",
	confirmPassword: "",
};

const registrationSchema = Yup.object().shape({
	email: Yup.string()
		.trim()
		.required("Email is required")
		.email("Email must be valid"),
	password: Yup.string()
		.trim()
		.min(6, "Minimum 6 symbols")
		.max(20, "Maximum 20 symbols")
		.matches(
			/^(?=.*[a-zA-Z])(?=.*[0-9]).+$/,
			"Should have at least one number and letter"
		)
		.required("Password is required"),
	confirmPassword: Yup.string()
		.trim()
		.required("Password confirmation is required")
		.when("password", {
			is: (val) => !!(val && val.length > 0),
			then: Yup.string().oneOf(
				[Yup.ref("password")],
				"Password and Confirm Password didn't match"
			),
		}),
});

const RegisterPage = (props) => {
	return (
		<AuthWrapper>
			<RegisterComponent {...props} />
		</AuthWrapper>
	);
};

const RegisterComponent = (props) => {
	const {
		authStore: { registerUser },
	} = useStore();
	//const classes = useStyles();
	const { history } = props;

	//when component mounts, get subdomain of url
	const classes = window.labs.isEducation ? eduStyles() : standardStyles();

	const formik = useFormik({
		initialValues,
		validationSchema: registrationSchema,
		onSubmit: async (values, { setFieldError, setSubmitting }) => {
			setSubmitting(true);
			try {
				await registerUser(values.email, values.password);
			} catch (err) {
				setSubmitting(false);
				if (err?.response?.status === 409) {
					setFieldError(
						err?.response?.data?.param,
						err?.response?.data?.message
					);
				} else if (err?.response?.status === 500) {
					history.push("/pages/errors/error-500");
				}
			}
		},
	});

	return (
		<div className={classes.loginCard}>
			<Typography variant="h5" component="h1">
				Register
			</Typography>
			<Typography className={classes.mBottom} variant="body1">
				Create your account
			</Typography>
			<form noValidate autoComplete="off" onSubmit={formik.handleSubmit}>
				<TextField
					size="small"
					label="Email"
					name="email"
					variant="outlined"
					margin="dense"
					value={formik.values.email}
					onChange={formik.handleChange}
					onBlur={formik.handleBlur}
					error={!!formik.touched.email && !!formik.errors.email}
					helperText={formik.touched.email && formik.errors.email}
					fullWidth
				/>
				<TextField
					size="small"
					label="Password"
					type="password"
					name="password"
					variant="outlined"
					margin="dense"
					value={formik.values.password}
					onChange={formik.handleChange}
					onBlur={formik.handleBlur}
					error={formik.touched.password && formik.errors.password}
					helperText={formik.touched.password && formik.errors.password}
					fullWidth
				/>
				<TextField
					size="small"
					label="Repeat password"
					type="password"
					name="confirmPassword"
					variant="outlined"
					margin="dense"
					value={formik.values.confirmPassword}
					onChange={formik.handleChange}
					onBlur={formik.handleBlur}
					error={
						formik.touched.confirmPassword && formik.errors.confirmPassword
					}
					helperText={
						formik.touched.confirmPassword && formik.errors.confirmPassword
					}
					fullWidth
				/>
				<div className={classes.mBottom}>
					<Button
						type="submit"
						variant="contained"
						color="primary"
						fullWidth
						className={classes.button}
						onClick={formik.handleSubmit}
						disabled={formik.isSubmitting}
					>
						{formik.isSubmitting ? (
							<>
								Please wait{" "}
								<CircularProgress
									className={classes.circularProgress}
									color="inherit"
									size={16}
								/>
							</>
						) : (
							<> Register</>
						)}
					</Button>
					<Button
						variant="outlined"
						color="primary"
						fullWidth
						className={classes.button}
						onClick={() => history.push("/pages/auth/login")}
					>
						Sign in
					</Button>
				</div>
			</form>
		</div>
	);
};

export default observer(RegisterPage);
