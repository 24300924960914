import axios from "axios";
import config from "../utils/Configuration";
import TokenStorage from "../utils/TokenStorage";

class ApiService {
  static tokenStorage = new TokenStorage();

  get tokenStorage() {
    return ApiService.tokenStorage;
  }

  static api = axios.create({
    baseURL: config.baseUrl,
  });

  async request(inputConfig) {
    try {
      const res = await ApiService.api(inputConfig);

      return res.data;
    } catch (err) {
      throw err;
    }
  }

  async protectedRequest(inputConfig) {
    const outputConfig = {
      ...inputConfig,
      ...{
        headers: {
          Authorization: `Bearer ${this.tokenStorage.get()}`,
        },
      },
    };

    try {
      const res = await ApiService.api(outputConfig);

      return res.data;
    } catch (err) {
      throw err;
    }
  }
  async protectedFormDataRequest(inputConfig) {
    const outputConfig = {
      ...inputConfig,
      ...{
        headers: {
          Authorization: `Bearer ${this.tokenStorage.get()}`,
          "Content-Type": "multipart/form-data",
        },
      },
    };

    try {
      const res = await ApiService.api(outputConfig);

      return res.data;
    } catch (err) {
      throw err;
    }
  }
}

export default ApiService;
