import Users from "./UserList";
import UserDetails from "./UserDetails";
export const UserConfig = {
	routes: [
		{
			path: "/users",
			exact: true,
			component: Users,
		},
		{
			path: "/users/:id",
			component: UserDetails,
		},
	],
};
