import React, { useEffect, useState } from "react";
import {
  makeStyles,
  TextField,
  Button,
  Grid,
  Box,
  Paper,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import { observer } from "mobx-react-lite";

import { useFormik } from "formik";
import * as Yup from "yup";

import { useStore } from "../../../stores/helpers/useStore";
import OftadehLayout from "../../../components/OftadehLayout/OftadehLayout";
import CongratulationModal from "../../../components/Modals/CongratulationModal/CongratulationModal";
import { useIsMounted } from "../../../helpers/useIsMounted";

const initialValues = {
  email: "",
  password: "",
};

const loginationSchema = Yup.object().shape({
  email: Yup.string()
    .trim()
    .required("Email is required")
    .email("Email must be valid"),
  password: Yup.string()
    .trim()
    .min(8, "Minimum 8 symbols")
    // .max(20, "Maximum 20 symbols")
    .required("Password is required"),
});

const useStyles = makeStyles(() => ({
  inviteForm: {
    maxWidth: 500,
    margin: "0 auto",
    padding: 30,
  },
  controlButtons: {
    display: "flex",
    justifyContent: "center",
    marginTop: 20,
  },
  error: {
    color: "#f44336",
    fontSize: "0.75rem",
    textAlign: "center",
  },
}));

const InviteHost = (props) => {
  const { history } = props;
  const classes = useStyles();
  const isMounted = useIsMounted();
  const [subscriptionError, setSubscriptionError] = useState("");
  const [openCongratulationModal, setOpenCongratulationModal] = useState(false);
  const {
    hostsStore: { inviteExistingHost },
    subscriptionStore: { subscriptionQuantity },
  } = useStore();

  const formik = useFormik({
    initialValues,
    validationSchema: loginationSchema,
    onSubmit: async (values, { setFieldError, setSubmitting }) => {
      setSubscriptionError("");
      setSubmitting(true);
      try {
        await inviteExistingHost(values.email, values.password);
        setOpenCongratulationModal(true);
      } catch (err) {
        if (err.response?.status === 409) {
          setFieldError(
            err?.response?.data?.param,
            err?.response?.data?.message
          );
        } else if (err.response?.status === 403) {
          setFieldError("email", err.response?.data?.message);
        } else if (err.response?.status === 420) {
          setSubscriptionError(err?.response?.data?.message);
        } else if (err.response?.status === 500) {
          history.push("/pages/errors/error-500");
        }
      }
    },
  });

  const closeCongratulationModal = () => {
    setOpenCongratulationModal(false);
    history.push("/hosts");
  };

  useEffect(() => {
    if (!subscriptionQuantity) history.push("/hosts");
  }, [subscriptionQuantity, history]);

  return (
    <>
      <OftadehLayout loading={!isMounted}>
        <h1>Invite Host</h1>

        <Paper className={classes.inviteForm}>
          <Box m={3.5}>
            <Grid container direction={"column"} spacing={5}>
              <Typography variant="h4" component="h1" align="center" paragraph>
                Invite Host
              </Typography>
              <form
                noValidate
                autoComplete="off"
                onSubmit={formik.handleSubmit}
              >
                <TextField
                  size="small"
                  label="Email"
                  name="email"
                  variant="outlined"
                  margin="dense"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={!!formik.touched.email && !!formik.errors.email}
                  helperText={formik.touched.email && formik.errors.email}
                  fullWidth
                />
                <TextField
                  size="small"
                  label="Password"
                  type="password"
                  name="password"
                  variant="outlined"
                  margin="dense"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={!!formik.touched.password && !!formik.errors.password}
                  helperText={formik.touched.password && formik.errors.password}
                  fullWidth
                />

                {subscriptionError && (
                  <p className={classes.error}>{subscriptionError}</p>
                )}

                <Box className={classes.controlButtons}>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={formik.isSubmitting}
                    style={{ marginRight: 15 }}
                  >
                    {formik.isSubmitting ? (
                      <>
                        Please wait{" "}
                        <CircularProgress
                          className={classes.circularProgress}
                          color="inherit"
                          size={16}
                        />
                      </>
                    ) : (
                      <> Invite</>
                    )}
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => history.push("/hosts")}
                  >
                    Cancel
                  </Button>
                </Box>
              </form>
            </Grid>
          </Box>
        </Paper>
      </OftadehLayout>

      <CongratulationModal
        open={openCongratulationModal}
        handleClose={closeCongratulationModal}
      >
        Host <b>{formik.values.email}</b> successfully added!
        <br />
        Now host have <b>"PRO"</b> status and can use all advantages!
      </CongratulationModal>
    </>
  );
};

export default observer(InviteHost);
