import React from "react";

import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
	pricing: {
		marginBottom: 100,

		padding: "30px 50px",
		color: "#444",
		fontSize: 14,
		"& p ,h3,h4": {
			textAlign: "left",
		},
	},
});

const SubscriptionDescription = () => {
	const classes = useStyles();
	return (
		<div className={classes.pricing}>
			<p>
				<h3>How it works</h3>
				During subscription creation, you'll choose your host quantity. That
				quantity determines the amount charged every regular billing cycle.
				Minimum quantity is: 3
			</p>
			<p>
				<b>For example:</b>
				<br />
				If you create a subscription with 5 hosts; the net subscription price
				is:
				<br /> <b>3 x 15 + 2 x 10 = 65$ </b>per month
			</p>

			<p>
				As your trivia empire moves forward -- you can adjust the quantity. The
				new quantity and definition determine the new price that's applicable to
				subsequent billing cycles.
			</p>

			<p>
				If you want to increase the number of hosts you will need to pay
				one-time fee (pro-rated to point in cycle) in the amount of the price of
				the next added host.
				<br />
				<b>For example:</b>
				If you have 5 added hosts and you want to add another one 2 weeks in,
				you will need to pay a $10 fee, and the next billing cycle subscription
				price will be:
				<br />
				<b>3 x 15 + 3 x 10 = 75$</b> per month
			</p>
		</div>
	);
};

export default SubscriptionDescription;
