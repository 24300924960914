class Configuration {
  get baseUrl() {
    return process.env.REACT_APP_BASE_URL;
  }

  get authToken() {
    const auth = localStorage.getItem("auth");

    return auth ?? "";
  }

  get isUserVerified() {
    const verified = JSON.parse(localStorage.getItem("verified"));

    return verified ?? false;
  }
}

const config = new Configuration();
export default config;
