import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import Chip from "@material-ui/core/Chip";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import Alert from "@material-ui/lab/Alert";

const useStyles = makeStyles({
	table: {
		"& tbody": {
			textTransform: "uppercase",

			"& th,td": {
				fontWeight: "bold",
			},
		},
	},
});
const baseUrl = process.env.REACT_APP_FE_URL || "https://triviarat.com/";
const hostUrl = `${baseUrl}sign-in/`;

const HostsTable = ({ hostsList, deletePlayer }) => {
	const classes = useStyles();
	const history = useHistory();

	const isLast = (index) => index === hostsList.length - 1;

	const getState = (state) => {
		console.log("state", state);
		//let label = state;
		//let color = "primary";

		let map = {
			play: {
				label: "Game On",
				color: "#AFE1AF",
			},
			pause: {
				label: "Idle",
				color: "primary",
			},
			stop: {
				label: "Stopped",
				color: "error",
			},
			check: {
				label: "Marking",
				color: "#96DED1",
			},
		};

		const sel = map[state];

		return <Chip label={sel.label} style={{ backgroundColor: sel.color }} />;
	};

	return (
		<>
			<Alert severity="info">
				{window.labs.hosts} log in to their account at{" "}
				<a href={hostUrl} target="_BLANK">
					{hostUrl}
				</a>{" "}
				with the room code as their username (in the email field) and the
				password configured for the {window.labs.host}.
			</Alert>

			<TableContainer>
				<Table
					className={classes.table}
					style={{ tableLayout: "fixed" }}
					aria-label="simple table"
				>
					<TableHead>
						<TableRow>
							<TableCell>Room Code (username) </TableCell>
							<TableCell align="center">Current State</TableCell>
							<TableCell align="center">Player Count</TableCell>
							<TableCell align="right" style={{ paddingRight: 80 }}>
								Actions
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{hostsList.map((row, index) => (
							<TableRow key={row.id}>
								<TableCell
									component="th"
									scope="row"
									style={{
										borderBottom: isLast(index) && 0,
									}}
								>
									{row.roomCode}
								</TableCell>
								<TableCell
									align="center"
									style={{
										borderBottom: isLast(index) && 0,
									}}
								>
									{getState(row.gameStatus)}
								</TableCell>
								<TableCell
									align="center"
									style={{
										borderBottom: isLast(index) && 0,
									}}
								>
									{row.playersCount}
								</TableCell>
								<TableCell
									align="right"
									style={{
										borderBottom: isLast(index) && 0,
									}}
								>
									<Button
										color="primary"
										onClick={() => history.push(`/edit-host/${row.roomCode}`)}
									>
										Edit
									</Button>
									<Button
										color="secondary"
										style={{ marginLeft: 10 }}
										onClick={() => deletePlayer(row)}
									>
										Delete
									</Button>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</>
	);
};

export default HostsTable;
