// import { MaterialUIComponentsNavigation } from "../pages/documentation/material-ui-components/MaterialUIComponentsNavigation";
import labels from "../utils/labels";

const navigationConfig = [
	{
		id: "Main",
		title: "SETUP",
		type: "group",
		children: [
			// {
			//   id: "dashboard",
			//   title: "Dashboard",
			//   type: "item",
			//   icon: "apps",
			//   url: "/",
			//   exact: true,
			// },
			{
				id: "brand",
				title: labels.my_brand,
				type: "item",
				icon: "storefront",
				url: "/brand",
				exact: true,
			},
			{
				id: "hosts",
				title: labels.hosts,
				type: "item",
				icon: "people",
				url: "/hosts",
				exact: true,
			},
			{
				id: "settings",
				title: "Global Settings",
				type: "item",
				icon: "settings",
				url: "/pages/settings",
				exact: true,
			},

			{
				id: "questions",
				title: "Questions",
				type: "item",
				icon: "library_books",
				url: "/questions",
				exact: true,
			},
			{
				id: "wagers",
				title: "Wager Schemes",
				type: "item",
				icon: "money",
				url: "/wager-schemes",
				exact: true,
			},
		],
	},
	{
		type: "divider",
	},
	{
		id: "game",
		title: "GAME DATA",
		type: "group",
		children: [
			// {
			// 	id: "users",
			// 	title: labels.users_and_teams,
			// 	type: "item",
			// 	icon: "groups",
			// 	url: "/users",
			// 	exact: true,
			// 	emailRequired: true,
			// },

			{
				id: "player_settings",
				title: "Player Settings",
				type: "item",
				icon: "people",
				url: "/pages/player-settings",
				exact: true,
			},
			{
				id: "rounds_history",
				title: "Game History",
				type: "item",
				icon: "leaderboard",
				url: "/game-history",
				exact: true,
			},
			{
				id: "games_results",
				title: "Game Results",
				type: "item",
				icon: "leaderboard",
				url: "/game-results",
				exact: true,
			},
		],
	},
	{
		type: "divider",
	},
	{
		id: "help",
		title: "SUPPORT",
		type: "group",
		children: [
			{
				id: "help",
				title: "Contact",
				type: "item",
				icon: "mail",
				url: "/contact",
				exact: true,
			},
			{
				id: "guide",
				title: "Guide",
				type: "item",
				icon: "help",
				url: "/guide",
				exact: true,
			},
		],
	},
];

export default navigationConfig;

export function getNavigationConfig(displayUser) {
	let filteredNavigation = navigationConfig;

	if (displayUser === false) {
		filteredNavigation.forEach((item) => {
			const newChildren = item.children
				? item.children.filter((nav) => nav.id !== "users")
				: [];
			item.children = newChildren;
		});
	}

	return filteredNavigation;
}
