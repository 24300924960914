import { makeAutoObservable } from "mobx";
import AuthStore from "./data/AuthStore";
import HostsStore from "./data/HostsStore";
import SettingsStore from "./data/SettingsStore/SettingsStore";
import SubscriptionStore from "./data/SubscriptionStore";
import UIStore from "./data/UIStore";

export default class RootStore {
  authStore;
  subscriptionStore;
  hostsStore;
  uiStore;
  settingsStore;

  init() {
    makeAutoObservable(this);
    this.authStore = new AuthStore();
    this.subscriptionStore = new SubscriptionStore();
    this.hostsStore = new HostsStore();
    this.uiStore = new UIStore();
    this.settingsStore = new SettingsStore();
  }
}
